import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";

const ActivityContext = createContext();

export const ActivityProvider = ({ children }) => {
    const [selectedActivity, setSelectedActivity] = useState(null);

    return (
        <ActivityContext.Provider value={{ selectedActivity, setSelectedActivity }}>
            {children}
        </ActivityContext.Provider>
    );
};

export const useActivity = () => useContext(ActivityContext);

ActivityProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
