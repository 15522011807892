import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';

const ScreenshotWidget = () => {
    const [imageData, setImageData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchScreenshot = async () => {
        try {
            const serveIp = localStorage.getItem('serverIp');
            if (!serveIp) throw new Error('Server IP not found');

            console.log('Fetching from:', `http://${serveIp}:5001/get_all_screenshots`);
            
            const response = await fetch(`http://${serveIp}:5001/get_all_screenshots`);
            
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Server response:', response.status, errorText);
                throw new Error(`Failed to fetch screenshot: ${response.status} ${errorText}`);
            }
            
            const blob = await response.blob();
            if (blob.size === 0) {
                throw new Error('Received empty screenshot data');
            }
            
            const imageUrl = URL.createObjectURL(blob);
            setImageData(imageUrl);
            setError(null);
        } catch (error) {
            console.error('Error details:', error);
            setError(`Failed to load screenshot: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchScreenshot();
        const interval = setInterval(fetchScreenshot, 1000); // Refresh every 1 second
        return () => clearInterval(interval);
    }, []);

    if (loading) {
        return (
            <Box sx={{ width: 400, height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ width: 400, height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: 600, height: 330 }}>
            <Typography variant="h6" gutterBottom>Worker Screens</Typography>
            <Box sx={{ width: '100%', height: 'calc(100% - 32px)', overflow: 'hidden' }}>
                <img 
                    src={imageData} 
                    alt="System Screenshot" 
                    style={{ 
                        width: '100%', 
                        height: '100%', 
                        objectFit: 'contain' 
                    }} 
                />
            </Box>
        </Box>
    );
};

export default ScreenshotWidget;
