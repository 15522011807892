import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TabView from '../components/shared/TabView';
import AdminPanel from '../components/_admin/AdminPanel';
import ResetPassword from './ResetPassword';
import { Box, Typography, TextField, Grid, Button, FormControlLabel, Switch } from '@mui/material';
import { SettingsContext } from '../components/SettingsContext';
import { AuthContext } from '../components/AuthContext';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

// Custom scrollbar styling
const scrollbarStyle = {
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#252526',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.15)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(255, 255, 255, 0.25)',
  },
  // For Firefox
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(255, 255, 255, 0.15) #252526',
};

function Settings({ isFloating }) {
  const { 
    serverIp, 
    setServerIp, 
    useFloatingPanel, 
    setUseFloatingPanel,
    setIsSettingsOpen,
    autoHideSidebar, 
    setAutoHideSidebar, 
    useSmallSidebar, 
    setUseSmallSidebar 
  } = useContext(SettingsContext);
  
  const { role, brands = [] } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  // User info state
  const [userInfo, setUserInfo] = useState({
    username: '',
    fornavn: '',
    etternavn: '',
    role: role || '',
    brands: brands.length > 0 ? brands.join(', ') : 'N/A',
    freeze: false,
    persdistr: '',
    persgroup: '',
  });

  // Admin access flag
  const [hasAdminAccess, setHasAdminAccess] = useState(false);
  
  // Force re-render when tabs change
  const [tabKey, setTabKey] = useState(0);
  
  // Current active tab (simplified)
  const [activeTab, setActiveTab] = useState('general');

  // Update small sidebar setting in localStorage
  useEffect(() => {
    localStorage.setItem('useSmallSidebar', useSmallSidebar.toString());
  }, [useSmallSidebar]);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      const token = Cookies.get('token');
      const username = userInfo.username;
      const apiUrl = process.env.REACT_APP_STATUS_API_URL;

      if (token && username) {
        try {
          const response = await fetch(`${apiUrl}/user?username=${username}&token=${token}`);
          if (response.ok) {
            const data = await response.json();
            const capitalize = (str) => str.replace(/\b\w/g, char => char.toUpperCase());

            // Update user info
            setUserInfo((prev) => ({
              ...prev,
              fornavn: capitalize(data.fornavn || ' '),
              etternavn: capitalize(data.etternavn || ' '),
              role: capitalize(data.role || ' '),
              brands: data.brands ? data.brands.map(capitalize).join(', ') : ' ',
              freeze: data.freeze || false,
              persdistr: capitalize(data.persdistr || ' '),
              persgroup: capitalize(data.persgroup || ' '),
            }));
            
            // Check admin access
            const userRole = data.role ? data.role.toLowerCase() : '';
            const isAdmin = userRole === 'admin' || userRole === 'superadmin';
            
            if (isAdmin !== hasAdminAccess) {
              setHasAdminAccess(isAdmin);
              // Force tab refresh on admin status change
              setTabKey(prev => prev + 1);
            }
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [userInfo.username]);

  // Get username from JWT token
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserInfo(prev => ({
          ...prev,
          username: decodedToken.sub || '',
        }));
      } catch (error) {
        console.error('Error decoding JWT token:', error);
      }
    }
  }, []);

  const handleSaveIp = () => {
    localStorage.setItem('serverIp', serverIp);
    alert('Server IP lagret!');
  };

  // Navigate away from settings page when floating panel is enabled
  useEffect(() => {
    if (useFloatingPanel && !isFloating && location.pathname === '/settings') {
      navigate('/dashboard');
    }
  }, [useFloatingPanel, isFloating, navigate, location.pathname]);

  // Get active tab from URL or default to 'general'
  useEffect(() => {
    if (isFloating) return; // Skip for floating mode
    
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    
    if (tab === 'account') {
      setActiveTab('account');
    } else if (tab === 'admin' && hasAdminAccess) {
      setActiveTab('admin');
    } else {
      setActiveTab('general');
    }
  }, [location.search, hasAdminAccess, isFloating]);

  // Content for General tab
  const generalTabContent = (
    <Box sx={{ ...scrollbarStyle, overflowY: 'auto' }}>
      <FormControlLabel
        control={
          <Switch 
            checked={useFloatingPanel} 
            onChange={(e) => {
              const newValue = e.target.checked;
              setUseFloatingPanel(newValue);
              localStorage.setItem('useFloatingPanel', JSON.stringify(newValue));
              
              if (newValue) {
                setIsSettingsOpen(true);
                if (location.pathname === '/settings') {
                  navigate('/dashboard');
                }
              } else {
                setIsSettingsOpen(false);
                if (location.pathname !== '/settings') {
                  navigate('/settings');
                }
              }
            }} 
          />
        }
        label="Use floating settings panel"
        sx={{ marginTop: 2 }}
      />
      <FormControlLabel
        control={<Switch checked={useSmallSidebar} onChange={(e) => setUseSmallSidebar(e.target.checked)} />}
        label="Use small sidebar"
        sx={{ marginTop: 2, marginLeft: 2 }}  
      />
      <FormControlLabel
        control={<Switch checked={autoHideSidebar} onChange={(e) => setAutoHideSidebar(e.target.checked)} />}
        label="Auto-hide sidebar"
        sx={{ marginTop: 2, marginLeft: 2 }}
      />
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 1, 
          marginTop: 2, 
          border: '1px solid #333', 
          padding: 2, 
          borderRadius: 2, 
          bgcolor: '#1e1e1e' 
        }}
      >
        <Typography variant="h6">Worker Server</Typography>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            label="Server IP"
            variant="outlined"
            value={serverIp}
            onChange={(e) => setServerIp(e.target.value)}
            sx={{ maxWidth: 150 }}
          />
          <Button variant="contained" color="primary" onClick={handleSaveIp}>
            Connect
          </Button>
        </Box>
      </Box>      
    </Box>
  );

  // Content for Account tab
  const accountTabContent = (
    <Box sx={{ ...scrollbarStyle, overflowY: 'auto' }}>
      <Box key="account" sx={{ display: 'flex', gap: 4, marginBottom: 4 }}>
        {/* User information */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: '#1e1e1e',
            padding: 3,
            borderRadius: 2,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Logged-in User Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Username"
                value={userInfo.username}
                fullWidth
                size="small"
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Full Name"
                value={`${userInfo.fornavn} ${userInfo.etternavn}`}
                fullWidth
                size="small"
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Role"
                value={userInfo.role}
                fullWidth
                size="small"
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Brands"
                value={userInfo.brands}
                fullWidth
                size="small"
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Group"
                value={userInfo.persgroup || 'N/A'}
                fullWidth
                size="small"
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="District"
                value={userInfo.persdistr}
                fullWidth
                size="small"
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Account Status"
                value={userInfo.freeze ? "Frozen" : "Active"}
                fullWidth
                size="small"
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>

        {/* Password reset */}
        <Box
          sx={{
            flex: 1,
            backgroundColor: '#1e1e1e',
            padding: 3,
            borderRadius: 2
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Reset Password
          </Typography>
          <ResetPassword />
        </Box>
      </Box>
    </Box>
  );

  // Prepare tabs and content
  let tabLabels = ["General", "Account"];
  let tabContents = [generalTabContent, accountTabContent];
  
  // Add Admin tab if user has access
  if (hasAdminAccess) {
    tabLabels.push("Admin");
    tabContents.push(<AdminPanel />);
  }

  // Custom dimensions for floating panel mode with additional height
  const floatingPanelDimensions = {
    minHeight: isFloating ? 'calc(100vh - 100px + 79px + 70px)' : 'calc(100vh - 400px)', // Added 79px + 70px
    maxHeight: isFloating ? 'calc(100vh - 60px + 79px + 70px)' : 'calc(100vh - 300px)',  // Added 79px + 70px
    width: isFloating ? 'calc(100% + 100px)' : '100%',                            
    padding: isFloating ? '35px' : '8px',
    margin: isFloating ? '5px 0' : '0px',
    borderRadius: isFloating ? '10px' : '4px',
    overflow: 'auto',
    flex: 1,
    ...scrollbarStyle // Apply scrollbar style to TabView dimensions
  };

  return (
    <Box 
      sx={{
        marginRight: '10px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(255, 255, 255, 0.15)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: 'rgba(255, 255, 255, 0.25)',
        },
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgba(255, 255, 255, 0.15) transparent',
        '& *': {
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.15)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(255, 255, 255, 0.25)',
          },
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgba(255, 255, 255, 0.15) transparent'
        }
      }}
    >
      <TabView
        key={`settings-tabview-${tabKey}`}
        tabLabels={tabLabels}
        tabContents={tabContents}
        disableUrlUpdate={isFloating}
        initialActiveTab={activeTab}
        preventFlicker={true}
        customSmallDimensions={floatingPanelDimensions}
      />
    </Box>
  );
}

export default Settings;
