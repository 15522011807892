import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TabPanel({ children, value, index, variant, customSmallDimensions }) {
  const defaultSmallStyle = {
    p: 1,
    border: '1px solid #3a3a3a',
    borderRadius: '4px',
    backgroundColor: '#252526',
    color: '#fff',
    mt: 1,
    width: '100%',
    margin: '0px',
    minHeight: 'calc(100vh - 400px)', // Default small height
    maxHeight: 'calc(100vh - 300px)', // Default small max height
    boxSizing: 'border-box',
    overflowY: 'auto',
  };

  // Enhanced panel styles for larger floating panel
  const panelStyle = variant === 'small'
    ? { 
        ...defaultSmallStyle, 
        ...(customSmallDimensions || {}),
        display: 'flex',
        flexDirection: 'column',
        // Allow for the larger content area
        '& .MuiGrid-container': {
          width: '100%',
          margin: 0,
          flexGrow: 1,
        },
        // Ensure content fills the expanded height
        '& > div': {
          height: '100%',
          minHeight: customSmallDimensions?.minHeight || defaultSmallStyle.minHeight,
          maxHeight: customSmallDimensions?.maxHeight || defaultSmallStyle.maxHeight,
          display: 'flex',
          flexDirection: 'column',
        },
        // Adjust grid items to expand properly
        '& .MuiGrid-item': {
          display: 'flex',
          flexDirection: 'column',
        }
      }
    : {
        p: 2,
        border: '2px solid #3a3a3a',
        borderRadius: '8px',
        backgroundColor: '#252526',
        color: '#fff',
        mt: 0,
        width: '100%',
        margin: '8px',
        minHeight: '100%',
        marginBottom: '60px',
        boxSizing: 'border-box',
        overflowY: 'auto',
      };

  return (
    <div hidden={value !== index} style={{ height: '100%', width: '100%' }}>
      {value === index && <Box sx={panelStyle}>{children}</Box>}
    </div>
  );
}

export default function TabView({ 
  tabLabels = [], 
  tabContents = [], 
  variant = 'normal', 
  customSmallDimensions,
  disableUrlUpdate = false,
  initialActiveTab = '',
  smallTabColors = {},
  preventFlicker = false
}) {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Keep track of manual tab changes to prevent URL-triggered flicker
  const manualTabChange = useRef(false);
  const lastTabName = useRef('');
  const [initialized, setInitialized] = useState(false);
  
  // Initialize tabs based on props or URL parameters
  const getInitialTab = () => {
    if (initialActiveTab) {
      return initialActiveTab.toLowerCase();
    }
    return query.get('tab') || (tabLabels.length > 0 ? tabLabels[0].toLowerCase() : '');
  };

  const getInitialSubTab = () => {
    return query.get('subTab') || (tabLabels.length > 0 ? tabLabels[0].toLowerCase() : '');
  };

  const [value, setValue] = useState(getInitialTab());
  const [subValue, setSubValue] = useState(getInitialSubTab());
  
  // Mark as initialized after first render
  useEffect(() => {
    setInitialized(true);
  }, []);

  // Update from initialActiveTab changes, but only if not during a manual change
  useEffect(() => {
    if (initialized && initialActiveTab && !manualTabChange.current) {
      setValue(initialActiveTab.toLowerCase());
    }
  }, [initialActiveTab, initialized]);

  // Handle URL updates
  useEffect(() => {
    if (!initialized || disableUrlUpdate || manualTabChange.current) return;
    
    const tabParam = query.get('tab');
    if (tabParam && tabParam !== value) {
      // Direct URL-based navigation
      setValue(tabParam);
    }
  }, [query, value, disableUrlUpdate, initialized]);

  // Simple direct tab change handler - updates state immediately
  const handleTabChange = (tabName) => {
    const lowerTabName = tabName.toLowerCase();
    
    // Don't do anything if already on this tab
    if (value === lowerTabName) return;
    
    // Prevent URL-triggered effects from changing tab during manual navigation
    manualTabChange.current = true;
    lastTabName.current = lowerTabName;
    
    // Update state immediately
    setValue(lowerTabName);
    setSubValue('');
    
    // Update URL if not disabled
    if (!disableUrlUpdate) {
      const updatedParams = new URLSearchParams(window.location.search);
      updatedParams.set('tab', lowerTabName);
      updatedParams.delete('subTab');
      navigate(`?${updatedParams.toString()}`);
      
      // Reset manual flag after a short delay
      setTimeout(() => {
        manualTabChange.current = false;
      }, 200); // Long enough for navigation to complete
    } else {
      // Reset immediately if we're not updating URL
      manualTabChange.current = false;
    }
  };

  const handleSubTabChange = (subTabName) => {
    const lowerSubTabName = subTabName.toLowerCase();
    
    // Don't do anything if already on this subtab
    if (subValue === lowerSubTabName) return;
    
    // Prevent URL-triggered effects
    manualTabChange.current = true;
    
    // Update state immediately
    setSubValue(lowerSubTabName);
    
    // Update URL if not disabled
    if (!disableUrlUpdate) {
      const updatedParams = new URLSearchParams(window.location.search);
      updatedParams.set('subTab', lowerSubTabName);
      navigate(`?${updatedParams.toString()}`);
      
      // Reset manual flag after a short delay
      setTimeout(() => {
        manualTabChange.current = false;
      }, 200); // Long enough for navigation to complete
    } else {
      // Reset immediately if we're not updating URL
      manualTabChange.current = false;
    }
  };

  // Default small tab colors
  const defaultSmallTabColors = {
    active: '#e9531f',
    inactive: '#69250d',
    activeHover: '#8c2e10',
    inactiveHover: '#a53713',
  };

  // Merge custom colors with defaults
  const tabColors = {
    ...defaultSmallTabColors,
    ...smallTabColors
  };

  const tabStyle = variant === 'small'
    ? {
        width: '140px',
        height: '40px',
        margin: '5px',
        marginTop: '-5px',
        marginBottom: '5px',
      }
    : {
        width: '180px',
        height: '80px',
        margin: '6px',
        marginBottom: '-15px',
      };

  // This is the actual render function - simplified to be more predictable
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {/* Tab Headers */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: variant === 'small' ? 0 : 0,
          mb: variant === 'small' ? 0 : 2,
          paddingLeft: '2px',
          paddingRight: '8px',
          paddingBottom: '0px',
          paddingTop: '8px',
          boxSizing: 'border-box',
        }}
      >
        {tabLabels.map((label, index) => {
          const isSelected = 
            (variant === 'small' ? subValue : value) === label.toLowerCase();
            
          return (
            <Box
              key={`tab-${label}-${index}`}
              onClick={() => {
                if (variant === 'small') {
                  handleSubTabChange(label.toLowerCase());
                } else {
                  handleTabChange(label.toLowerCase());
                }
              }}
              sx={{
                ...tabStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                borderRadius: variant === 'small' ? '4px' : '8px',
                boxShadow: isSelected ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
                backgroundColor: isSelected
                  ? variant === 'small' ? tabColors.active : '#1677ff'
                  : variant === 'small' ? tabColors.inactive : '#303031',
                color: isSelected ? '#fff' : '#c5c5c5',
                cursor: 'pointer',
                transition: 'background-color 0.2s, transform 0.1s, box-shadow 0.1s',
                '&:hover': {
                  backgroundColor: isSelected
                    ? variant === 'small' ? tabColors.activeHover : '#1677ff'
                    : variant === 'small' ? tabColors.inactiveHover : '#3b3b3b',
                  color: '#fff',
                  transform: 'scale(1.02)',
                  boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)',
                },
                '&:active': {
                  transform: 'scale(0.95)',
                },
              }}
            >
              <Typography 
                variant="body1" 
                fontWeight={isSelected ? 'bold' : 'normal'}
              >
                {label}
              </Typography>
            </Box>
          );
        })}
      </Box>

      {/* Tab Content */}
      {tabContents.map((content, index) => {
        const tabIndex = tabLabels[index].toLowerCase();
        const tabValue = variant === 'small' ? subValue : value;
        
        return (
          <TabPanel 
            key={`panel-${tabIndex}`} 
            value={tabValue} 
            index={tabIndex} 
            variant={variant} 
            customSmallDimensions={customSmallDimensions}
          >
            {content}
          </TabPanel>
        );
      })}
    </Box>
  );
}
