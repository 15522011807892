import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, Typography, Select, MenuItem, List, ListItem, ListItemText, IconButton, ListItemSecondaryAction } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';
import JobViewer from './_workermanager/JobViewer';
import TabView from './shared/TabView';
import FunctionViewer from './_workermanager/FunctionViewer';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';

// Add a utility for API calls with proper error handling
const apiCall = async (url, options = {}) => {
  try {
    // Ensure we have headers with proper content type
    const headers = {
      'Content-Type': 'application/json',
      ...options.headers
    };
    
    // Create the full options object with defaults
    const fetchOptions = {
      ...options,
      headers,
      mode: 'cors', // Explicitly set CORS mode
      credentials: 'include', // Include credentials if needed
    };
    
    const response = await fetch(url, fetchOptions);
    
    // Handle non-ok responses
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`API Error (${response.status}): ${errorText}`);
    }
    
    // For non-JSON responses (like images)
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return await response.json();
    }
    
    return response;
  } catch (error) {
    console.error(`API call failed: ${error.message}`);
    throw error;
  }
};

const WorkerManager = () => {
  const [serverIp, setServerIp] = useState(localStorage.getItem('serverIp') || '');
  const [availableServers, setAvailableServers] = useState([]);
  const [jobResult, setJobResult] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [refreshInterval, setRefreshInterval] = useState(Number(localStorage.getItem('refreshInterval')) || 5000);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [newWorkerIp, setNewWorkerIp] = useState('');
  const [totalJobQueue, setTotalJobQueue] = useState([]);
  const [jobStatuses, setJobStatuses] = useState({});
  const [workerScreenshots, setWorkerScreenshots] = useState({});
  const [selectedJob, setSelectedJob] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subTab = queryParams.get('subTab') || 'Status';

  const userSelectedJob = useRef(false); // Sporer om brukeren har valgt manuelt

  
  const activeJobRequests = new Set(); // Holder styr på hvilke jobber som sjekkes


  const [serverInfo, setServerInfo] = useState({
    cpu_usage: null,
    memory_usage: null,
    job_queue_length: null,
    jobs_last_day: null,
    jobs_last_hour: null,
  });
  

  const imageRef = useRef(null); // Referanse til bildet

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      imageRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchWorkers();
      fetchTotalJobQueue();
      //updateJobStatuses();
    }, 1000);
    return () => clearInterval(interval);
  }, [serverIp]);

  useEffect(() => {
    console.log("Selected job changed:", selectedJob);
  }, [selectedJob]);
  

  useEffect(() => {
    let interval = null;
  
    if (autoRefresh) {
      interval = setInterval(() => {
        fetchScreenshot();
      }, refreshInterval);
    } else if (!autoRefresh && interval) {
      clearInterval(interval);
    }
  
    return () => clearInterval(interval);
  }, [autoRefresh, refreshInterval]);
  

  useEffect(() => {
    if (totalJobQueue.every((job) => jobStatuses[job.job_id]?.status === 'done')) {
      return;
    }
    const interval = setInterval(() => updateJobStatuses(), 30);
    return () => clearInterval(interval);
  }, [totalJobQueue, jobStatuses]);
  

  useEffect(() => {
    const interval = setInterval(() => fetchTotalJobQueue(), 5000);
    return () => clearInterval(interval);
  }, [serverIp]);
  
  
  const handleSaveIp = () => {
    localStorage.setItem('serverIp', serverIp);
    //alert('Server IP lagret!');
    message.success('Server IP lagret!');
  };


  const handleSubmitJob = async (functionName, args, kwargs) => {
    if (!serverIp) {
      message.error('You are not connected to the server. Please connect first.');
      return;
    }
  
    try {
      const result = await apiCall(`http://${serverIp}:5001/submit_job`, {
        method: 'POST',
        body: JSON.stringify({
          function_name: functionName,
          args: args || [],
          kwargs: kwargs || {},
        }),
      });
      
      message.success(`Job sent to a worker! Jobb-ID: ${result.job_id}`);
    } catch (error) {
      message.error(`Error with connection: ${error.message}`);
    }
  };
  

  const [serverStatus, setServerStatus] = useState({
    status: "Connecting...", // Default status
    color: "yellow",
    lastResponseTime: null,
  });
  
useEffect(() => {
  const checkServerStatus = async () => {
    if (!serverIp) return;
  
    try {
      const response = await fetch(`http://${serverIp}:5001/status`);
      if (response.ok) {
        const data = await response.json();
  
        setServerStatus({
          status: "Connected",
          color: "green",
          lastResponseTime: Date.now(),
        });
  
        // Oppdater serverinfo
        setServerInfo({
          cpu_usage: data.cpu_usage,
          memory_usage: data.memory_usage,
          job_queue_length: data.job_queue_length,
          jobs_last_day: data.jobs_last_day,
          jobs_last_hour: data.jobs_last_hour,
        });
  
      } else {
        throw new Error("Server unreachable");
      }
    } catch (error) {
      setServerStatus((prev) => {
        const timeSinceLastResponse = prev.lastResponseTime ? (Date.now() - prev.lastResponseTime) / 1000 : null;
        if (timeSinceLastResponse && timeSinceLastResponse > 30) {
          return { status: "No response in 30s", color: "yellow", lastResponseTime: prev.lastResponseTime };
        }
        return { status: "Not connected to server", color: "red", lastResponseTime: prev.lastResponseTime };
      });
    }
  };
  
    checkServerStatus(); // Check immediately
    const interval = setInterval(checkServerStatus, 5000); // Check every 5s
    return () => clearInterval(interval);
  }, [serverIp]);


  
  
  const fetchScreenshot = async () => {
    if (!serverIp) {
      //alert('Oppgi IP-adressen til serveren først.');
      message.error('Oppgi IP-adressen til serveren først.');
      return;
    }

    try {
      const response = await apiCall(`http://${serverIp}:5001/get_all_screenshots`);
      if (response instanceof Response) {
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setScreenshot(imageUrl);
      } else {
        throw new Error('Expected Response object but got JSON');
      }
    } catch (error) {
      setScreenshot(null);
      console.error('Error fetching screenshot:', error);
    }
  };


  useEffect(() => {
    let interval;
    const controller = new AbortController();
    const signal = controller.signal;
  
    const fetchAndUpdateScreenshot = async () => {
      if (workers.some((worker) => worker.ip === subTab)) {
        try {
          const response = await fetch(`http://${serverIp}:5001/get_worker_screenshot?ip=${subTab}`, { signal });
          if (response.ok) {
            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);
  
            setWorkerScreenshots((prev) => {
              if (prev[subTab]) {
                URL.revokeObjectURL(prev[subTab]);
              }
              return { ...prev, [subTab]: imageUrl };
            });
          } else {
            console.error('Feil ved henting av skjermbilde:', response.statusText);
          }
        } catch (error) {
          if (error.name !== 'AbortError') {
            console.error('Feil ved tilkobling:', error);
          }
        }
      }
    };
  
    // Hent bilde umiddelbart og sett opp intervallet
    fetchAndUpdateScreenshot();
    if (workers.some((worker) => worker.ip === subTab)) {
      interval = setInterval(fetchAndUpdateScreenshot, 1000);
    }
  
    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, [workers, subTab, serverIp]);
  



  const fetchWorkers = async () => {
    if (!serverIp) return;
    
    try {
      const result = await apiCall(`http://${serverIp}:5001/get_all_workers`);
      
      // Oppdaterer workers med all nødvendig informasjon
      const updatedWorkers = result.workers.map((worker) => ({
        ...worker,
        active_job_name: worker.active_job_name || 'None',
        active_job_id: worker.active_job_id || 'None',
        queue_length: worker.queue_length || 0,
      }));

      setWorkers(updatedWorkers);
    } catch (error) {
      console.error('Failed to fetch workers:', error);
      // Optionally show error message to user
    }
  };
  

  const addWorker = async () => {
    if (!serverIp || !newWorkerIp) {
      //alert('Oppgi både serverens IP-adresse og workerens IP-adresse.');
      message.error('Oppgi både serverens IP-adresse og workerens IP-adresse.');
      return;
    }
  
    try {
      const response = await fetch(`http://${serverIp}:5001/register_worker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ip: newWorkerIp }),
      });
  
      const result = await response.json();
      if (response.ok) {
        //alert('Worker lagt til!');
        message.success('Worker lagt til!');
        setNewWorkerIp('');
        fetchWorkers(); // Oppdater listen over workers
      } else {
        //alert(`Feil: ${result.error}`);
        message.error(`Feil: ${result.error}`);
      }
    } catch (error) {
      console.error('Feil ved tilkobling:', error);
    }
  };
  

  const removeWorker = async (workerIp) => {
    if (!serverIp) {
      //('Oppgi serverens IP-adresse først.');
      message.error('Oppgi serverens IP-adresse først.');
      return;
    }

    try {
      const response = await fetch(`http://${serverIp}:5001/remove_worker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ip: workerIp }),
      });

      const result = await response.json();
      if (response.ok) {
        //alert('Worker fjernet!');
        message.success('Worker fjernet!');
        fetchWorkers();
      } else {
        //alert(`Feil: ${result.error}`);
        message.error(`Feil: ${result.error}`);
      }
    } catch (error) {
      console.error('Feil ved tilkobling:', error);
    }
  };

  // Forbered funksjonen for nullstilling
  const resetWorker = async (workerIp) => {
    if (!serverIp) {
      message.error('Oppgi serverens IP-adresse først.');
      return;
    }

    try {
      const response = await fetch(`http://${serverIp}:5001/reset_worker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ip: workerIp }),
      });
      if (response.ok) {
        message.success(`Worker ${workerIp} ble nullstilt.`);
        fetchWorkers(); // Oppdater listen over workers
      } else {
        message.error(`Feil ved nullstilling av worker: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Feil ved tilkobling:', error);
      message.error('Feil ved tilkobling.');
    }
  };

  const fetchTotalJobQueue = async () => {
 
    try {
      const response = await fetch(`http://${serverIp}:5001/get_job_queue`);
      if (response.ok) {
        const result = await response.json();
        setTotalJobQueue(result.job_queue || []);
      } else {
        console.error('Feil ved henting av total jobbkø:', response.statusText);
      }
    } catch (error) {
      console.error('Feil ved tilkobling:', error);
    }
  };
  



  
  const fetchJobStatus = async (jobId) => {
    if (
      !serverIp || 
      jobStatuses[jobId]?.status === 'done' || 
      jobStatuses[jobId]?.status === 'failed'
    ) {
      return; // Ikke hent status for ferdige eller feilede jobber
    }
  
    try {
      const response = await fetch(`http://${serverIp}:5001/job_status/${jobId}`);
      if (response.ok) {
        const result = await response.json();
  
        // Hvis jobben har en feilstatus
        if (result.status?.status === 'error') {
          console.warn(`Jobb ${jobId} har feilet:`, result.status?.error || 'Ukjent feil');
          setJobStatuses((prevStatuses) => ({
            ...prevStatuses,
            [jobId]: { status: 'failed', error: result.status?.error || 'Ukjent feil' },
          }));
          return; // Stopp videre behandling for denne jobben
        }
  
        // Oppdater status for jobben hvis ingen feil
        setJobStatuses((prevStatuses) => ({
          ...prevStatuses,
          [jobId]: result.status,
        }));
      } else {
        console.error(`Feilrespons for jobb ${jobId}:`, response.statusText);
        setJobStatuses((prevStatuses) => ({
          ...prevStatuses,
          [jobId]: { status: 'failed', error: response.statusText },
        }));
      }
    } catch (error) {
      console.error(`Feil ved henting av status for jobb ${jobId}:`, error);
      setJobStatuses((prevStatuses) => ({
        ...prevStatuses,
        [jobId]: { status: 'failed', error: error.message },
      }));
    }
  };
  



const updateJobStatuses = () => {
  totalJobQueue
    .filter(
      (job) =>
        !activeJobRequests.has(job.job_id) && // Unngå dobbelt-forespørsler
        jobStatuses[job.job_id]?.status !== 'done' &&
        jobStatuses[job.job_id]?.status !== 'failed' // Ignorer ferdige og feilede jobber
    )
    .forEach((job) => {
      activeJobRequests.add(job.job_id); // Merk jobben som aktiv
      fetchJobStatus(job.job_id).finally(() => {
        activeJobRequests.delete(job.job_id); // Fjern jobben fra aktive etter forespørsel
      });
    });
};

useEffect(() => {
  if (totalJobQueue.every((job) => jobStatuses[job.job_id]?.status === 'done')) {
    return;
  }
  const interval = setInterval(() => updateJobStatuses(), 2000); // Oppdater hvert 2. sekund
  return () => clearInterval(interval);
}, [totalJobQueue, jobStatuses]);

  
  
  
  
  const handleRefreshIntervalChange = (event) => {
    const interval = Number(event.target.value);
    setRefreshInterval(interval);
    localStorage.setItem('refreshInterval', interval);
  };


  const handleRestartServer = async (serverIp) => {
    try {
        const response = await fetch(`http://${serverIp}:5001/restart_server`, {
            method: 'POST',
        });

        if (!response.ok) {
            throw new Error('Failed to restart server');
        }

        //alert('Server is restarting...');
        message.info('Server is restarting...');
    } catch (error) {
        message.info('Server is restarting...'); // Server restarts too quick, so there is no time to get a response
    }
};

// handle restart_all_workers
const handleRestartAllWorkers = async () => {
  try {
    if  (!window.confirm('Are you sure you want to restart all workers?')) {
      return;
    }
    else {

      const response = await fetch(`http://${serverIp}:5001/reset_all_workers`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Failed to restart all workers');
      }

      message.info('All workers are restarting...');
    }
  } catch (error) {
    message.info('Could not restart all workers.');
  }

};

  // Add this new function to fetch available servers
  const fetchAvailableServers = async () => {
    try {
      const response = await fetch('https://scripthub.serviceorderhub.com/dashboardapi/workers/listsecure');
      if (response.ok) {
        const data = await response.json();
        const servers = data.workers.filter(worker => 
          worker.worker_type === 'server' && 
          worker.operational && 
          worker.worker_local_ip // ensure worker_local_ip exists
        );
        
        setAvailableServers(servers);
        
        // If no server is selected yet, select the first available one
        if (!serverIp && servers.length > 0) {
          const firstServer = servers[0].worker_local_ip;
          setServerIp(firstServer);
          localStorage.setItem('serverIp', firstServer);
        }
      } else {
        console.error('Failed to fetch servers:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching available servers:', error);
    }
  };

  // Add useEffect to fetch servers when component mounts
  useEffect(() => {
    fetchAvailableServers();
  }, []);

  const tabLabels = ['Connection', 'Status', ...workers.map((worker) => worker.ip)];
  const tabContents = [
    <Box key="connection">
      {/* Status Indicator */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: 4 }}>
          <Box sx={{ width: 16, height: 16, borderRadius: "50%", backgroundColor: serverStatus.color }} />
          <Typography variant="body1">{serverStatus.status}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
          <Select
            value={serverIp}
            onChange={(e) => {
              setServerIp(e.target.value);
              localStorage.setItem('serverIp', e.target.value);
            }}
            sx={{ minWidth: 200 }}
            displayEmpty
          >
            <MenuItem value="" disabled>Select a server</MenuItem>
            {availableServers.map((server) => (
              <MenuItem key={server.worker_id} value={server.worker_local_ip}>
                {server.worker_local_ip} ({server.worker_location})
              </MenuItem>
            ))}
          </Select>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => {
              handleSaveIp();
              fetchAvailableServers();
            }}
          >
            Connect
          </Button>
          <IconButton onClick={fetchAvailableServers} sx={{ ml: 1 }}>
            <RefreshIcon />
          </IconButton>
          
          <TextField
            label="Worker IP"
            variant="outlined"
            value={newWorkerIp}
            onChange={(e) => setNewWorkerIp(e.target.value)}
            sx={{ maxWidth: 150, marginLeft: 2 }}
          />
          <Button variant="contained" color="primary" onClick={addWorker}>
            Add a worker IP
          </Button>
          
        </Box>

        {/* Serverinfo */}
        <Box sx={{ marginBottom: 2, color: '#667070' }}>
          <Typography variant="h6">Server Info</Typography>
          <Typography variant="body1">Memory Usage: {serverInfo.memory_usage || 'N/A'}%</Typography>
          <Typography variant="body1">Job Queue Length: {serverInfo.job_queue_length || 'N/A'}</Typography>
          <Typography variant="body1">Jobs Last 24h: {serverInfo.jobs_last_day || 'N/A'}</Typography>
          <Typography variant="body1">Jobs Last 1h: {serverInfo.jobs_last_hour || 'N/A'}</Typography>
        </Box>
            {/* Server Restart Button */}
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2, marginBottom: 2 }}>
                  <Button variant="contained" color="secondary" sx={{height: "20px", width: "150px", backgroundColor: "#667070", color: "#fffX"}} onClick={() => handleRestartServer(serverIp)}>
                    Reload Server
                  </Button>
                </Box>
                  </Box>,



                  <Box key="status" sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1, overflowY: 'auto' }}>
                  <Typography variant="h6">Connected Workers</Typography>
                  <List>
                  {workers.map((worker, index) => (
                  <ListItem key={index}>
                    <ListItemText
                  primary={`IP: ${worker.ip}`}
                  secondary={`Status: ${worker.status || 'Unknown'}  | Queue: ${worker.queue_length} | Current job: ${worker.active_job_name || 'None'}`}
                    />
                    <ListItemSecondaryAction>
                        {/* Reset Worker Button */}
                      <IconButton edge="end" sx={{ marginRight: 1 }} onClick={() => resetWorker(worker.ip)}>
                      <RefreshIcon />
                      </IconButton>
                      {/* Remove Worker Button with Confirmation */}
                      <IconButton
                      edge="end"
                      onClick={() => {
                      if (window.confirm(`Are you sure you want to remove the worker with IP: ${worker.ip}? This action cannot be undone.`)) {
                        removeWorker(worker.ip);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>

      </Box>
      <Box sx={{ flex: 1 }}>
        
        
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 2, marginBottom: 2 }}>
        <Button variant="contained" color="secondary" sx={{height: "20px", width: "200px", backgroundColor: "#667070", color: "#fffX"}} onClick={() => handleRestartAllWorkers(serverIp)}>
          Restart all workers
        </Button>
      </Box>


  {/* Knapp og dropdown i samme rad, fast posisjon */}
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    gap: 1, 
    position: 'sticky', 
    top: 0, 
    backgroundColor: '#1e1e1e', 
    padding: 1, 
    zIndex: 1000 
  }}>
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={() => setAutoRefresh(!autoRefresh)}
      sx={{ height: 36 }} // Sikrer at knappen har samme høyde som dropdown
    >
      {autoRefresh ? 'Stop' : 'Play'}
    </Button>
    <Select
      value={refreshInterval}
      onChange={handleRefreshIntervalChange}
      size="small"
      sx={{ 
        minWidth: 100, 
        maxWidth: 120, 
        fontSize: '0.9rem', 
        height: 36, // Matcher knappens høyde
        padding: '0 10px', // Justerer padding for sentrering
        '.MuiSelect-select': { display: 'flex', alignItems: 'center' } // Sikrer vertikal sentrering
      }}
    >
      <MenuItem value={1000}>1s</MenuItem>
      <MenuItem value={5000}>5s</MenuItem>
      <MenuItem value={10000}>10s</MenuItem>
    </Select>
  </Box>

  {/* Bildet vises under knappen */}
{/*   {screenshot && (
    <Box sx={{ marginTop: 2 }}>
      <img 
        src={screenshot} 
        alt="Worker Screenshot" 
        style={{ maxWidth: '100%', height: '600px', objectFit: 'contain' }} 
      />
    </Box>
  )} */}

{screenshot && (
  <Box sx={{ marginTop: 2, textAlign: 'center' }}>
    <img
      ref={imageRef}
      src={screenshot}
      alt="Worker Screenshot"
      onClick={toggleFullscreen}
      style={{
        maxWidth: '100%',
        height: '600px',
        objectFit: 'contain',
        cursor: 'pointer',
        transition: 'transform 0.3s ease-in-out',
      }}
    />
  </Box>
)}
</Box>

    </Box>,
    ...workers.map((worker) => (
      <Box key={worker.ip}>
        <Typography variant="h6">Status for Worker: {worker.ip}</Typography>
        <Typography variant="body1">Status: {worker.status || 'Ukjent'}</Typography>
        {workerScreenshots[worker.ip] && (
          <Box sx={{ marginTop: 2 }}>
            <img src={workerScreenshots[worker.ip]} alt="Worker Screenshot" style={{ maxWidth: '100%', height: '750px', objectFit: 'contain' }} />
          </Box>
        )}
      </Box>
    )),
  ];




  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Worker Management
      </Typography>
      
      {/* Job Control */}
      <Box sx={{ marginBottom: 2 }}>
      </Box>
      {jobResult && (
        <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 2 }}>
          {typeof jobResult === 'string' ? jobResult : JSON.stringify(jobResult)}
        </Typography>
      )}
  
      {/* Job Viewer and Function Viewer */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 4, maxWidth: '100%' }}>
        {/* FunctionViewer */}
        <FunctionViewer 
          serverIp={serverIp} 
          onSubmit={handleSubmitJob} 
        />

  
        {/* JobViewer */}
        <JobViewer 
            jobs={totalJobQueue} 
            jobStatuses={jobStatuses}
            selectedJob={selectedJob} 
            setSelectedJob={setSelectedJob} 
        />
        <TabView 
          tabLabels={tabLabels}
          variant="small" 
          tabContents={tabContents.map((content, index) => (
            <Box
              key={index}
              sx={{
                minHeight: '800px', // Sett fast høyde
                maxHeight: '800px',
                overflowY: 'auto', // Legg til scrollbar hvis nødvendig
                padding: 2,
                backgroundColor: '#252526', // Juster bakgrunn
                borderRadius: '8px',
              }}
            >
              {content}
            </Box>
          ))}
        />
      </Box>
  

    </Box>
  );
  
  
  
  
};

export default WorkerManager;
