import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import Chat from "./Chat";
import { useChat } from "./ChatContext";
import { useLocation } from "react-router-dom";

const CHAT_WIDTH = 400;
const STATUS_BAR_HEIGHT = 32;

const Panel = styled.div`
  position: ${props => props.isInSocial ? 'relative' : 'fixed'};
  top: ${props => props.isInSocial ? '0' : '0'};
  right: ${props => {
    if (props.isInSocial) return '0';
    return props.isChatOpen || props.isChatLocked ? "0" : `-${CHAT_WIDTH}px`;
  }};
  width: ${props => props.isInSocial ? '100%' : `${CHAT_WIDTH}px`};
  height: ${props => props.isInSocial ? '100%' : `calc(100vh - ${STATUS_BAR_HEIGHT}px)`};
  background: ${props => props.isInSocial ? 'transparent' : '#2d2f33'};
  box-shadow: ${props => props.isInSocial ? 'none' : '-5px 0 10px rgba(0, 0, 0, 0.3)'};
  display: flex;
  flex-direction: column;
  transition: ${props => props.isInSocial ? 'none' : 'right 0.3s ease-in-out'};
  z-index: ${props => props.isInSocial ? '1' : '999'};
  pointer-events: auto;
  flex: 1;
`;

const ChatPanel = () => {
  const { isChatOpen, isChatLocked, setIsChatOpen } = useChat();
  const panelRef = useRef(null);
  const location = useLocation();
  const isInSocial = location.pathname === "/social";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!isInSocial && panelRef.current && !panelRef.current.contains(event.target) && !isChatLocked) {
        setIsChatOpen(false);
      }
    };

    if (isChatOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isChatOpen, setIsChatOpen, isChatLocked, isInSocial]);

  return (
    (isChatOpen || isChatLocked || isInSocial) ? (
      <Panel 
        ref={panelRef} 
        isChatOpen={isChatOpen} 
        isChatLocked={isChatLocked}
        isInSocial={isInSocial}
      >
        <Chat isInSocial={isInSocial} />
      </Panel>
    ) : null
  );
};

export default ChatPanel;
