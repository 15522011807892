// src/components/StatusBar.js
import React, { useEffect, useState, useContext } from "react";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import ServerStatus from "./ServerStatus";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useChat } from "./_social/ChatContext"; // 🎯 Importerer ChatContext
import { AuthContext } from "./AuthContext"; // Import AuthContext
import { SettingsContext } from "./SettingsContext"; // Add this import

const STATUS_BAR_HEIGHT = 32; // 🎯 Fast høyde på statusbaren

function FormatText({ text }) {
  return (
    <Typography
      sx={{
        color: "#878787",
        fontSize: "0.8rem",
        textAlign: "right",
      }}
    >
      {text}
    </Typography>
  );
}

function StatusBar() {
  const { isChatOpen, setIsChatOpen, isChatLocked, toggleChat } = useChat();
  const { isAuthenticated, role } = useContext(AuthContext); // Add role to destructuring
  const { useSmallSidebar, autoHideSidebar, isSidebarOpen } = useContext(SettingsContext);
  const [sessionTimeLeft, setSessionTimeLeft] = useState("");

  const calculateTimeLeft = () => {
    const token = Cookies.get("token");
    if (!token) {
      setSessionTimeLeft("Session expired");
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      const timeLeft = decodedToken.exp - currentTime;

      if (timeLeft <= 0) {
        setSessionTimeLeft("Session expired");
        return;
      }

      const hours = Math.floor(timeLeft / 3600);
      const minutes = Math.floor((timeLeft % 3600) / 60);
      const seconds = Math.floor(timeLeft % 60);
      setSessionTimeLeft(`Session expiring in: ${hours}h ${minutes}m ${seconds}s`);
    } catch (error) {
      console.error("Feil ved dekoding av token:", error);
      setSessionTimeLeft("Error calculating session time");
    }
  };

  useEffect(() => {
    calculateTimeLeft();
    const interval = setInterval(() => {
      calculateTimeLeft();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!isAuthenticated && isChatOpen) {
      setIsChatOpen(false);
    }
  }, [isAuthenticated, isChatOpen, setIsChatOpen]);

  // Simplified sidebar width calculation - remove role-specific check
  const sidebarWidth = autoHideSidebar && !isSidebarOpen ? 0 : (useSmallSidebar ? 120 : 180);

  return (
    <Box
      sx={{
        width: `calc(100% - ${sidebarWidth}px)`,
        bgcolor: "#252526",
        color: "#ffffff",
        textAlign: "left",
        p: 0.5,
        position: "fixed",
        bottom: 0,
        left: sidebarWidth,
        height: `${STATUS_BAR_HEIGHT}px`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2,
        transition: theme => theme.transitions.create(['width', 'left'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.standard,
        }),
      }}
    >
      {/* Venstre side - Server status */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {role !== 'workers' && role !== 'repair-cost' && (
          <>
            <FormatText text="System status:&nbsp;" />
            <ServerStatus />
          </>
        )}
        {(role === 'workers' || role === 'repair-cost') && (
          <>
            <FormatText text="Backend status:&nbsp;" />
            <ServerStatus showOnlyBackend={true} />
          </>
        )}
      </Box>

      {/* Høyre side - Chat-knapp + Session info */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/* Chat-knapp - skjul for repair-cost rolle */}
        {role !== 'repair-cost' && (
          <Tooltip title={!isAuthenticated ? "Please log in to chat" : (isChatLocked ? "Unlock & close chat" : isChatOpen ? "Close chat" : "Open chat")}>
            <IconButton
              onClick={toggleChat}
              disabled={!isAuthenticated}
              sx={{
                flexShrink: 0,
                color: isChatLocked ? "#ff4747" : isChatOpen ? "#4a9bfc" : "#878787",
                "&:hover": { color: isChatLocked ? "#e33a3a" : "#3580e3" },
                p: 0.5,
              }}
            >
              {isChatOpen ? <ChatBubbleIcon fontSize="small" /> : <ChatBubbleOutlineIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        )}
        
        <Box sx={{ width: "250px", textAlign: "right" }}>
          <FormatText text={sessionTimeLeft} />
        </Box>
      </Box>
    </Box>
  );
}

export { StatusBar, STATUS_BAR_HEIGHT };
export default StatusBar;
