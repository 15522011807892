import { useEffect, useContext } from 'react';
import { AuthContext } from '../components/AuthContext';
import { SettingsContext } from '../components/SettingsContext';

/**
 * Hook to apply specific settings based on user role
 */
const useRoleSpecificSettings = () => {
  const { role, isAuthenticated } = useContext(AuthContext);
  const { setUseSmallSidebar } = useContext(SettingsContext);

  useEffect(() => {
    if (isAuthenticated) {
      // Apply small sidebar for specific roles
      if (role === 'repair-cost' || role === 'price-admin') {
        console.log(`Applying small sidebar for role: ${role}`);
        localStorage.setItem('useSmallSidebar', 'true');
        setUseSmallSidebar(true);
      }
    }
  }, [role, isAuthenticated, setUseSmallSidebar]);
};

export default useRoleSpecificSettings;
