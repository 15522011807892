import React, { useState, useEffect } from "react";
import { Card, Typography } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const DailyProgressWidget = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 60000); // Oppdater hvert minutt
        return () => clearInterval(interval);
    }, []);

    const fetchData = async () => {
        try {
            const token = Cookies.get("token");
            if (!token) throw new Error("No token found");

            const decodedToken = jwtDecode(token);
            const username = decodedToken.sub;

            // Hent data
            const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/activity/daily-summary`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ username, token })
            });

            if (!response.ok) throw new Error("Failed to fetch daily progress data");
            const result = await response.json();

            // Oppdater datastrukturen: Én rad med alle verdier
            setData([
                {
                    label: "Oversikt",
                    totalQueue: result.totalQueue || 0,
                    newToday: result.newToday || 0,
                    startedToday: result.startedToday || 0
                }
            ]);
        } catch (error) {
            console.error("Failed to fetch daily progress data:", error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Card sx={{ width: 600, padding: 2, backgroundColor: "#1e1e1e", color: "#fff", textAlign: "center", height: "330px" }}>
                <Typography variant="h6">Progress</Typography>
                <Typography variant="h5">Loading...</Typography>
            </Card>
        );
    }

    return (
        <Card sx={{ width: 600, padding: 2, backgroundColor: "#1e1e1e", color: "#fff", textAlign: "fixed", height: "330px" }}>
            <Typography variant="h6" gutterBottom>
                Progress
            </Typography>
            <ResponsiveContainer width="120%" height={250}>
                <BarChart data={data} layout="vertical" margin={{ top: 0, right: 180, left: -50, bottom: 10 }}>
                <XAxis type="number" tickCount={10} allowDecimals={false} />
                    <YAxis dataKey="label" type="category" tick={false} /> {/* Skjuler feil labels */}
                    <Tooltip />
                    <Legend />

                    {/* Korrekte labels og plasseringer */}
                    <Bar dataKey="totalQueue" fill="#6a0dad" barSize={60} name="Status Ass" />
                    <Bar dataKey="newToday" fill="#82ca9d" barSize={60} name="Inflow today" />
                    <Bar dataKey="startedToday" fill="#ff7300" barSize={60} name="Start today" />
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default DailyProgressWidget;
