// src/components/_dashboard/WidgetPanel.js
import React, { forwardRef } from 'react';
import { Box, Typography, Switch } from '@mui/material';
import DigitalWatch from './_widgets/DigitalWatch';
import AnalogWatch from './_widgets/AnalogWatch';
import ServiceQueueWidget from './_widgets/ServiceQueueWidget';
import DailyProgressWidget from './_widgets/DailyProgressWidget';
import ProductCategoryWidget from './_widgets/ProductCategoryWidget';
import DataSourceWidget from './_widgets/DataSourceWidget';
import UserInfoWidget from './_widgets/UserInfoWidget';
import ScreenshotWidget from './_widgets/ScreenshotWidget';

const widgets = [
    { id: 1, name: "Digital Watch", component: DigitalWatch },
    { id: 2, name: "Analog Watch", component: AnalogWatch },
    { id: 3, name: "Service Queue", component: ServiceQueueWidget },
    { id: 4, name: "Daily Progress", component: DailyProgressWidget },
    { id: 5, name: "Apple Product Categories", component: () => <ProductCategoryWidget brand="Apple" /> },
    { id: 6, name: "Samsung Product Categories", component: () => <ProductCategoryWidget brand="Samsung" /> },
    { id: 7, name: "Data Source", component: DataSourceWidget },
    { id: 8, name: "User Info", component: UserInfoWidget },
    { id: 9, name: "System Screenshot", component: ScreenshotWidget }
];


const WidgetPanel = forwardRef(({ isOpen, onToggleWidget, activeWidgets }, ref) => (
	<Box
		ref={ref}
		sx={{
			position: 'fixed',
			top: 0,
			right: 0,
			width: 300,
			height: '100vh',
			backgroundColor: '#333',
			color: 'white',
			overflowY: 'auto',
			transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
			transition: 'transform 0.3s ease-in-out',
			padding: 2,
			zIndex: 1200,
		}}
	>
		<Typography variant="h5" gutterBottom>
			Widgets
		</Typography>
		<Typography variant="body1">
			Enable or disable widgets to customize your dashboard.
		</Typography>

		<Box sx={{ marginTop: 2 }}>
			{widgets.map((widget) => {
				const isWidgetActive = activeWidgets.some((w) => w.id === widget.id);
				return (
					<Box
						key={widget.id}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginBottom: 2,
						}}
					>
						<Typography variant="subtitle1">{widget.name}</Typography>
						<Switch
							color="primary"
							checked={isWidgetActive}
							onChange={(e) => onToggleWidget(widget, e.target.checked)}
						/>
					</Box>
				);
			})}
		</Box>
	</Box>
));

export default WidgetPanel;
