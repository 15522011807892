import React, { useContext, useEffect } from 'react';
import { Modal, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SettingsContext } from './SettingsContext';
import Settings from './Settings';
import { useNavigate, useLocation } from 'react-router-dom';

const FloatingSettingsPanel = () => {
  const { isSettingsOpen, setIsSettingsOpen, useFloatingPanel } = useContext(SettingsContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Ensure panel state syncs with useFloatingPanel setting
  useEffect(() => {
    if (!useFloatingPanel && isSettingsOpen) {
      setIsSettingsOpen(false);
    }
  }, [useFloatingPanel, isSettingsOpen, setIsSettingsOpen]);

  const handleClose = () => {
    setIsSettingsOpen(false);
  };

  // Don't render the modal at all if floating panel is disabled
  if (!useFloatingPanel) {
    return null;
  }

  return (
    <Modal
      open={isSettingsOpen}
      onClose={handleClose}
      aria-labelledby="settings-modal"
      aria-describedby="settings-panel"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 'calc(75vw + 100px)', // Increased width by 100px
          maxHeight: 'calc(65vh + 79px + 70px)', // Increased height by 79px + 70px
          bgcolor: 'background.paper',
          color: 'text.primary',
          boxShadow: 24,
          borderRadius: 2,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
      }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 10, right: 10 }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" id="settings-modal" sx={{ mb: 2 }}>
          Settings
        </Typography>

        <Box sx={{ 
          flexGrow: 1, 
          overflowY: 'auto', 
          minHeight: 'calc(60vh + 70px)', // Increased by 70px
          paddingBottom: 2 
        }}>
          <Settings isFloating={true} />
        </Box>
      </Box>
    </Modal>
  );
};

export default FloatingSettingsPanel;
