import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import TabView from '../shared/TabView';
import FunctionManager from './FunctionManager';
import RoleManager from './RoleManager';

const SuperAdminPanel = ({ apiStats, serverInfo }) => {
  // Inner TabView content for Users tab
  const innerTabLabels = ['Functions', 'Roles'];
  const innerTabContents = [
    // Functions tab content - Extremely minimal margins, full height
    <Box sx={{ 
      p: 0.5, 
      bgcolor: '#2a2a2a', 
      borderRadius: 1, 
      height: '100%',
      overflow: 'hidden' // Prevent overflow
    }}>
      <FunctionManager />
    </Box>,
    
    // Roles tab content
    <Box sx={{ p: 0.5, bgcolor: '#2a2a2a', borderRadius: 1, height: '100%', overflow: 'auto' }}>
      <RoleManager />
    </Box>
  ];

  // Main TabView content
  const mainTabLabels = ['Users', 'System', 'Logs'];
  const mainTabContents = [
    // Users tab with inner TabView - No padding, full height
    <Box sx={{ 
      height: '100%', 
      bgcolor: '#1e1e1e', 
      p: 0, 
      display: 'flex', 
      flexDirection: 'column' 
    }}>
      <TabView 
        tabLabels={innerTabLabels} 
        tabContents={innerTabContents}
        variant="small"
        smallTabColors={{
          active: '#4a4a4a',
          inactive: '#303030',
          activeHover: '#5a5a5a',
          inactiveHover: '#404040'
        }}
        sx={{ 
          flexGrow: 1, 
          height: '100%',
          '& .MuiBox-root': { height: '100%', display: 'flex', flexDirection: 'column' },
          '& .tab-content-container': { flexGrow: 1, overflow: 'hidden' }
        }}
      />
    </Box>,
    
    // Other main tabs with same height settings
    <Box sx={{ p: 0.5, bgcolor: '#1e1e1e', height: '100%', overflow: 'auto' }}>
      <Typography variant="subtitle2" sx={{ mb: 0.5, color: '#fff', fontSize: '0.9rem' }}>
        System Information
      </Typography>
      {serverInfo ? (
        <pre style={{ color: '#ddd', margin: 0, fontSize: '0.8rem' }}>{JSON.stringify(serverInfo, null, 2)}</pre>
      ) : (
        <Typography sx={{ color: '#aaa', fontSize: '0.8rem' }}>Loading...</Typography>
      )}
    </Box>,
    
    <Box sx={{ p: 0.5, bgcolor: '#1e1e1e', height: '100%', overflow: 'auto' }}>
      <Typography variant="subtitle2" sx={{ mb: 0.5, color: '#fff', fontSize: '0.9rem' }}>
        System Logs
      </Typography>
      {apiStats ? (
        <pre style={{ color: '#ddd', margin: 0, fontSize: '0.8rem' }}>{JSON.stringify(apiStats, null, 2)}</pre>
      ) : (
        <Typography sx={{ color: '#aaa', fontSize: '0.8rem' }}>Loading...</Typography>
      )}
    </Box>
  ];

  return (
    <Paper elevation={3} sx={{ 
      p: 0.5,
      bgcolor: '#1e1e1e', 
      color: '#fff',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden' // Prevent overflow
    }}>
      <Typography variant="h5" sx={{ mb: 0.5, fontSize: '1.2rem', pl: 0.5 }}>
        Super Admin Panel
      </Typography>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}> {/* Prevent overflow */}
        <TabView 
          tabLabels={mainTabLabels} 
          tabContents={mainTabContents}
          variant="standard"
          sx={{ 
            height: '100%',
            '& .MuiBox-root': { height: '100%' }, 
            '& .tab-content-container': { height: 'calc(100% - 40px)' } 
          }}
        />
      </Box>
    </Paper>
  );
};

export default SuperAdminPanel;
