import React, { useContext, useRef } from 'react';
import { IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { SettingsContext } from './SettingsContext';

const SidebarToggleButton = ({ isOpen, onToggle }) => {
  const { useSmallSidebar } = useContext(SettingsContext);
  const sidebarWidth = useSmallSidebar ? 120 : 180;
  const lastClosedRef = useRef(false);

  const handleMouseEnter = () => {
    if (!isOpen && !lastClosedRef.current) {
      onToggle();
    }
  };

  const handleClick = () => {
    onToggle();
    lastClosedRef.current = true;
    setTimeout(() => {
      lastClosedRef.current = false;
    }, 500); // Gir en kort forsinkelse for å unngå blinking
  };

  return (
    <IconButton
      onMouseEnter={handleMouseEnter} // Åpner kun hvis den ikke allerede er åpen
      onClick={handleClick} // Klikk for å lukke
      className="sidebar-toggle-button"
      sx={{
        position: 'fixed',
        top: '300px',
        left: isOpen ? `${sidebarWidth}px` : '0px',
        transition: 'left 0.3s ease-in-out',
        backgroundColor: '#333',
        color: '#fff',
        borderRadius: '0 5px 5px 0',
        width: '40px',
        height: '40px',
        boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
        '&:hover': {
          backgroundColor: '#444',
        },
        zIndex: 1300,
      }}
    >
      {isOpen ? <ChevronLeft /> : <ChevronRight />}
    </IconButton>
  );
};

export default SidebarToggleButton;
