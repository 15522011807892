// DigitalWatch.js
import React, { useState, useEffect } from 'react';

const DigitalWatch = () => {
	const [time, setTime] = useState(new Date());

	useEffect(() => {
		const timerID = setInterval(() => tick(), 1000);
		return () => clearInterval(timerID);
	}, []);

	const tick = () => {
		setTime(new Date());
	};

	return (
		<div style={styles.container}>
			<div style={styles.time}>{time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</div>
			<div style={styles.date}>{time.toLocaleDateString([], { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</div>
		</div>
	);
};

const styles = {
	container: {
		backgroundColor: '#292929', // Changed from #3a3a3a
		padding: '20px',
		borderRadius: '8px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: '#f5f5f5', // Lys tekst for god kontrast
		fontFamily: 'Arial, sans-serif',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
		width: '220px',
		height: '130px',
		justifyContent: 'center',
	},
	time: {
		fontSize: '2em',
		fontWeight: 'bold'
	},
	date: {
		fontSize: '1em',
		marginTop: '5px'
	}
};

export default DigitalWatch;
