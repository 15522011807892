import React, { createContext, useState, useEffect } from 'react';

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const [serverIp, setServerIp] = useState(localStorage.getItem('serverIp') || '');
  
  // Fix the useFloatingPanel initialization to properly parse the stored value
  const [useFloatingPanel, setUseFloatingPanel] = useState(() => {
    const storedValue = localStorage.getItem('useFloatingPanel');
    // Check if the value exists and parse it, default to true if it doesn't exist
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });
  
  const [isSettingsOpen, setIsSettingsOpen] = useState(false); // Styrer visningen av popupen
  const [autoHideSidebar, setAutoHideSidebar] = useState(() => {
    const storedValue = localStorage.getItem('autoHideSidebar');
    return storedValue === 'true' || false;
  });

  // Endre initialverdien for isSidebarOpen basert på autoHideSidebar
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    const isAutoHide = localStorage.getItem('autoHideSidebar') === 'true';
    return isAutoHide ? false : true; // Start lukket hvis autoHideSidebar er true
  });

  // Ny state for useSmallSidebar hentet fra localStorage
  const [useSmallSidebar, setUseSmallSidebar] = useState(() => {
    // Make sure we're getting the accurate localStorage value
    const storedValue = localStorage.getItem('useSmallSidebar');
    console.log("Initial useSmallSidebar from localStorage:", storedValue);
    return storedValue === 'true';
  });

  useEffect(() => {
    localStorage.setItem('serverIp', serverIp);
  }, [serverIp]);

  // Ensure useFloatingPanel is properly stored in localStorage
  useEffect(() => {
    localStorage.setItem('useFloatingPanel', JSON.stringify(useFloatingPanel));
  }, [useFloatingPanel]);

  useEffect(() => {
    localStorage.setItem('autoHideSidebar', autoHideSidebar.toString());
    if (autoHideSidebar) {
      setIsSidebarOpen(false);
    }
  }, [autoHideSidebar]);

  useEffect(() => {
    localStorage.setItem('useSmallSidebar', useSmallSidebar.toString());
  }, [useSmallSidebar]);

  // Add an effect to monitor localStorage changes for useSmallSidebar
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'useSmallSidebar') {
        console.log("useSmallSidebar changed in localStorage:", e.newValue);
        setUseSmallSidebar(e.newValue === 'true');
      }
    };
    
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <SettingsContext.Provider value={{ 
      serverIp, setServerIp, 
      useFloatingPanel, setUseFloatingPanel, 
      isSettingsOpen, setIsSettingsOpen,
      isSidebarOpen, setIsSidebarOpen,
      autoHideSidebar, setAutoHideSidebar,
      useSmallSidebar, setUseSmallSidebar  // Eksponer useSmallSidebar
    }}>
      {children}
    </SettingsContext.Provider>
  );
};
