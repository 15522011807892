// src/components/_social/Chat.js
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import { useChat } from "./ChatContext";
import { FaThumbtack } from "react-icons/fa";

const CHAT_WIDTH = 400;
const STATUS_BAR_HEIGHT = 40;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.isInSocial ? '100%' : `${CHAT_WIDTH}px`};
  height: ${props => props.isInSocial ? '100%' : `calc(100vh - ${STATUS_BAR_HEIGHT}px)`};
  background: ${props => props.isInSocial ? 'transparent' : '#2d2f33'};
  color: white;
  box-shadow: ${props => props.isInSocial ? 'none' : '-2px 0 10px rgba(0, 0, 0, 0.3)'};
  padding: 1rem;
  box-sizing: border-box;
  position: ${props => props.isInSocial ? 'relative' : 'fixed'};
  bottom: ${props => props.isInSocial ? '0' : `${STATUS_BAR_HEIGHT-6}px`};
  right: 0;
  z-index: ${props => props.isInSocial ? '1' : '1000'};
  transition: ${props => props.isInSocial ? 'none' : 'right 0.3s ease-in-out'};
`;

const LockButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  color: ${(props) => (props.isChatLocked ? "#ff4747" : "#4a9bfc")};
  cursor: pointer;
  font-size: 14px;
  padding: 4px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${(props) => (props.isChatLocked ? "#e33a3a" : "#3580e3")};
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
  background: ${props => props.isInSocial ? '#1c1e22' : '#1c1e22'};
  border-radius: ${props => props.isInSocial ? '0 8px 8px 0' : '8px'};
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.isInSocial ? '0' : '2rem'};
`;

const InputContainer = styled.div`
  display: flex;
  border-top: 1px solid #444;
  padding: 10px;
  background: #2d2f33;
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  background: #1e2125;
  color: white;
  outline: none;
  margin-right: 0.5rem;
`;

const SendButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background: #4a9bfc;
  color: white;
  cursor: pointer;

  &:disabled {
    background: #555;
    cursor: not-allowed;
  }
`;

const Chat = ({ isInSocial }) => {
  const { isChatOpen, setIsChatOpen, isChatLocked, lockChat } = useChat();
  const chatRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState(null);

  const apiUrl = process.env.REACT_APP_STATUS_API_URL;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chatRef.current && !chatRef.current.contains(event.target) && !isChatLocked) {
        setIsChatOpen(false);
      }
    };

    if (isChatOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isChatOpen, isChatLocked]);

  const fetchMessages = async () => {
    const token = Cookies.get("token");
    if (!token) return;

    try {
      const response = await fetch(`${apiUrl}/chat/get_messages`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify({ 
          limit: 100,
          group_id: currentGroupId  // Use the current group ID or null for the default global chat
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setMessages((data.messages || []).reverse()); // Fix order
        
        // Update current group ID if returned
        if (data.group_id) {
          setCurrentGroupId(data.group_id);
        }
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    if (isChatOpen) {
      fetchMessages();
      const interval = setInterval(fetchMessages, 5000);
      return () => clearInterval(interval);
    }
  }, [isChatOpen]);

  const sendMessage = async () => {
    if (!input.trim()) return;
    setIsSending(true);

    const token = Cookies.get("token");
    if (!token) {
      console.error("User is not authenticated");
      setIsSending(false);
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/chat/send_message`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify({ 
          message: input.trim(),
          group_id: currentGroupId  // Send to current group or use default
        }),
      });

      if (response.ok) {
        setInput("");
        fetchMessages();
        scrollToBottom(); // 🎯 Scroller automatisk til bunnen
      } else {
        console.error("Error sending message:", response.statusText);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsSending(false);
    }
  };

  // 🎯 Autoscroll til bunnen av chatten
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // 🎯 Scroller til bunnen når meldinger oppdateres
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <ChatContainer ref={chatRef} isInSocial={isInSocial}>
      {/* Only show lock button if not in social route */}
      {!isInSocial && (
        <LockButton onClick={lockChat} isChatLocked={isChatLocked}>
          <FaThumbtack />
        </LockButton>
      )}

      <MessagesContainer isInSocial={isInSocial}>
        {messages.map((msg, index) => (
          <div key={index}>
            <strong>{msg.username}:</strong> {msg.message}
            {msg.is_edited && <small> (edited)</small>}
          </div>
        ))}
        <div ref={messagesEndRef} /> {/* 🎯 Autoscroll til bunnen */}
      </MessagesContainer>

      <InputContainer>
        <ChatInput
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
          onKeyPress={(e) => e.key === "Enter" && sendMessage()}
        />
        <SendButton onClick={sendMessage} disabled={isSending}>
          {isSending ? "..." : "Send"}
        </SendButton>
      </InputContainer>
    </ChatContainer>
  );
};

export default Chat;
