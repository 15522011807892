// src/App.js
import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import Activities from "./components/Activities";
import Stock from "./components/Stock";
import Shipping from "./components/Shipping";
import Login from "./components/Login";
import Settings from "./components/Settings";
import WorkerManager from "./components/WorkerManager";
import Cookies from "js-cookie";
import { CssBaseline, Box, ThemeProvider } from "@mui/material";
import "antd/dist/reset.css";
import { darkTheme } from "./themes/themes";
import { AuthProvider } from "./components/AuthContext";
import StatusBar from "./components/StatusBar";
import ProtectedRoute from "./components/ProtectedRoute";
import ErrorBoundary from "./components/ErrorBoundary";
import { SettingsContext, SettingsProvider } from "./components/SettingsContext";
import FloatingSettingsPanel from "./components/FloatingSettingsPanel";
import ChatPanel from "./components/_social/ChatPanel";
import { ChatProvider, useChat } from "./components/_social/ChatContext";
import { ActivityProvider } from "./components/_activities/ActivityContext";
import SidebarToggleButton from './components/SidebarToggleButton';
import SuperAdmin from "./components/_admin/SuperAdmin";
import RepairCostChecker from "./components/RepairCostChecker";
import PriceAdminPanel from "./components/_admin/PriceAdminPanel";
import useRoleSpecificSettings from "./hooks/useRoleSpecificSettings";
import Social from "./components/Social";

const themeToUse = darkTheme;

function App() {
  return (
    <ThemeProvider theme={themeToUse}>
      <CssBaseline />
      <SettingsProvider>
        <AuthProvider>
          <ChatProvider>
            <ActivityProvider>
              <RoleSettingsHandler />
              <Router>
                <ErrorBoundary>
                  <MainLayout />
                </ErrorBoundary>
              </Router>
            </ActivityProvider>
          </ChatProvider>
        </AuthProvider>
      </SettingsProvider>
    </ThemeProvider>
  );
}

// Component to apply role-specific settings
const RoleSettingsHandler = () => {
  useRoleSpecificSettings();
  return null;
};

const MainLayout = () => {
  const { isChatOpen, isChatLocked } = useChat();
  const { autoHideSidebar, isSidebarOpen, setIsSidebarOpen } = useContext(SettingsContext);
  const location = useLocation();
  // Oppdater betingelsen til å ekskludere både login og social
  const showChatPanel = location.pathname !== "/login" && location.pathname !== "/social";

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  
  // Skift innholdet når chatten er åpen ELLER låst
  const marginRight = location.pathname === "/login"
    ? "0"
    : (isChatLocked ? "400px" : "0");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", position: "relative", overflowX: "hidden" }}>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        {autoHideSidebar ? (isSidebarOpen && <Sidebar />) : <Sidebar />}
        {autoHideSidebar && <SidebarToggleButton isOpen={isSidebarOpen} onToggle={toggleSidebar} />}
        <FloatingSettingsPanel />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            color: "text.primary",
            p: 3,
            marginRight: marginRight,
            transition: theme => theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.standard,
            }),
            width: `calc(100% - ${marginRight})`,
            overflow: 'auto',
            position: 'relative',
          }}
        >
          <Routes>
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute allowedRoles={['admin', 'superadmin']}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/activities"
              element={
                <ProtectedRoute allowedRoles={['admin', 'superadmin']}>
                  <Activities />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stock"
              element={
                <ProtectedRoute allowedRoles={['admin', 'superadmin']}>
                  <Stock />
                </ProtectedRoute>
              }
            />
            <Route
              path="/shipping"
              element={
                <ProtectedRoute allowedRoles={['admin', 'superadmin']}>
                  <Shipping />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute allowedRoles={['admin', 'superadmin']}>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/workers"
              element={
                <ProtectedRoute allowedRoles={['admin', 'superadmin', 'workers']}>
                  <WorkerManager />
                </ProtectedRoute>
              }
            />
            <Route
              path="/superadmin"
              element={
                <ProtectedRoute allowedRoles={['superadmin']}>
                  <SuperAdmin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/repair-cost"
              element={
                <ProtectedRoute allowedRoles={['admin', 'superadmin', 'repair-cost', 'price-admin']}>
                  <RepairCostChecker />
                </ProtectedRoute>
              }
            />
            <Route
              path="/price-admin"
              element={
                <ProtectedRoute allowedRoles={['price-admin', 'superadmin']}>
                  <PriceAdminPanel />
                </ProtectedRoute>
              }
            />
            <Route
              path="/social"
              element={
                <ProtectedRoute allowedRoles={['admin', 'superadmin']}>
                  <Social />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Box>
      </Box>

      <StatusBar />
      {showChatPanel && <ChatPanel />}
    </Box>
  );
};

export default App;
