import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grow,
  CircularProgress
} from '@mui/material';
import debounce from 'lodash/debounce';
import Cookies from 'js-cookie';

// Simplified helper function to get API URL from environment variable
const getApiUrl = () => {
  return process.env.REACT_APP_STATUS_API_URL || 'http://localhost:5000';
};

// Legg til en funksjon som henter oppdatert token og returnerer headers
const getHeaders = () => {
  const cookieValue = Cookies.get('token');
  const token = cookieValue ? cookieValue.split(';')[0] : null;
  return {
    'Content-Type': 'application/json',
    ...(token && { 'Authorization': `Bearer ${token}` })
  };
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    padding: '20px',
  },
  searchContainer: {
    width: '100%',
    maxWidth: '600px',
    marginTop: '20px',
  },
  searchField: {
    width: '100%',
  },
  resultsList: {
    width: '100%',
    maxWidth: '600px',
    maxHeight: '300px',
    overflowY: 'auto',
    marginTop: '10px',
    backgroundColor: 'background.paper',
  },
  selectedRepair: {
    width: '100%',
    maxWidth: '600px',
    marginTop: '20px',
    padding: '20px',
  },
  priceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  }
};

const RepairCostChecker = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [repairOptions, setRepairOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const resultRef = useRef(null);
  const containerRef = useRef(null);
  const textFieldRef = useRef(null);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (term) => {
      if (term.length < 2) {
        setSearchResults([]);
        return;
      }
      setLoading(true);
      const apiUrl = getApiUrl();
      const fetchUrl = `${apiUrl}/price/search`;
      // Hent oppdaterte headers med token
      const headers = getHeaders();
      
      console.log("DEBUG: Fetching search results from:", fetchUrl);
      console.log("DEBUG: Headers being sent:", headers);
      try {
        const response = await fetch(fetchUrl, { 
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ q: term })
        });
        if (!response.ok) {
          throw new Error(`Search failed with status: ${response.status}`);
        }
        const data = await response.json();
        
        // Filtrer unike modeller basert på brand + model
        const uniqueModels = Array.from(
          new Map(data.map(item => [`${item.brand} ${item.model}`, item])).values()
        );

        setSearchResults(uniqueModels);
      } catch (error) {
        console.error('Search error:', error);
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setSelectedModel(null);
    setRepairOptions({});
    debouncedSearch(value);
  };

  const handleSelectModel = async (model) => {
    setSelectedModel(model);
    setSearchResults([]);
    setSearchTerm(model.model);

    setLoading(true);
    const apiUrl = getApiUrl();
    const fetchUrl = `${apiUrl}/price/model`;
    // Hent oppdaterte headers med token
    const headers = getHeaders();
    
    console.log("DEBUG: Fetching repair options from:", fetchUrl);
    console.log("DEBUG: Headers being sent:", headers);

    try {
      const response = await fetch(fetchUrl, { 
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ model: model.model })
      });
      if (!response.ok) {
        throw new Error(`Fetching repair options failed: ${response.status}`);
      }
      const data = await response.json();
      
      console.log("DEBUG: Reparasjonsdata mottatt:", data);

      // Filtrer unike reparasjonstyper og grupper priser
      const repairMap = {};
      data.forEach(repair => {
        if (!repairMap[repair.repair_type]) {
          repairMap[repair.repair_type] = [];
        }
        repairMap[repair.repair_type].push({
          storage: repair.storage || 'Standard',
          price_nok: repair.price_nok
        });
      });

      setRepairOptions(repairMap);
    } catch (error) {
      console.error('Fetch repair options error:', error);
      setRepairOptions({});
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      setSearchTerm('');
      setSearchResults([]);
      setSelectedModel(null);
      return;
    }
    if (!searchResults.length) return;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightIndex((prev) => (prev + 1) % searchResults.length);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightIndex((prev) =>
        prev - 1 < 0 ? searchResults.length - 1 : prev - 1
      );
    } else if (e.key === 'Enter' && highlightIndex >= 0) {
      e.preventDefault();
      handleSelectModel(searchResults[highlightIndex]);
    }
  };

  const handleContainerClick = (event) => {
    // Focus the search field only if you clicked directly on the container
    if (event.target === containerRef.current && !searchResults.length) {
      textFieldRef.current?.focus();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setSearchTerm('');
        setSearchResults([]);
        setSelectedModel(null);
        textFieldRef.current?.focus(); // Focus search field when clicking outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, setSearchTerm, setSearchResults, setSelectedModel]);

  useEffect(() => {
    const handlePageClick = (event) => {
      if (!containerRef.current?.contains(event.target) && !searchResults.length) {
        textFieldRef.current?.focus();
      }
    };
    document.addEventListener('click', handlePageClick);
    return () => {
      document.removeEventListener('click', handlePageClick);
    };
  }, [containerRef, searchResults]);

  return (
    <Box sx={styles.container}>
      <Typography variant="h4" component="h1" gutterBottom>
        Check repair cost
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom color="text.secondary">
        What Apple product do you want to check?
      </Typography>

      <Box
        ref={containerRef}
        sx={styles.searchContainer}
        onClick={handleContainerClick}
      >
        <TextField
          sx={styles.searchField}
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          placeholder="Start typing to search..."
          inputRef={textFieldRef}
          InputProps={{
            endAdornment: loading && <CircularProgress size={20} />,
          }}
        />

        {searchResults.length > 0 && (
          <Grow in={true} timeout={500}>
            <Paper ref={resultRef} sx={styles.resultsList}>
              <List>
                {searchResults.map((result, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => handleSelectModel(result)}
                    sx={{
                      backgroundColor:
                        index === highlightIndex ? 'rgba(255,255,255,0.2)' : 'transparent'
                    }}
                  >
                    <ListItemText
                      primary={`${result.brand} ${result.model}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grow>
        )}

        {selectedModel && (
          <Grow in={true} timeout={500}>
            <Paper sx={styles.selectedRepair}>
              <Typography variant="h6" gutterBottom>
                {`${selectedModel.brand} ${selectedModel.model}`}
              </Typography>

              {Object.keys(repairOptions).length > 0 ? (
                Object.entries(repairOptions).map(([repairType, prices], index) => (
                  <Box key={index} sx={{ marginBottom: '10px' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {repairType}:
                    </Typography>
                    {Array.isArray(prices) && prices.length > 0 ? (
                      prices.map((price, i) => (
                        <Box key={i} sx={styles.priceItem}>
                          <Typography>{price.storage}:</Typography>
                          <Typography>{price.price_nok} NOK</Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography variant="body2" color="error">
                        No price data available.
                      </Typography>
                    )}
                  </Box>
                ))
              ) : (
                <Typography variant="body1" color="error">
                  No repair prices found for this model.
                </Typography>
              )}
            </Paper>
          </Grow>
        )}
      </Box>
    </Box>
  );
};

export default RepairCostChecker;
