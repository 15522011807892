import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useChat } from './ChatContext';
import { FaUsers, FaUserFriends, FaPlus, FaCircle } from 'react-icons/fa';
import Cookies from 'js-cookie';

const Container = styled.div`
  background: #2d2f33;
  border-radius: 8px;
  padding: 16px;
  width: 300px;
  height: 100%;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #404040;
`;

const ChatList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChatItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: ${props => props.isActive ? '#404040' : '#363636'};
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #404040;
  }
`;

const ChatName = styled.span`
  color: #ffffff;
  margin-left: 12px;
  flex: 1;
`;

const UnreadBadge = styled.span`
  background: #4a9bfc;
  color: white;
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 12px;
  min-width: 20px;
  text-align: center;
`;

const AddButton = styled.button`
  background: none;
  border: none;
  color: #4a9bfc;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #3580e3;
  }
`;

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;

const UserItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: #363636;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #404040;
    transform: translateX(4px);
  }
`;

const OnlineStatus = styled.div`
  color: ${props => props.isOnline ? '#4caf50' : '#666'};
  margin-right: 8px;
  font-size: 10px;
`;

const UserName = styled.span`
  color: #ffffff;
  flex: 1;
`;

const ChatSelector = () => {
  const { 
    activeChat, 
    chatGroups, 
    privateChats, 
    unreadMessages, 
    switchChat,
    startPrivateChat 
  } = useChat();
  const [onlineUsers, setOnlineUsers] = useState([]);

  const fetchOnlineUsers = async () => {
    try {
      const token = Cookies.get("token");
      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/chat/online-users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      if (response.ok) {
        const data = await response.json();
        setOnlineUsers(data.users);
      }
    } catch (error) {
      console.error("Error fetching online users:", error);
    }
  };

  useEffect(() => {
    fetchOnlineUsers();
    const interval = setInterval(fetchOnlineUsers, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleUserClick = async (username) => {
    const existingChat = privateChats.find(chat => 
      chat.participants.includes(username)
    );

    if (existingChat) {
      switchChat(existingChat);
    } else {
      const newChat = await startPrivateChat(username);
      if (newChat) {
        switchChat(newChat);
      }
    }
  };

  const handleStartNewChat = () => {
    console.log("Start new chat clicked");
  };

  const handleJoinNewGroup = () => {
    console.log("Join new group clicked");
  };

  return (
    <Container>
      <Section>
        <SectionHeader>
          <span>Chat Groups</span>
          <AddButton onClick={handleJoinNewGroup} title="Join new group">
            <FaPlus />
          </AddButton>
        </SectionHeader>
        <ChatList>
          {chatGroups.map(group => (
            <ChatItem 
              key={group.id}
              isActive={activeChat?.id === group.id}
              onClick={() => switchChat(group)}
            >
              <FaUsers />
              <ChatName>{group.name}</ChatName>
              {unreadMessages[group.id] > 0 && (
                <UnreadBadge>{unreadMessages[group.id]}</UnreadBadge>
              )}
            </ChatItem>
          ))}
        </ChatList>
      </Section>

      <Section>
        <SectionHeader>
          <span>Online Users</span>
        </SectionHeader>
        <UserList>
          {onlineUsers.map(user => (
            <UserItem 
              key={user.username}
              onClick={() => handleUserClick(user.username)}
            >
              <OnlineStatus isOnline={true}>
                <FaCircle />
              </OnlineStatus>
              <UserName>{user.username}</UserName>
            </UserItem>
          ))}
        </UserList>
      </Section>

      <Section>
        <SectionHeader>
          <span>Private Chats</span>
          <AddButton onClick={handleStartNewChat} title="Start new chat">
            <FaPlus />
          </AddButton>
        </SectionHeader>
        <ChatList>
          {privateChats.map(chat => (
            <ChatItem 
              key={chat.id}
              isActive={activeChat?.id === chat.id}
              onClick={() => switchChat(chat)}
            >
              <FaUserFriends />
              <ChatName>{chat.name}</ChatName>
              {unreadMessages[chat.id] > 0 && (
                <UnreadBadge>{unreadMessages[chat.id]}</UnreadBadge>
              )}
            </ChatItem>
          ))}
        </ChatList>
      </Section>
    </Container>
  );
};

export default ChatSelector;
