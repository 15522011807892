import React, { createContext, useContext, useState, useCallback } from "react";
import Cookies from "js-cookie";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isChatLocked, setIsChatLocked] = useState(false);
  const [activeChat, setActiveChat] = useState(null); // { id, type, name }
  const [chatGroups, setChatGroups] = useState([]); // Liste over tilgjengelige chat-grupper
  const [privateChats, setPrivateChats] = useState([]); // Liste over private samtaler
  const [unreadMessages, setUnreadMessages] = useState({}); // { chatId: count }

  const apiUrl = process.env.REACT_APP_STATUS_API_URL;

  // Hent chat-grupper fra backend
  const fetchChatGroups = useCallback(async () => {
    const token = Cookies.get("token");
    if (!token) return;

    try {
      const response = await fetch(`${apiUrl}/chat/groups`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setChatGroups(data.groups);
      }
    } catch (error) {
      console.error("Error fetching chat groups:", error);
    }
  }, [apiUrl]);

  // Hent private samtaler
  const fetchPrivateChats = useCallback(async () => {
    const token = Cookies.get("token");
    if (!token) return;

    try {
      const response = await fetch(`${apiUrl}/chat/private-chats`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPrivateChats(data.chats);
      }
    } catch (error) {
      console.error("Error fetching private chats:", error);
    }
  }, [apiUrl]);

  // Start ny privat chat
  const startPrivateChat = async (username) => {
    const token = Cookies.get("token");
    if (!token) return;

    try {
      const response = await fetch(`${apiUrl}/chat/start-private`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ username }),
      });

      if (response.ok) {
        const data = await response.json();
        setPrivateChats(prev => [...prev, data.chat]);
        return data.chat;
      }
    } catch (error) {
      console.error("Error starting private chat:", error);
    }
  };

  // Bytt aktiv chat
  const switchChat = (chatInfo) => {
    setActiveChat(chatInfo);
    setIsChatOpen(true);
    // Nullstill uleste meldinger for denne chatten
    setUnreadMessages(prev => ({ ...prev, [chatInfo.id]: 0 }));
  };

  // Håndter nye meldinger når chatten ikke er aktiv
  const handleNewMessage = (chatId) => {
    if (activeChat?.id !== chatId) {
      setUnreadMessages(prev => ({
        ...prev,
        [chatId]: (prev[chatId] || 0) + 1
      }));
    }
  };

  const toggleChat = () => {
    if (isChatLocked) {
      setIsChatLocked(false);
      setIsChatOpen(false);
    } else {
      setIsChatOpen(prev => !prev);
    }
  };

  const lockChat = () => {
    setIsChatLocked(prev => !prev);
  };

  const leaveGroup = async (groupId) => {
    const token = Cookies.get("token");
    if (!token) return;

    try {
      const response = await fetch(`${apiUrl}/chat/leave-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ group_id: groupId }),
      });

      if (response.ok) {
        // Oppdater chat-grupper listen
        fetchChatGroups();
        // Hvis aktiv chat var denne gruppen, nullstill
        if (activeChat?.id === groupId) {
          setActiveChat(null);
        }
        return true;
      }
    } catch (error) {
      console.error("Error leaving group:", error);
      return false;
    }
  };

  const deletePrivateChat = async (groupId) => {
    const token = Cookies.get("token");
    if (!token) return;

    try {
      const response = await fetch(`${apiUrl}/chat/delete-private-chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ group_id: groupId }),
      });

      if (response.ok) {
        // Oppdater private chats listen
        fetchPrivateChats();
        // Hvis aktiv chat var denne, nullstill
        if (activeChat?.id === groupId) {
          setActiveChat(null);
        }
        return true;
      }
    } catch (error) {
      console.error("Error deleting private chat:", error);
      return false;
    }
  };

  // Last inn chat-data ved oppstart
  React.useEffect(() => {
    fetchChatGroups();
    fetchPrivateChats();
  }, [fetchChatGroups, fetchPrivateChats]);

  return (
    <ChatContext.Provider
      value={{
        isChatOpen,
        setIsChatOpen,
        isChatLocked,
        toggleChat,
        lockChat,
        activeChat,
        chatGroups,
        privateChats,
        unreadMessages,
        switchChat,
        startPrivateChat,
        handleNewMessage,
        fetchChatGroups,
        fetchPrivateChats,
        leaveGroup,
        deletePrivateChat,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
