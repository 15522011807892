import React, { useState, useEffect } from "react";
import { Card, Typography, Box, Chip, Stack } from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const UserInfoWidget = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    const formatDate = (dateString) => {
        if (!dateString) return "N/A";
        return new Date(dateString).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const fetchUserData = async () => {
        try {
            const token = Cookies.get("token");
            if (!token) throw new Error("No token found");

            const decodedToken = jwtDecode(token);
            const username = decodedToken.sub;

            const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/user?username=${username}&token=${token}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            });

            if (!response.ok) throw new Error("Failed to fetch user data");

            const result = await response.json();
            setUserData(result);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setUserData(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    if (loading) {
        return (
            <Card sx={{ 
                width: 300, 
                height: '330px',
                padding: 2, 
                backgroundColor: "#1e1e1e", 
                color: "#fff",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography variant="h6" align="center">User Info</Typography>
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>Loading...</Typography>
            </Card>
        );
    }

    if (!userData) {
        return (
            <Card sx={{ 
                width: 300, 
                height: '330px',
                padding: 2, 
                backgroundColor: "#1e1e1e", 
                color: "#fff",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography variant="h6" align="center">User Info</Typography>
                <Typography variant="body1" align="center" sx={{ mt: 2 }}>No data available</Typography>
            </Card>
        );
    }

    return (
        <Card sx={{ 
            width: 300, 
            height: '330px',
            padding: 2, 
            backgroundColor: "#1e1e1e", 
            color: "#fff",
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Stack spacing={2} sx={{ height: '100%', justifyContent: 'space-between' }}>
                <Typography variant="h6" align="center">User Info</Typography>
                
                <Box>
                    <Typography variant="h5" align="center" gutterBottom>
                        {`${userData.fornavn} ${userData.etternavn}`.toUpperCase()}
                    </Typography>
                    <Typography variant="body2" align="center" color="gray">
                        {userData.username} ({userData.role})
                    </Typography>
                </Box>

                <Box>
                    <Typography variant="body2" color="gray">Brand Access:</Typography>
                    <Stack direction="row" spacing={1} mt={0.5} flexWrap="wrap" justifyContent="center">
                        {userData.brands?.map((brand) => (
                            <Chip 
                                key={brand}
                                label={brand.toUpperCase()}
                                size="small"
                                sx={{ backgroundColor: "#555", height: '20px', m: 0.5 }}
                            />
                        ))}
                    </Stack>
                </Box>

                <Box>
                    <Typography variant="caption" display="block" color="gray">
                        Account created: {formatDate(userData.created_at)}
                    </Typography>
                    {userData.account_locked && (
                        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                            Account locked
                        </Typography>
                    )}
                </Box>
            </Stack>
        </Card>
    );
};

export default UserInfoWidget;
