import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Alert,
  CircularProgress,
  Pagination,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Upload as UploadIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import axios from 'axios';

const PriceAdminPanel = () => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
  const [uploadModal, setUploadModal] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [formData, setFormData] = useState({
    brand: '',
    repair_type: '',
    model: '',
    storage: '',
    price_nok: '',
    price_sek: '',
    price_dkk: '',
    price_eur: ''
  });

  // Fetch prices with pagination
  const fetchPrices = async (page = 1) => {
    setLoading(true);
    setError(null);
    
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/admin/prices?page=${page}&per_page=20`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      setPrices(response.data.prices);
      setTotalPages(Math.ceil(response.data.total / response.data.per_page));
      setCurrentPage(response.data.page);
    } catch (err) {
      setError('Failed to fetch prices. Please try again later.');
      console.error('Error fetching prices:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  // Handle page change
  const handlePageChange = (event, page) => {
    fetchPrices(page);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Open modal for adding new price
  const handleAddPrice = () => {
    setFormData({
      brand: '',
      repair_type: '',
      model: '',
      storage: '',
      price_nok: '',
      price_sek: '',
      price_dkk: '',
      price_eur: ''
    });
    setModalMode('add');
    setShowModal(true);
  };

  // Open modal for editing price
  const handleEditPrice = (price) => {
    setSelectedPrice(price);
    setFormData({
      brand: price.brand,
      repair_type: price.repair_type,
      model: price.model,
      storage: price.storage,
      price_nok: price.price_nok,
      price_sek: price.price_sek,
      price_dkk: price.price_dkk,
      price_eur: price.price_eur
    });
    setModalMode('edit');
    setShowModal(true);
  };

  // Submit form - add or edit price
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const token = localStorage.getItem('token');
      
      if (modalMode === 'add') {
        await axios.post('/api/admin/prices', formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
      } else {
        await axios.put(`/api/admin/prices/${selectedPrice.id}`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
      }
      
      setShowModal(false);
      fetchPrices(currentPage); // Refresh prices
    } catch (err) {
      setError(`Failed to ${modalMode} price: ${err.response?.data?.error || err.message}`);
      console.error(`Error ${modalMode}ing price:`, err);
    } finally {
      setLoading(false);
    }
  };

  // Delete price
  const handleDeletePrice = async (priceId) => {
    if (!window.confirm('Are you sure you want to delete this price?')) {
      return;
    }
    
    setLoading(true);
    
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/admin/prices/${priceId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      // Refresh prices
      fetchPrices(currentPage);
    } catch (err) {
      setError('Failed to delete price. Please try again later.');
      console.error('Error deleting price:', err);
    } finally {
      setLoading(false);
    }
  };

  // Handle file selection for upload
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle Excel file upload
  const handleUpload = async (e) => {
    e.preventDefault();
    
    if (!file) {
      setUploadStatus({ severity: 'error', message: 'Please select a file to upload' });
      return;
    }
    
    if (!file.name.endsWith('.xlsx')) {
      setUploadStatus({ severity: 'error', message: 'File must be an Excel (.xlsx) file' });
      return;
    }
    
    setLoading(true);
    setUploadStatus(null);
    
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('file', file);
      
      await axios.post('/api/admin/prices/upload', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      
      setUploadStatus({ severity: 'success', message: 'Prices imported successfully' });
      setFile(null);
      document.getElementById('fileUpload').value = '';
      
      // Refresh prices after upload
      fetchPrices(1); // Return to first page
    } catch (err) {
      setUploadStatus({
        severity: 'error',
        message: `Failed to upload prices: ${err.response?.data?.error || err.message}`
      });
      console.error('Error uploading prices:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="price-admin-panel">
      <Typography variant="h4" component="h2" gutterBottom>
        Price Administration
      </Typography>

      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          onClick={handleAddPrice}
        >
          Add New Price
        </Button>
        <Button 
          variant="contained" 
          color="success" 
          startIcon={<UploadIcon />}
          onClick={() => setUploadModal(true)}
        >
          Import Prices
        </Button>
      </Box>

      {loading && prices.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ mb: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="price table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Brand</TableCell>
                  <TableCell>Model</TableCell>
                  <TableCell>Storage</TableCell>
                  <TableCell>Repair Type</TableCell>
                  <TableCell>NOK</TableCell>
                  <TableCell>SEK</TableCell>
                  <TableCell>DKK</TableCell>
                  <TableCell>EUR</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prices.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      No prices found
                    </TableCell>
                  </TableRow>
                ) : (
                  prices.map((price) => (
                    <TableRow key={price.id}>
                      <TableCell>{price.id}</TableCell>
                      <TableCell>{price.brand}</TableCell>
                      <TableCell>{price.model}</TableCell>
                      <TableCell>{price.storage}</TableCell>
                      <TableCell>{price.repair_type}</TableCell>
                      <TableCell>{price.price_nok}</TableCell>
                      <TableCell>{price.price_sek}</TableCell>
                      <TableCell>{price.price_dkk}</TableCell>
                      <TableCell>{price.price_eur}</TableCell>
                      <TableCell>
                        <IconButton 
                          color="primary" 
                          size="small" 
                          onClick={() => handleEditPrice(price)}
                          sx={{ mr: 1 }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton 
                          color="error" 
                          size="small" 
                          onClick={() => handleDeletePrice(price.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Pagination 
              count={totalPages} 
              page={currentPage} 
              onChange={handlePageChange} 
              color="primary" 
              showFirstButton 
              showLastButton
            />
          </Box>
        </>
      )}

      {/* Add/Edit Price Dialog */}
      <Dialog open={showModal} onClose={() => setShowModal(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {modalMode === 'add' ? 'Add New Price' : 'Edit Price'}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Brand"
                  name="brand"
                  value={formData.brand}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Model"
                  name="model"
                  value={formData.model}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Repair Type"
                  name="repair_type"
                  value={formData.repair_type}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Storage"
                  name="storage"
                  value={formData.storage}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Price NOK"
                  name="price_nok"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">NOK</InputAdornment>,
                  }}
                  value={formData.price_nok}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Price SEK"
                  name="price_sek"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">SEK</InputAdornment>,
                  }}
                  value={formData.price_sek}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Price DKK"
                  name="price_dkk"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">DKK</InputAdornment>,
                  }}
                  value={formData.price_dkk}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Price EUR"
                  name="price_eur"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                  value={formData.price_eur}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="inherit">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading ? 'Saving...' : modalMode === 'add' ? 'Add Price' : 'Update Price'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Upload Excel Dialog */}
      <Dialog open={uploadModal} onClose={() => setUploadModal(false)}>
        <DialogTitle>Import Prices from Excel</DialogTitle>
        <DialogContent>
          {uploadStatus && (
            <Alert 
              severity={uploadStatus.severity} 
              sx={{ mb: 2 }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setUploadStatus(null)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {uploadStatus.message}
            </Alert>
          )}
          
          <Card sx={{ mb: 3 }}>
            <CardHeader title="Instructions" />
            <CardContent>
              <Typography variant="body2" component="p">
                The Excel file should have the following columns:
              </Typography>
              <Box component="ul" sx={{ pl: 2 }}>
                <li>Brand</li>
                <li>Model</li>
                <li>Repair</li>
                <li>Storage</li>
                <li>Price NOK</li>
                <li>Price SEK</li>
                <li>Price DKK</li>
                <li>Price EUR</li>
              </Box>
              <Typography variant="body2" component="p" sx={{ mt: 1, fontWeight: 'bold' }}>
                Note: Importing will replace all existing prices!
              </Typography>
            </CardContent>
          </Card>
          
          <form onSubmit={handleUpload}>
            <Box sx={{ mb: 3 }}>
              <Button
                variant="contained"
                component="label"
                fullWidth
              >
                Select Excel File
                <input
                  id="fileUpload"
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept=".xlsx"
                />
              </Button>
              {file && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Selected file: {file.name}
                </Typography>
              )}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                color="inherit" 
                onClick={() => setUploadModal(false)} 
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                disabled={loading || !file}
              >
                {loading ? 'Uploading...' : 'Upload'}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PriceAdminPanel;
