// src/components/_dashboard/_widgets/ServiceQueueWidget.js
import React, { useState, useEffect } from 'react';
import { Card, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

const ServiceQueueWidget = () => {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const fetchServiceCount = async () => {
        try {
            const token = Cookies.get('token');
            console.log('token sent to server:', token);
            const decodedToken = jwtDecode(token);
            const username = decodedToken.sub;  // Endret fra .username til .sub
            const apiUrl = `${process.env.REACT_APP_STATUS_API_URL}/activity/assigned`;
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    token: token
                })
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            setCount(Array.isArray(data) ? data.length : 0);
        } catch (error) {
            console.error('Failed to fetch service count:', error);
        } finally {
            setLoading(false);
        }
    };
    
    

    useEffect(() => {
        fetchServiceCount();

        // Optional: Polling every 60 seconds to update the count
        const interval = setInterval(fetchServiceCount, 18000);
        return () => clearInterval(interval);
    }, []);

    if (loading) {
        return (
            <Card
                sx={{
                    padding: 2,
                    backgroundColor: '#1e1e1e',  // Changed from #292929
                    color: '#fff',
                    borderRadius: '8px',
                    textAlign: 'center',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    width: '220px',
                    height: '130px',
                    margin: '0 auto'
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Service Queue
                </Typography>
                <Typography variant="h5">Loading...</Typography>
            </Card>
        );
    }

    return (
        <Card
            sx={{
                padding: 2,
                backgroundColor: '#1e1e1e',  // Changed from #292929
                color: '#fff',
                borderRadius: '8px',
                textAlign: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                width: '220px',
                height: '130px',
                margin: '0 auto'
            }}
        >
            <Typography variant="h6" gutterBottom>
                Service Queue
            </Typography>
            <Typography
                variant="h3"
                sx={{
                    fontWeight: 'bold',
                    color: count > 20 ? 'red' : '#fff',
                }}
            >
                {count}
            </Typography>
        </Card>
    );
};

export default ServiceQueueWidget;
