import React, { useState, useEffect } from "react";
import { Typography, TextField, Box } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useActivity } from "../components/_activities/ActivityContext";
import QueuesList from "./_queues/QueuesList";
import { useNavigate } from "react-router-dom";

// Lager en key basert på endpoint for localStorage
const getLocalCacheKey = (endpoint) => `cachedQueues_${endpoint}`;

// Hjelpefunksjon for å formatere millisekunder til HH:MM:SS
const formatTime = (ms) => {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

const Queues = ({ endpoint = "/activity/assigned" }) => {
  const { setSelectedActivity } = useActivity();
  // Viser de dataene som for øyeblikket vises (gammel cache eller oppdatert data)
  const [services, setServices] = useState([]);
  // Buffer for nye data som skal settes inn når de er klare
  const [newData, setNewData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [lastFetched, setLastFetched] = useState(null);
  const [fetching, setFetching] = useState(false);
  // Neste tidspunkt for oppdatering (i ms)
  const [nextUpdate, setNextUpdate] = useState(null);
  // Nedtelling i ms til neste oppdatering
  const [timeLeft, setTimeLeft] = useState(0);
  const navigate = useNavigate();

  // Ved mounting: sjekk om vi har cache i localStorage
  useEffect(() => {
    const cacheKey = getLocalCacheKey(endpoint);
    const cachedDataString = localStorage.getItem(cacheKey);
    if (cachedDataString) {
      try {
        const cached = JSON.parse(cachedDataString);
        if (cached && cached.data) {
          setServices(cached.data);
          setLastFetched(cached.lastFetched);
          setNextUpdate(cached.lastFetched + 300000); // 5 minutter etter lagret tidspunkt
        }
      } catch (err) {
        console.error("Failed to parse cached data", err);
      }
    }
  }, [endpoint]);

  // Funksjon for å hente data fra serveren dersom cache er utdatert
  const fetchServices = async () => {
    setFetching(true);
    const cacheKey = getLocalCacheKey(endpoint);
    const now = Date.now();
    
    // Sjekk om vi har cache og om den er fersk (mindre enn 5 minutter gammel)
    const cachedDataString = localStorage.getItem(cacheKey);
    if (cachedDataString) {
      try {
        const cached = JSON.parse(cachedDataString);
        if (cached && cached.lastFetched && now - cached.lastFetched < 300000) {
          // Dataen er fortsatt fersk – oppdater nextUpdate og hopp over fetch
          setNextUpdate(cached.lastFetched + 300000);
          setFetching(false);
          return;
        }
      } catch (err) {
        console.error("Error parsing cached data", err);
      }
    }

    try {
      const token = Cookies.get("token");
      if (!token) throw new Error("No token found");

      const decodedToken = jwtDecode(token);
      const username = decodedToken.sub;

      const apiUrl = endpoint.startsWith("http")
        ? endpoint
        : `${process.env.REACT_APP_STATUS_API_URL}${endpoint}`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, token }),
      });

      if (!response.ok) throw new Error("Failed to fetch service data");
      const result = await response.json();

      const formattedData = result.map((service, index) => ({
        id: index,
        activityNumber: service.ActivityNumber,
        statusDate: service.StatusDate,
        daysSinceStatus: service.daysSinceStatus,
        refNo: service.RefNo || "",
        GuarRef: service.GuarRef || "",
        technician: service.Person ? service.Person.toLowerCase() : "",
        status: service.Status
          ? service.Status.charAt(0).toUpperCase() + service.Status.slice(1)
          : "",
        serviceCode: (service.ServiceCode || "").toUpperCase(),
        brand: (service.Brand || "")
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        model: service.ProdId || "",
        ProblemInMore: service.ProblemInMore || "",
      }));

      // Oppdater kun dersom dataene har endret seg
      if (JSON.stringify(formattedData) !== JSON.stringify(services)) {
        const nowTimestamp = Date.now();
        const newCache = {
          data: formattedData,
          lastFetched: nowTimestamp,
        };
        // Lagre ny cache i localStorage
        localStorage.setItem(cacheKey, JSON.stringify(newCache));
        setLastFetched(nowTimestamp);
        setNewData(formattedData);
        setNextUpdate(nowTimestamp + 300000);
      }
    } catch (error) {
      console.error("Failed to fetch service data:", error);
    } finally {
      setFetching(false);
    }
  };

  // Hent data med en gang og deretter hvert 5. minutt
  useEffect(() => {
    fetchServices();
    const intervalId = setInterval(() => {
      fetchServices();
    }, 300000);
    return () => clearInterval(intervalId);
  }, [endpoint]);

  // Når nye data er klare, oppdater de viste dataene
  useEffect(() => {
    if (newData.length > 0) {
      setServices(newData);
      setNewData([]);
    }
  }, [newData]);

  // Nedtelling til neste oppdatering
  useEffect(() => {
    if (nextUpdate) {
      const timer = setInterval(() => {
        const diff = nextUpdate - Date.now();
        setTimeLeft(diff > 0 ? diff : 0);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [nextUpdate]);

  // Filtrering av de viste dataene – filteret brukes på den siste viste listen
  const filteredServices = services.filter((service) => {
    const searchTerms = filterText.toLowerCase().split(/\s+/).filter(Boolean);
    return searchTerms.every((term) => {
      if (term.includes("=")) {
        const [field, expected] = term.split("=");
        const key = Object.keys(service).find(
          (k) => k.toLowerCase() === field.toLowerCase()
        );
        return key
          ? service[key] &&
              service[key].toString().toLowerCase().includes(expected.toLowerCase())
          : false;
      } else {
        return Object.values(service).some(
          (value) =>
            value && value.toString().toLowerCase().includes(term)
        );
      }
    });
  });

  const handleRowClick = (activityNumber) => {
    setSelectedActivity(activityNumber);
    navigate("/activities?tab=handling&subTab=activity");
  };

  return (
    <Box sx={{ 
      display: "flex", 
      flexDirection: "column", 
      height: "100vh", 
      width: "100%",
      '& ::-webkit-scrollbar': {
        width: '0px',  // Endret fra 8px til 0px
        display: 'none' // Legger til dette for å skjule scrollbar helt
      },
      msOverflowStyle: 'none',  // For IE og Edge
      scrollbarWidth: 'none',   // For Firefox
    }}>
      {/* Søkefelt */}
      <Box sx={{ padding: "10px", flexShrink: 0, marginLeft: "-10px" }}>
        <TextField
          label="Search..."
          variant="outlined"
          fullWidth
          size="small"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{
            backgroundColor: "#333",
            color: "#fff",
            "& .MuiInputBase-input": { color: "#fff" },
            "& .MuiInputLabel-root": { color: "#fff" },
          }}
        />
      </Box>

      {/* List view of services */}
        <Box sx={{ flex: "grow", overflow: "auto" }}>
          {services.length > 0 ? (
            <QueuesList services={filteredServices} onRowClick={handleRowClick} />
          ) : localStorage.getItem(getLocalCacheKey(endpoint)) ? (
            <Typography sx={{ textAlign: "center", padding: 2 }}>
          No active services found.
            </Typography>
          ) : (
            <Typography sx={{ textAlign: "center", padding: 2 }}>
          Fetching data...
            </Typography>
          )}

          {/* Update indicator with countdown */}
        <Typography sx={{ fontSize: "12px", color: "#bbb", textAlign: "right", padding: "5px" }}>
          {fetching
            ? "Oppdaterer data..."
            : `Last updated: ${
                lastFetched ? new Date(lastFetched).toLocaleTimeString() : "Never"
              }. Will update in ${formatTime(timeLeft)}`}
        </Typography>
      </Box>
    </Box>
  );
};

Queues.propTypes = {
  endpoint: PropTypes.string,
};

export default Queues;
