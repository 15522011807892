// AnalogWatch.js
import React, { useRef, useEffect } from 'react';

const AnalogWatch = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        
        // Håndter DPI/pixel ratio for skarpere rendering
        const dpr = window.devicePixelRatio || 1;
        const rect = canvas.getBoundingClientRect();

        // Sett faktisk canvas størrelse større for bedre oppløsning
        canvas.width = rect.width * dpr;
        canvas.height = rect.height * dpr;
        
        // Skaler context for å matche DPI
        context.scale(dpr, dpr);
        
        const radius = (rect.width / 2); // Bruk rect.width istedenfor canvas.width
        context.translate(radius, radius);

        const drawClock = () => {
            // Reset alle canvas states
            context.setTransform(dpr, 0, 0, dpr, 0, 0);
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.translate(radius, radius);
            
            // Reset shadow før hver tegning
            context.shadowColor = 'transparent';
            context.shadowBlur = 0;
            context.shadowOffsetX = 0;
            context.shadowOffsetY = 0;
            
            drawFace(context, radius);
            drawNumbers(context, radius);
            drawTime(context, radius);
        };

        const intervalId = setInterval(drawClock, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const drawFace = (ctx, radius) => {
        // Mørk bakgrunn - endret farge
        ctx.beginPath();
        ctx.arc(0, 0, radius * 0.9, 0, 2 * Math.PI);
        ctx.fillStyle = '#0d0d0d';
        ctx.fill();

        // Blå ytre ring
        ctx.strokeStyle = '#388bfd';
        ctx.lineWidth = radius * 0.02;
        ctx.stroke();

        // Timemarkeringer i blått
        for (let i = 0; i < 12; i++) {
            const ang = (i * Math.PI) / 6;
            ctx.beginPath();
            ctx.rotate(ang);
            ctx.moveTo(radius * 0.85, 0);
            ctx.lineTo(radius * 0.89, 0);
            ctx.strokeStyle = '#388bfd';
            ctx.lineWidth = radius * 0.02;
            ctx.lineCap = 'round';
            ctx.stroke();
            ctx.rotate(-ang);
        }

        // Blått midtpunkt
        ctx.beginPath();
        ctx.arc(0, 0, radius * 0.04, 0, 2 * Math.PI);
        ctx.fillStyle = '#388bfd';
        ctx.fill();
    };

    const drawNumbers = (ctx, radius) => {
        ctx.font = `${radius * 0.15}px 'Inter', sans-serif`;
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#ffffff'; // Hvite tall for kontrast

        for (let num = 1; num <= 12; num++) {
            const ang = (num * Math.PI) / 6;
            const x = Math.sin(ang) * (radius * 0.75);
            const y = -Math.cos(ang) * (radius * 0.75);
            ctx.fillText(num.toString(), x, y);
        }
    };

    const drawTime = (ctx, radius) => {
        const now = new Date();
        let hour = now.getHours();
        let minute = now.getMinutes();
        let second = now.getSeconds();

        // Time hand
        hour = hour % 12;
        hour = (hour * Math.PI) / 6 + (minute * Math.PI) / (6 * 60);
        ctx.strokeStyle = '#ffffff';
        drawHand(ctx, hour, radius * 0.5, radius * 0.03);

        // Minute hand
        minute = (minute * Math.PI) / 30;
        ctx.strokeStyle = '#ffffff';
        drawHand(ctx, minute, radius * 0.7, radius * 0.025);

        // Second hand
        second = (second * Math.PI) / 30;
        ctx.strokeStyle = '#388bfd';
        drawHand(ctx, second, radius * 0.8, radius * 0.01);
    };

    const drawHand = (ctx, pos, length, width) => {
        ctx.beginPath();
        ctx.lineWidth = width;
        ctx.lineCap = 'round';
        ctx.moveTo(0, 0);
        ctx.rotate(pos);
        ctx.lineTo(0, -length);
        ctx.stroke();
        ctx.rotate(-pos);
    };

    return (
        <div style={styles.container}>
            <canvas ref={canvasRef} style={styles.canvas}></canvas>
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#0d0d0d', // Endret bakgrunnsfarge
        padding: '20px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
        width: 'fit-content',
        margin: 'auto'
    },
    canvas: {
        backgroundColor: '#0d0d0d', // Endret bakgrunnsfarge
        borderRadius: '50%',
        border: '1px solid #388bfd',
        width: '290px',
        height: '290px',
        display: 'block'
    }
};

export default AnalogWatch;
