import React, { useState, useEffect } from "react";
import { Card, Typography, Box, Stack } from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const DataSourceWidget = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchUserData = async () => {
        try {
            const token = Cookies.get("token");
            if (!token) throw new Error("No token found");

            const decodedToken = jwtDecode(token);
            const username = decodedToken.sub;

            const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/user?username=${username}&token=${token}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            });

            if (!response.ok) throw new Error("Failed to fetch user data");

            const result = await response.json();
            setUserData(result);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setUserData(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    if (loading) {
        return (
            <Card sx={{ width: 300, padding: 2, backgroundColor: "#1e1e1e", color: "#fff" }}>
                <Typography variant="h6" align="center">Data Source</Typography>
                <Typography variant="h5" align="center">Loading...</Typography>
            </Card>
        );
    }

    if (!userData) {
        return (
            <Card sx={{ width: 300, padding: 2, backgroundColor: "#1e1e1e", color: "#fff" }}>
                <Typography variant="h6" align="center">Data Source</Typography>
                <Typography variant="h5" align="center">No data available</Typography>
            </Card>
        );
    }

    return (
        <Card sx={{ width: 300, padding: 2, backgroundColor: "#1e1e1e", color: "#fff" }}>
            <Stack spacing={2}>
                <Typography variant="h6" align="center">Data Source</Typography>
                <Box sx={{ 
                    backgroundColor: '#444',
                    padding: 2,
                    borderRadius: 1,
                    border: '1px solid #555'
                }}>
                    <Typography variant="h6" sx={{ mb: 1 }}>Group: {userData.persgroup}</Typography>
                    <Typography variant="h6" sx={{ mb: 1 }}>District: {userData.persdistr}</Typography>
                    <Typography variant="h6">Database: {userData.sesamdb}</Typography>
                </Box>
            </Stack>
        </Card>
    );
};

export default DataSourceWidget;
