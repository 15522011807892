import React, { useEffect, useState } from 'react';
import { 
  Box, Typography, TextField, Button, List, ListItem,
  ListItemText, IconButton, Divider, Paper, Alert, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions, Chip,
  Tab, Tabs, Card, CardHeader, CardContent
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const RoleManager = () => {
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [presetDialogOpen, setPresetDialogOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [presets, setPresets] = useState([]);
  const [availableFunctions, setAvailableFunctions] = useState([]);
  const [dialogTabValue, setDialogTabValue] = useState(0);
  const [availableSystemFunctions, setAvailableSystemFunctions] = useState([]);
  const [selectedRoleFunctions, setSelectedRoleFunctions] = useState([]);
  const [availableRoleFunctions, setAvailableRoleFunctions] = useState([]);
  const [selectedPresetFunctions, setSelectedPresetFunctions] = useState([]);

  // State for tracking selected items in each list
  const [selectedAvailableSystemItem, setSelectedAvailableSystemItem] = useState(null);
  const [selectedRoleFunctionItem, setSelectedRoleFunctionItem] = useState(null);
  const [selectedAvailableRoleItem, setSelectedAvailableRoleItem] = useState(null);
  const [selectedPresetFunctionItem, setSelectedPresetFunctionItem] = useState(null);

  // Fetch all roles
  const fetchRoles = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const token = Cookies.get("token");
      if (!token) throw new Error("No token found");

      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/list_roles`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error("Failed to fetch roles");
      
      const data = await response.json();
      setRoles(data.roles || []);
    } catch (err) {
      console.error("Error fetching roles:", err);
      setError("Failed to load roles: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch available functions - making sure we always return an array
  const fetchAvailableFunctions = async () => {
    try {
      const token = Cookies.get("token");
      if (!token) throw new Error("No token found");

      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/list_all_functions`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error("Failed to fetch functions");
      
      const data = await response.json();
      const functions = data.functions || [];
      setAvailableFunctions(functions);
      return functions; // Return the functions array for direct use
    } catch (err) {
      console.error("Error fetching functions:", err);
      // Fallback to default functions if API call fails
      const defaultFunctions = [
        'dashboard', 'activities', 'stock', 'shipping', 'social',
        'workers', 'repaircost', 'priceadmin', 'superadmin', 'settings'
      ];
      setAvailableFunctions(defaultFunctions);
      return defaultFunctions; // Return the default functions
    }
  };

  // Fetch role presets
  const fetchRolePresets = async (role) => {
    try {
      const token = Cookies.get("token");
      if (!token) throw new Error("No token found");

      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/list_role_presets?role=${role}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error("Failed to fetch role presets");
      
      const data = await response.json();
      return data.presets || [];
    } catch (err) {
      console.error("Error fetching role presets:", err);
      return [];
    }
  };

  // Replace the function with direct table recreation
  const recreateRolesTableIfNeeded = async () => {
    try {
      const token = Cookies.get("token");
      if (!token) throw new Error("No token found");
      
      setLoading(true);
      setError("Fixing database schema by recreating roles table...");

      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/api/admin/create_roles_table`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error("Failed to recreate roles table");
      
      setSuccessMessage("Roles table recreated successfully with all required columns");
      fetchRoles(); // Refresh roles list
      
      setTimeout(() => setSuccessMessage(null), 3000);
      return true;
    } catch (err) {
      console.error("Error recreating roles table:", err);
      setError("Failed to fix database schema: " + err.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Handle missing column error directly
  const handleMissingColumnError = async (errorMsg) => {
    if (errorMsg && errorMsg.includes('Missing required column')) {
      return await recreateRolesTableIfNeeded();
    }
    return false;
  };

  // Fetch role available functions with direct handling for missing columns
  const fetchRoleAvailableFunctions = async (role) => {
    try {
      const token = Cookies.get("token");
      if (!token) throw new Error("No token found");

      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/list_role_functions?role=${role}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 400) {
        // This might be the missing column error
        const data = await response.json();
        if (await handleMissingColumnError(data.error)) {
          // Try again after fixing
          return fetchRoleAvailableFunctions(role);
        }
        throw new Error(data.error || "Bad request");
      }
      
      if (!response.ok) throw new Error("Failed to fetch role available functions");
      
      const data = await response.json();
      return data.functions || [];
    } catch (err) {
      console.error("Error fetching role available functions:", err);
      return [];
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchAvailableFunctions();
  }, []);

  const handleAddRole = async () => {
    if (!newRole.trim()) return;
    
    try {
      setLoading(true);
      const token = Cookies.get("token");
      
      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/add_role`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ role: newRole.trim() })
      });

      if (!response.ok) throw new Error("Failed to add role");
      
      setSuccessMessage(`Role '${newRole}' added successfully`);
      setNewRole('');
      fetchRoles();
      
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Error adding role:", err);
      setError("Failed to add role: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRole = async (role) => {
    try {
      setLoading(true);
      const token = Cookies.get("token");
      
      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/delete_role`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ role: role.role })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to delete role");
      }
      
      setSuccessMessage(`Role '${role.role}' deleted successfully`);
      fetchRoles();
      
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Error deleting role:", err);
      setError("Failed to delete role: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoleData = async (role) => {
    try {
      setSelectedRole(role);
      setLoading(true);
      setDialogTabValue(0);
      
      // Fetch all system functions first to make sure we have them
      const allFunctions = await fetchAvailableFunctions();
      
      // Fetch current available functions for this role (ensure it's an array)
      const roleFuncs = await fetchRoleAvailableFunctions(role.role) || [];
      
      // Fetch current presets for this role (ensure it's an array)
      const rolePresets = await fetchRolePresets(role.role) || [];
      
      setPresets(rolePresets);
      
      // Setup for Available Functions tab - safely filter out functions already assigned to the role
      setSelectedRoleFunctions(roleFuncs);
      setAvailableSystemFunctions(
        allFunctions.filter(func => !roleFuncs.includes(func))
      );
      
      // Setup for Function Presets tab - safely filter out functions already in presets
      setSelectedPresetFunctions(rolePresets);
      setAvailableRoleFunctions(
        roleFuncs.filter(func => !rolePresets.includes(func))
      );
      
      setPresetDialogOpen(true);
    } catch (err) {
      console.error("Error loading role data:", err);
      setError("Failed to load role data: " + err.message);
      
      // Initialize with empty arrays to prevent further errors
      setPresets([]);
      setSelectedRoleFunctions([]);
      setAvailableSystemFunctions([]);
      setAvailableRoleFunctions([]);
      setSelectedPresetFunctions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPresetDialog = (role) => {
    fetchRoleData(role);
  };

  const handleClosePresetDialog = () => {
    setPresetDialogOpen(false);
    setSelectedRole(null);
    setPresets([]);
    setAvailableSystemFunctions([]);
    setSelectedRoleFunctions([]);
    setAvailableRoleFunctions([]);
    setSelectedPresetFunctions([]);
    fetchRoles(); // Refresh the main roles list
  };

  const handleAddRoleFunctionsOne = async (functionName) => {
    if (!selectedRole || !functionName) return;
    
    try {
      const token = Cookies.get("token");
      
      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/add_role_functions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          role: selectedRole.role, 
          functions: [functionName]
        })
      });

      if (response.status === 400) {
        const data = await response.json();
        if (await handleMissingColumnError(data.error)) {
          handleAddRoleFunctionsOne(functionName);
          return;
        }
        throw new Error(data.error || "Bad request");
      }

      if (!response.ok) throw new Error("Failed to add available function");
      
      // No need to update UI state as we already did that in the drag handler
      setSuccessMessage(`Function '${functionName}' added to role '${selectedRole.role}'`);
      
      // Update the preset tab's available functions list
      setAvailableRoleFunctions(prev => [...prev, functionName].sort());
      
      setTimeout(() => setSuccessMessage(null), 2000);
    } catch (err) {
      console.error("Error adding role function:", err);
      setError("Failed to add function to role: " + err.message);
      
      // Revert the UI change on error
      setAvailableSystemFunctions(prev => [...prev, functionName].sort());
      setSelectedRoleFunctions(prev => prev.filter(f => f !== functionName));
      
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleRemoveRoleFunctionOne = async (functionName) => {
    if (!selectedRole || !functionName) return;
    
    try {
      const token = Cookies.get("token");
      
      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/remove_role_function`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          role: selectedRole.role, 
          function: functionName
        })
      });

      if (response.status === 400) {
        const data = await response.json();
        if (await handleMissingColumnError(data.error)) {
          handleRemoveRoleFunctionOne(functionName);
          return;
        }
        throw new Error(data.error || "Bad request");
      }

      if (!response.ok) throw new Error("Failed to remove function from role");
      
      // No need to update UI state as we already did that in the drag handler
      setSuccessMessage(`Function '${functionName}' removed from role '${selectedRole.role}'`);

      // If this function was in presets, remove it from there too
      if (selectedPresetFunctions.includes(functionName)) {
        handleRemovePresetOne(functionName);
      }
      
      // Remove from the preset tab's available functions
      setAvailableRoleFunctions(prev => prev.filter(f => f !== functionName));
      
      setTimeout(() => setSuccessMessage(null), 2000);
    } catch (err) {
      console.error("Error removing role function:", err);
      setError("Failed to remove function from role: " + err.message);
      
      // Revert the UI change on error
      setAvailableSystemFunctions(prev => prev.filter(f => f !== functionName));
      setSelectedRoleFunctions(prev => [...prev, functionName].sort());
      
      setTimeout(() => setError(null), 3000);
    }
  };
  
  const handleAddPresetOne = async (functionName) => {
    if (!selectedRole || !functionName) return;
    
    try {
      const token = Cookies.get("token");
      
      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/add_role_preset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          role: selectedRole.role, 
          functions: [functionName]
        })
      });

      if (!response.ok) throw new Error("Failed to add function to preset");
      
      // No need to update UI state as we already did that in the drag handler  
      setSuccessMessage(`Function '${functionName}' added to preset for role '${selectedRole.role}'`);
      
      setTimeout(() => setSuccessMessage(null), 2000);
    } catch (err) {
      console.error("Error adding preset:", err);
      setError("Failed to add function to preset: " + err.message);
      
      // Revert the UI change on error
      setAvailableRoleFunctions(prev => [...prev, functionName].sort());
      setSelectedPresetFunctions(prev => prev.filter(f => f !== functionName));
      
      setTimeout(() => setError(null), 3000);
    }
  };
  
  const handleRemovePresetOne = async (functionName) => {
    if (!selectedRole || !functionName) return;
    
    try {
      const token = Cookies.get("token");
      
      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/remove_role_preset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          role: selectedRole.role, 
          functions: [functionName]
        })
      });

      if (!response.ok) throw new Error("Failed to remove function from preset");
      
      // No need to update UI state as we already did that in the drag handler
      setSuccessMessage(`Function '${functionName}' removed from preset for role '${selectedRole.role}'`);
      
      setTimeout(() => setSuccessMessage(null), 2000);
    } catch (err) {
      console.error("Error removing preset:", err);
      setError("Failed to remove function from preset: " + err.message);
      
      // Revert the UI change on error
      setAvailableRoleFunctions(prev => prev.filter(f => f !== functionName));
      setSelectedPresetFunctions(prev => [...prev, functionName].sort());
      
      setTimeout(() => setError(null), 3000);
    }
  };

  // Handle drag-and-drop for Available Functions tab
  const handleRoleFunctionsDragEnd = (result) => {
    if (!result.destination) return;
    
    const source = result.source;
    const destination = result.destination;
    
    if (source.droppableId === destination.droppableId) {
      // Reordering within the same list - optional but can be useful
      const list = source.droppableId === 'availableSystemFunctions'
        ? [...availableSystemFunctions]
        : [...selectedRoleFunctions];
        
      const [removed] = list.splice(source.index, 1);
      list.splice(destination.index, 0, removed);
      
      if (source.droppableId === 'availableSystemFunctions') {
        setAvailableSystemFunctions(list);
      } else {
        setSelectedRoleFunctions(list);
      }
    } else {
      // Moving between lists
      const sourceList = source.droppableId === 'availableSystemFunctions'
        ? [...availableSystemFunctions]
        : [...selectedRoleFunctions];
        
      const destList = destination.droppableId === 'availableSystemFunctions'
        ? [...availableSystemFunctions]
        : [...selectedRoleFunctions];
        
      const [removed] = sourceList.splice(source.index, 1);
      destList.splice(destination.index, 0, removed);
      
      if (source.droppableId === 'availableSystemFunctions') {
        setAvailableSystemFunctions(sourceList);
        setSelectedRoleFunctions(destList);
        
        // Auto-save changes - add function to role
        handleAddRoleFunctionsOne(removed);
      } else {
        setAvailableSystemFunctions(destList);
        setSelectedRoleFunctions(sourceList);
        
        // Auto-save changes - remove function from role
        handleRemoveRoleFunctionOne(removed);
      }
    }
  };
  
  // Handle drag-and-drop for Function Presets tab
  const handlePresetFunctionsDragEnd = (result) => {
    if (!result.destination) return;
    
    const source = result.source;
    const destination = result.destination;
    
    if (source.droppableId === destination.droppableId) {
      // Reordering within the same list
      const list = source.droppableId === 'availableRoleFunctions'
        ? [...availableRoleFunctions]
        : [...selectedPresetFunctions];
        
      const [removed] = list.splice(source.index, 1);
      list.splice(destination.index, 0, removed);
      
      if (source.droppableId === 'availableRoleFunctions') {
        setAvailableRoleFunctions(list);
      } else {
        setSelectedPresetFunctions(list);
      }
    } else {
      // Moving between lists
      const sourceList = source.droppableId === 'availableRoleFunctions'
        ? [...availableRoleFunctions]
        : [...selectedPresetFunctions];
        
      const destList = destination.droppableId === 'availableRoleFunctions'
        ? [...availableRoleFunctions]
        : [...selectedPresetFunctions];
        
      const [removed] = sourceList.splice(source.index, 1);
      destList.splice(destination.index, 0, removed);
      
      if (source.droppableId === 'availableRoleFunctions') {
        setAvailableRoleFunctions(sourceList);
        setSelectedPresetFunctions(destList);
        
        // Auto-save changes - add function to preset
        handleAddPresetOne(removed);
      } else {
        setAvailableRoleFunctions(destList);
        setSelectedPresetFunctions(sourceList);
        
        // Auto-save changes - remove function from preset
        handleRemovePresetOne(removed);
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setDialogTabValue(newValue);
  };

  // Handler for item selection in the Available Functions tab
  const handleAvailableSystemItemClick = (item) => {
    setSelectedAvailableSystemItem(selectedAvailableSystemItem === item ? null : item);
    setSelectedRoleFunctionItem(null); // Clear other selection
  };

  const handleRoleFunctionItemClick = (item) => {
    setSelectedRoleFunctionItem(selectedRoleFunctionItem === item ? null : item);
    setSelectedAvailableSystemItem(null); // Clear other selection
  };

  // Handler for item selection in the Function Presets tab
  const handleAvailableRoleItemClick = (item) => {
    setSelectedAvailableRoleItem(selectedAvailableRoleItem === item ? null : item);
    setSelectedPresetFunctionItem(null); // Clear other selection
  };

  const handlePresetFunctionItemClick = (item) => {
    setSelectedPresetFunctionItem(selectedPresetFunctionItem === item ? null : item);
    setSelectedAvailableRoleItem(null); // Clear other selection
  };

  // Handlers for arrow button clicks - Available Functions tab
  const handleMoveToRole = () => {
    if (selectedAvailableSystemItem) {
      const updatedAvailableSystemFunctions = availableSystemFunctions.filter(
        item => item !== selectedAvailableSystemItem
      );
      const updatedSelectedRoleFunctions = [...selectedRoleFunctions, selectedAvailableSystemItem].sort();
      
      setAvailableSystemFunctions(updatedAvailableSystemFunctions);
      setSelectedRoleFunctions(updatedSelectedRoleFunctions);
      handleAddRoleFunctionsOne(selectedAvailableSystemItem);
      setSelectedAvailableSystemItem(null);
    }
  };

  const handleRemoveFromRole = () => {
    if (selectedRoleFunctionItem) {
      const updatedSelectedRoleFunctions = selectedRoleFunctions.filter(
        item => item !== selectedRoleFunctionItem
      );
      const updatedAvailableSystemFunctions = [...availableSystemFunctions, selectedRoleFunctionItem].sort();
      
      setSelectedRoleFunctions(updatedSelectedRoleFunctions);
      setAvailableSystemFunctions(updatedAvailableSystemFunctions);
      handleRemoveRoleFunctionOne(selectedRoleFunctionItem);
      setSelectedRoleFunctionItem(null);
    }
  };

  // Handlers for arrow button clicks - Function Presets tab
  const handleAddToPreset = () => {
    if (selectedAvailableRoleItem) {
      const updatedAvailableRoleFunctions = availableRoleFunctions.filter(
        item => item !== selectedAvailableRoleItem
      );
      const updatedSelectedPresetFunctions = [...selectedPresetFunctions, selectedAvailableRoleItem].sort();
      
      setAvailableRoleFunctions(updatedAvailableRoleFunctions);
      setSelectedPresetFunctions(updatedSelectedPresetFunctions);
      handleAddPresetOne(selectedAvailableRoleItem);
      setSelectedAvailableRoleItem(null);
    }
  };

  const handleRemoveFromPreset = () => {
    if (selectedPresetFunctionItem) {
      const updatedSelectedPresetFunctions = selectedPresetFunctions.filter(
        item => item !== selectedPresetFunctionItem
      );
      const updatedAvailableRoleFunctions = [...availableRoleFunctions, selectedPresetFunctionItem].sort();
      
      setSelectedPresetFunctions(updatedSelectedPresetFunctions);
      setAvailableRoleFunctions(updatedAvailableRoleFunctions);
      handleRemovePresetOne(selectedPresetFunctionItem);
      setSelectedPresetFunctionItem(null);
    }
  };

  // Function to render a draggable function item
  const renderDraggableFunction = (item, index, isDragDisabled = false) => (
    <Draggable 
      key={item} 
      draggableId={item} 
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            p: 0.5,
            mb: 0.5,
            display: 'flex',
            alignItems: 'center',
            bgcolor: snapshot.isDragging ? '#1a567d' : '#444',
            borderRadius: '16px',
            color: '#fff',
            fontSize: '0.85rem',
            transition: 'background-color 0.2s',
            '&:hover': {
              bgcolor: snapshot.isDragging ? '#1a567d' : '#555',
            }
          }}
        >
          <DragIndicatorIcon sx={{ fontSize: '1rem', mr: 0.5, opacity: isDragDisabled ? 0.3 : 0.7 }} />
          {item}
        </Box>
      )}
    </Draggable>
  );

  // Updated render for selectable function items (non-draggable version)
  const renderSelectableFunction = (item, isSelected, onClick) => (
    <Box
      key={item}
      onClick={() => onClick(item)}
      sx={{
        p: 0.5,
        mb: 0.5,
        display: 'flex',
        alignItems: 'center',
        bgcolor: isSelected ? '#1a567d' : '#444',
        borderRadius: '16px',
        color: '#fff',
        fontSize: '0.85rem',
        transition: 'background-color 0.2s',
        cursor: 'pointer',
        '&:hover': {
          bgcolor: isSelected ? '#1a567d' : '#555',
        }
      }}
    >
      {item}
    </Box>
  );

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="subtitle2" sx={{ mb: 1, color: '#fff', fontSize: '0.9rem' }}>
        Role Management
      </Typography>
      
      {/* Add Role Section */}
      <Paper elevation={0} sx={{ p: 1, mb: 1, bgcolor: '#333', borderRadius: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <TextField
            size="small"
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
            placeholder="Enter role name"
            variant="outlined"
            sx={{
              flexGrow: 1,
              mr: 1,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                '& fieldset': { borderColor: '#555' },
                '&:hover fieldset': { borderColor: '#777' },
                '&.Mui-focused fieldset': { borderColor: '#0088cc' }
              },
              '& .MuiInputBase-input': { py: 0.75 }
            }}
          />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            disabled={!newRole.trim() || loading}
            onClick={handleAddRole}
            size="small"
            sx={{
              bgcolor: '#0066aa',
              '&:hover': { bgcolor: '#0088cc' },
              textTransform: 'none',
              py: 0.75
            }}
          >
            Add Role
          </Button>
        </Box>
      </Paper>

      {/* Success/Error Messages */}
      {successMessage && (
        <Alert severity="success" sx={{ mb: 1, py: 0, bgcolor: '#143a1d', color: '#a1ffa1' }}>
          {successMessage}
        </Alert>
      )}
      
      {error && (
        <Alert severity="error" sx={{ mb: 1, py: 0, bgcolor: '#3a1414', color: '#ffa1a1' }}>
          {error}
        </Alert>
      )}

      {/* Roles List */}
      <Paper 
        elevation={0} 
        sx={{ 
          flexGrow: 1, 
          bgcolor: '#222', 
          borderRadius: 1, 
          overflow: 'auto'
        }}
      >
        {loading && roles.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress size={24} sx={{ color: '#0088cc' }} />
          </Box>
        ) : (
          <List dense disablePadding sx={{ width: '100%' }}>
            {roles.length > 0 ? (
              roles.map((role, index) => (
                <React.Fragment key={role.id || index}>
                  <ListItem
                    secondaryAction={
                      <Box>
                        <IconButton 
                          edge="end" 
                          size="small"
                          onClick={() => handleOpenPresetDialog(role)}
                          sx={{ color: '#66bbff', mr: 1 }}
                          title="Manage role functions"
                        >
                          <SettingsIcon fontSize="small" />
                        </IconButton>
                        <IconButton 
                          edge="end" 
                          size="small" 
                          onClick={() => handleDeleteRole(role)}
                          sx={{ color: '#ff6666' }}
                          title="Delete role"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    }
                    sx={{ px: 1 }}
                  >
                    <ListItemText 
                      primary={role.role} 
                      primaryTypographyProps={{ 
                        sx: { color: '#fff', fontSize: '0.85rem' } 
                      }} 
                      secondary={
                        <>
                          {role.available_functions && role.available_functions.length > 0 
                            ? `${role.available_functions.length} available functions` 
                            : 'No available functions'} 
                          {' | '}
                          {role.function_preset && role.function_preset.length > 0 
                            ? `${role.function_preset.length} preset functions` 
                            : 'No preset functions'}
                        </>
                      }
                      secondaryTypographyProps={{
                        sx: { color: '#aaa', fontSize: '0.75rem' }
                      }}
                    />
                  </ListItem>
                  {index < roles.length - 1 && (
                    <Divider variant="middle" sx={{ borderColor: '#444' }} />
                  )}
                </React.Fragment>
              ))
            ) : (
              <ListItem>
                <ListItemText 
                  primary="No roles available" 
                  primaryTypographyProps={{ 
                    sx: { color: '#999', fontSize: '0.85rem', textAlign: 'center' } 
                  }} 
                />
              </ListItem>
            )}
          </List>
        )}
      </Paper>

      {/* Role Management Dialog */}
      <Dialog 
        open={presetDialogOpen} 
        onClose={handleClosePresetDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: '#2a2a2a',
            color: '#fff',
            borderRadius: 1
          }
        }}
      >
        <DialogTitle sx={{ bgcolor: '#333', fontSize: '1rem', pb: 1 }}>
          Function Management for Role: {selectedRole?.role}
        </DialogTitle>
        <Tabs
          value={dialogTabValue}
          onChange={handleTabChange}
          sx={{
            bgcolor: '#333',
            '& .MuiTab-root': {
              color: '#bbb',
              '&.Mui-selected': { color: '#fff' }
            },
            '& .MuiTabs-indicator': { bgcolor: '#0088cc' }
          }}
        >
          <Tab label="Available Functions" />
          <Tab label="Function Presets" />
        </Tabs>
        <DialogContent sx={{ mt: 2 }}>
          {dialogTabValue === 0 && (
            <>
              <Typography variant="body2" sx={{ mb: 2, fontSize: '0.8rem', color: '#aaa' }}>
                Select a function and click the arrows to add or remove it from the role's available functions, or drag and drop between lists.
              </Typography>
              
              <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                {/* Available System Functions */}
                <Card sx={{ flex: 1, bgcolor: '#333', color: '#fff' }}>
                  <CardHeader 
                    title="System Functions" 
                    titleTypographyProps={{ fontSize: '0.9rem' }}
                    sx={{ pb: 1, bgcolor: '#444' }}
                  />
                  <CardContent sx={{ p: 1, height: '300px', overflow: 'auto' }}>
                    {availableSystemFunctions.map(func => (
                      renderSelectableFunction(
                        func, 
                        selectedAvailableSystemItem === func,
                        handleAvailableSystemItemClick
                      )
                    ))}
                  </CardContent>
                </Card>

                {/* Transfer Arrows */}
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <IconButton 
                    size="small" 
                    sx={{ 
                      color: selectedAvailableSystemItem ? '#0088cc' : '#555',
                      mb: 1,
                      '&:hover': { bgcolor: selectedAvailableSystemItem ? 'rgba(0, 136, 204, 0.1)' : 'transparent' }
                    }}
                    onClick={handleMoveToRole}
                    disabled={!selectedAvailableSystemItem}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    sx={{ 
                      color: selectedRoleFunctionItem ? '#0088cc' : '#555',
                      '&:hover': { bgcolor: selectedRoleFunctionItem ? 'rgba(0, 136, 204, 0.1)' : 'transparent' }
                    }}
                    onClick={handleRemoveFromRole}
                    disabled={!selectedRoleFunctionItem}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Box>

                {/* Role Functions */}
                <Card sx={{ flex: 1, bgcolor: '#333', color: '#fff' }}>
                  <CardHeader 
                    title="Role Functions" 
                    titleTypographyProps={{ fontSize: '0.9rem' }}
                    sx={{ pb: 1, bgcolor: '#0066aa' }}
                  />
                  <CardContent sx={{ p: 1, height: '300px', overflow: 'auto' }}>
                    {selectedRoleFunctions.map(func => (
                      renderSelectableFunction(
                        func, 
                        selectedRoleFunctionItem === func,
                        handleRoleFunctionItemClick
                      )
                    ))}
                  </CardContent>
                </Card>
              </Box>
              
              <Alert severity="info" sx={{ mt: 1, fontSize: '0.8rem' }}>
                These are the functions that are available for this role. Users with this role will only have access to these functions.
              </Alert>
            </>
          )}

          {dialogTabValue === 1 && (
            <>
              <Typography variant="body2" sx={{ mb: 2, fontSize: '0.8rem', color: '#aaa' }}>
                Select a function and click the arrows to add or remove it from the role's default preset, or drag and drop between lists.
              </Typography>
              
              <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                {/* Available Role Functions */}
                <Card sx={{ flex: 1, bgcolor: '#333', color: '#fff' }}>
                  <CardHeader 
                    title="Available Role Functions" 
                    titleTypographyProps={{ fontSize: '0.9rem' }}
                    sx={{ pb: 1, bgcolor: '#444' }}
                  />
                  <CardContent sx={{ p: 1, height: '300px', overflow: 'auto' }}>
                    {availableRoleFunctions.map(func => (
                      renderSelectableFunction(
                        func, 
                        selectedAvailableRoleItem === func,
                        handleAvailableRoleItemClick
                      )
                    ))}
                  </CardContent>
                </Card>

                {/* Transfer Arrows */}
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <IconButton 
                    size="small" 
                    sx={{ 
                      color: selectedAvailableRoleItem ? '#0088cc' : '#555',
                      mb: 1,
                      '&:hover': { bgcolor: selectedAvailableRoleItem ? 'rgba(0, 136, 204, 0.1)' : 'transparent' }
                    }}
                    onClick={handleAddToPreset}
                    disabled={!selectedAvailableRoleItem}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                  <IconButton 
                    size="small" 
                    sx={{ 
                      color: selectedPresetFunctionItem ? '#0088cc' : '#555',
                      '&:hover': { bgcolor: selectedPresetFunctionItem ? 'rgba(0, 136, 204, 0.1)' : 'transparent' }
                    }}
                    onClick={handleRemoveFromPreset}
                    disabled={!selectedPresetFunctionItem}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Box>

                {/* Preset Functions */}
                <Card sx={{ flex: 1, bgcolor: '#333', color: '#fff' }}>
                  <CardHeader 
                    title="Preset Functions" 
                    titleTypographyProps={{ fontSize: '0.9rem' }}
                    sx={{ pb: 1, bgcolor: '#007b55' }}
                  />
                  <CardContent sx={{ p: 1, height: '300px', overflow: 'auto' }}>
                    {selectedPresetFunctions.map(func => (
                      renderSelectableFunction(
                        func, 
                        selectedPresetFunctionItem === func,
                        handlePresetFunctionItemClick
                      )
                    ))}
                  </CardContent>
                </Card>
              </Box>
              
              <Alert severity="info" sx={{ mt: 1, fontSize: '0.8rem' }}>
                These functions represent the default set that will be enabled for new users with this role.
              </Alert>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ bgcolor: '#333' }}>
          <Button 
            onClick={handleClosePresetDialog} 
            sx={{ color: '#fff', textTransform: 'none' }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RoleManager;
