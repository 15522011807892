import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, TextField, Button, FormControlLabel, Checkbox, Select, MenuItem } from '@mui/material';
import axios from 'axios';


const FunctionViewer = ({ serverIp, onSubmit }) => {
  const [functions, setFunctions] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [batchInput, setBatchInput] = useState('');
  const [isBatchMode, setIsBatchMode] = useState(false);
  const [validStatuses, setValidStatuses] = useState([]);
  const [isInitialMount, setIsInitialMount] = useState(true);
  
  // New state for dropdown options
  const [dropdownOptions, setDropdownOptions] = useState({
    brands: [],
    persons: [],
    districts: [],
    brand_configs: {}
  });

  // Modified useEffect for fetching functions - keep 5 second interval
  useEffect(() => {
    if (serverIp) {
      const fetchFunctions = () => {
        axios
          .get(`http://${serverIp}:5001/get_available_functions`)
          .then((response) => {
            setFunctions(response.data.functions);
            // Only set selected function on initial mount
            if (isInitialMount && response.data.functions.length > 0) {
              setSelectedFunction(response.data.functions[0]);
              setIsInitialMount(false);
            }
          })
          .catch((error) => console.error('Error fetching functions:', error));
      };

      fetchFunctions();
      const intervalId = setInterval(fetchFunctions, 5000);
      return () => clearInterval(intervalId);
    }
  }, [serverIp, isInitialMount]); // Remove selectedFunction dependency

  // New useEffect for fetching dropdown options
  useEffect(() => {
    if (serverIp) {
      axios.get(`http://${serverIp}:5001/get_dropdown_options`)
        .then((response) => {
          setDropdownOptions(response.data);
        })
        .catch((error) => console.error('Error fetching dropdown options:', error));
    }
  }, [serverIp]);

  // Modified useEffect for fetching statuses - only fetch when needed
  useEffect(() => {
    if (serverIp && 
        selectedFunction?.function_name === 'change_act_status' && 
        validStatuses.length === 0) {  // Only fetch if we don't have statuses
      axios.get(`http://${serverIp}:5001/get_valid_statuses`)
        .then((response) => {
          console.log('Fetched statuses:', response.data.valid_statuses);
          setValidStatuses(response.data.valid_statuses);
        })
        .catch((error) => console.error('Error fetching valid statuses:', error));
    }
  }, [serverIp, selectedFunction, validStatuses.length]);

  // New useEffect to update person/district when brand changes
  useEffect(() => {
    if (selectedFunction?.function_name === 'arrive' && inputData.brand && dropdownOptions.brand_configs) {
      const brandConfig = dropdownOptions.brand_configs[inputData.brand];
      if (brandConfig) {
        setInputData(prev => ({
          ...prev,
          person: brandConfig.person || prev.person,
          district: brandConfig.district || prev.district
        }));
      }
    }
  }, [inputData.brand, dropdownOptions.brand_configs, selectedFunction]);

  // Modified handleSelectFunction to clear statuses when switching away from change_act_status
  const handleSelectFunction = (func) => {
    // Clear valid statuses if switching away from change_act_status
    if (selectedFunction?.function_name === 'change_act_status' && 
        func.function_name !== 'change_act_status') {
      setValidStatuses([]);
    }
    
    setSelectedFunction(func);
    setErrors({});

    // Reset inputData
    const initialInputData = {};
    func.parameters?.forEach(param => {
      initialInputData[param.name] = param.default !== undefined ? param.default : (param.type === 'bool' ? false : '');
      
      // Set default values for dropdowns if available
      if (param.name === 'brand' && param.enum && param.enum.length > 0) {
        initialInputData[param.name] = param.enum[0];
        
        // Also set corresponding person/district based on brand
        const brandConfig = dropdownOptions.brand_configs && dropdownOptions.brand_configs[param.enum[0]];
        if (brandConfig) {
          initialInputData.person = brandConfig.person;
          initialInputData.district = brandConfig.district;
        }
      }
    });
    setInputData(initialInputData);
  };

  const handleInputChange = (paramName, value) => {
    setInputData(prev => ({ ...prev, [paramName]: value }));
    setErrors(prev => ({ ...prev, [paramName]: '' })); // Remove error message when user types
  };

  const validateInputs = (isBatchValidation = false) => {
    console.log('Validating inputs:', {
      selectedFunction,
      inputData,
      parameters: selectedFunction?.parameters,
      isBatchValidation
    });

    // If the function has no parameters, return true
    if (!selectedFunction?.parameters || selectedFunction.parameters.length === 0) {
      console.log('No parameters to validate, returning true');
      return true;
    }

    // In batch mode, we shouldn't validate inputData for the first parameter
    const parametersToValidate = isBatchValidation ? 
      selectedFunction.parameters.filter(p => p.name !== 'service_list' && p.name !== 'act_number') : 
      selectedFunction.parameters;

    const newErrors = {};
    parametersToValidate.forEach(param => {
      console.log('Validating parameter:', {
        name: param.name,
        required: param.required,
        value: inputData[param.name]
      });

      if (param.required && (inputData[param.name] === '' || inputData[param.name] === undefined)) {
        newErrors[param.name] = `${param.name} is required`;
      }
    });

    console.log('Validation errors:', newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleBatchSubmit = async () => {
    console.log('handleBatchSubmit called with:', {
      selectedFunction,
      inputData,
      batchInput
    });

    if (!selectedFunction) {
      console.error('No function selected');
      return;
    }

    // Validate inputs with batch flag
    const isValid = validateInputs(true);
    console.log('Input validation result:', isValid);
    if (!isValid) return;

    const batchValues = batchInput
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0);

    console.log('Batch values:', batchValues);

    if (batchValues.length === 0) {
      const error = 'Please enter at least one value';
      console.error(error);
      setErrors(prev => ({ ...prev, batchInput: error }));
      return;
    }

    try {
      // Special case for arrive function - send all service numbers in a single request
      if (selectedFunction.function_name === 'arrive') {
        const kwargs = { ...inputData };
        // Pass the entire array of service numbers
        kwargs.service_list = batchValues;
        
        console.log('Submitting NGEN batch job with all service numbers:', kwargs);
        await onSubmit(selectedFunction.function_name, [], kwargs);
        console.log('NGEN batch job submitted successfully');
      } else {
        // Original logic for other functions - send individual jobs
        for (const value of batchValues) {
          const kwargs = { ...inputData };
          if (selectedFunction.parameters && selectedFunction.parameters.length > 0) {
            // For change_act_status, we keep the status from inputData and only update act_number
            if (selectedFunction.function_name === 'change_act_status') {
              kwargs.act_number = value;
            } else {
              // Original logic for other functions
              const firstParam = selectedFunction.parameters[0];
              kwargs[firstParam.name] = firstParam.type === 'int' ? parseInt(value, 10) : 
                                      firstParam.type === 'float' ? parseFloat(value) :
                                      firstParam.type === 'bool' ? Boolean(value) : value;
            }
          }

          console.log('Submitting job with kwargs:', kwargs);
          await onSubmit(selectedFunction.function_name, [], kwargs);
        }
        console.log('All individual batch jobs submitted successfully');
      }

      setBatchInput('');
      setErrors({});
    } catch (error) {
      console.error('Error in handleBatchSubmit:', error);
      setErrors(prev => ({ ...prev, batchInput: 'Failed to submit batch jobs' }));
    }
  };

  const handleSubmit = () => {
    console.log('handleSubmit called');
    if (isBatchMode) {
      handleBatchSubmit();
    } else {
      if (selectedFunction && validateInputs()) {
        const args = [];
        const kwargs = {};
    
        selectedFunction.parameters.forEach(param => {
          let value = inputData[param.name];
          if (param.type === 'int') {
            value = parseInt(value, 10);
          } else if (param.type === 'float') {
            value = parseFloat(value);
          } else if (param.type === 'bool') {
            value = Boolean(value);
          }
          kwargs[param.name] = value;
        });
    
        onSubmit(selectedFunction.function_name, args, kwargs)
          .then((response) => {
            // Check if there are errors in the response
            if (response?.status?.data?.error) {
              throw new Error(response.status.data.error);
            }
            
            // Handle GSPN worker's direct result (synchronous response)
            if (response?.result) {
              setInputData(prev => ({
                ...prev,
                result: JSON.stringify(response.result, null, 2)
              }));
            }
            
            console.log('Single job submitted successfully');
          })
          .catch(error => {
            console.error('Error in handleSubmit:', error);
            const errorMessage = error.message || 
                               error.error || 
                               (error.response?.data?.error) || 
                               'Job execution failed';
            setErrors(prev => ({
              ...prev,
              submit: errorMessage
            }));
          });
      }
    }
  };

  // Helper function to render the appropriate input field based on parameter type
  const renderInputField = (param) => {
    // Special case for result display
    if (param.name === 'result') {
      return (
        <TextField
          label="Result"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={inputData[param.name] || ''}
          InputProps={{
            readOnly: true,
          }}
          sx={{ backgroundColor: '#252526', input: { color: '#fff' } }}
        />
      );
    }

    // Boolean parameter - render checkbox
    if (param.type === 'bool') {
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={inputData[param.name] || false}
              onChange={(e) => handleInputChange(param.name, e.target.checked)}
            />
          }
          label={param.description}
        />
      );
    }
    
    // Status parameter for change_act_status - render dropdown with valid statuses
    if (param.name === 'status' && selectedFunction.function_name === 'change_act_status') {
      return (
        <Select
          fullWidth
          value={inputData[param.name] || ''}
          onChange={(e) => handleInputChange(param.name, e.target.value)}
          error={!!errors[param.name]}
          sx={{ 
            backgroundColor: '#252526',
            color: '#fff',
            '& .MuiSelect-icon': { color: '#fff' }
          }}
        >
          {validStatuses.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      );
    }
    
    // Enum parameters (dropdown fields)
    if (param.enum) {
      let options = [];
      
      // Determine options based on parameter name
      if (param.name === 'brand') {
        options = dropdownOptions.brands;
      } else if (param.name === 'person') {
        options = dropdownOptions.persons;
      } else if (param.name === 'district') {
        options = dropdownOptions.districts;
      } else {
        options = param.enum; // Use the enum directly if defined
      }
      
      return (
        <Select
          fullWidth
          value={inputData[param.name] || ''}
          onChange={(e) => handleInputChange(param.name, e.target.value)}
          error={!!errors[param.name]}
          displayEmpty
          sx={{ 
            backgroundColor: '#252526',
            color: '#fff',
            '& .MuiSelect-icon': { color: '#fff' }
          }}
        >
          <MenuItem value="" disabled>
            {param.description}
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      );
    }
    
    // Default - render text field
    return (
      <TextField
        label={param.description}
        variant="outlined"
        fullWidth
        value={inputData[param.name] || ''}
        onChange={(e) => handleInputChange(param.name, e.target.value)}
        error={!!errors[param.name]}
        helperText={errors[param.name] || ''}
        sx={{ backgroundColor: '#252526', input: { color: '#fff' } }}
      />
    );
  };

  // Determine which batch input label to show
  const getBatchInputLabel = () => {
    if (selectedFunction.function_name === 'change_act_status') {
      return "Enter ACT numbers (one per line)";
    } else if (selectedFunction.function_name === 'arrive') {
      return "Enter service numbers (one per line)";
    }
    return "Enter values (one per line)";
  };

  return (
    <Box sx={{ display: 'flex', height: '750px', border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden' }}>
      
      {/* Left column - List of functions */}
      <Box sx={{ width: '30%', borderRight: '1px solid #ddd', backgroundColor: '#252526' }}>
        <Typography 
          variant="h6" 
          sx={{ padding: 2, fontWeight: 'bold', borderBottom: '2px solid #444', backgroundColor: '#1e1e1e', color: '#fff' }}
        >
          Available Functions
        </Typography>
        <List sx={{ overflowY: 'auto', height: 'calc(100% - 50px)' }}>
          {functions.map((func) => (
            <ListItem
              button
              key={func.function_name}
              onClick={() => handleSelectFunction(func)}
              selected={selectedFunction?.function_name === func.function_name}
              sx={{
                backgroundColor: selectedFunction?.function_name === func.function_name ? '#3a3a3a' : 'transparent',
                color: '#fff',
                '&:hover': { backgroundColor: '#4a4a4a' },
              }}
            >
              <ListItemText primary={func.name} secondary={func.short_description} />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Right column - Information about selected function */}
      <Box sx={{ flex: 1, backgroundColor: '#1e1e1e', color: '#fff' }}>
        <Typography 
          variant="h6" 
          sx={{ padding: 2, fontWeight: 'bold', borderBottom: '2px solid #444', backgroundColor: '#1e1e1e', color: '#fff' }}
        >
          Details
        </Typography>
        <Box sx={{ padding: 2, height: 'calc(100% - 50px)', overflowY: 'auto' }}>
          {selectedFunction && (
            <>
              <Typography variant="body2" sx={{color:"#707070"}}><strong>Name:</strong> </Typography>
              <Typography variant='h5' sx={{marginTop: 2, marginBottom: 2, color:"#e9531f"}}> {selectedFunction.name}</Typography>
              <Typography variant='body1' sx={{marginBottom: 4}}>{selectedFunction.long_description}</Typography>

              <Typography variant="subtitle2" sx={{color: "#707070"}}><strong>Last modified:</strong> {selectedFunction.last_modified}</Typography>
              <Typography variant="subtitle2" sx={{color: "#707070"}}><strong>Expected runtime:</strong> {selectedFunction.expected_runtime} sec</Typography>

              {/* Show input section only if function has parameters */}
              {selectedFunction.parameters?.length > 0 && (
                <>
                  <Typography variant="subtitle1" sx={{ marginTop: 1, color: "#bcbcbc" }}>
                    <strong>Input to function:</strong>
                  </Typography>
                  
                  {/* Show batch mode checkbox only for functions with parameters */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isBatchMode}
                        onChange={(e) => setIsBatchMode(e.target.checked)}
                      />
                    }
                    label="Batch Mode"
                    sx={{ marginTop: 2, color: '#fff' }}
                  />

                  {/* Show either batch input or normal input fields */}
                  {isBatchMode ? (
                    <>
                      {/* For batch mode, we need to keep all fields except the main parameter */}
                      {selectedFunction.parameters.filter(param => {
                        // Filter out the main parameter that will be provided in batch
                        if (selectedFunction.function_name === 'change_act_status') {
                          return param.name === 'status'; // Only keep status field
                        } 
                        else if (selectedFunction.function_name === 'arrive') {
                          return param.name !== 'service_list'; // Keep all except service_list
                        }
                        // For other functions, don't show any fields before batch input
                        return false;
                      }).map(param => (
                        <Box key={param.name} sx={{ marginBottom: 2 }}>
                          <Typography variant="body2" sx={{ marginBottom: 1, color: '#bcbcbc' }}>
                            {param.description}:
                          </Typography>
                          {renderInputField(param)}
                        </Box>
                      ))}

                      <TextField
                        label={getBatchInputLabel()}
                        multiline
                        rows={4}
                        value={batchInput}
                        onChange={(e) => setBatchInput(e.target.value)}
                        error={!!errors.batchInput}
                        helperText={errors.batchInput || ''}
                        fullWidth
                        sx={{
                          marginTop: 2,
                          backgroundColor: '#252526',
                          '& .MuiInputBase-input': { color: '#fff' },
                          '& .MuiInputLabel-root': { color: '#bcbcbc' }
                        }}
                      />
                    </>
                  ) : (
                    // Normal input fields - now with sorting
                    [...selectedFunction.parameters]
                      .sort((a, b) => {
                        // If we are in change_act_status, show status first
                        if (selectedFunction.function_name === 'change_act_status') {
                          if (a.name === 'status') return -1;
                          if (b.name === 'status') return 1;
                        }
                        // For arrive function, order: brand -> person -> district -> service_list
                        if (selectedFunction.function_name === 'arrive') {
                          const fieldOrder = { 'brand': 1, 'person': 2, 'district': 3, 'service_list': 4 };
                          return (fieldOrder[a.name] || 99) - (fieldOrder[b.name] || 99);
                        }
                        return 0;
                      })
                      .map(param => (
                        <Box key={param.name} sx={{ marginBottom: 2 }}>
                          <Typography variant="body2" sx={{ marginBottom: 1, color: '#bcbcbc' }}>
                            {param.description}:
                          </Typography>
                          {renderInputField(param)}
                        </Box>
                      ))
                  )}
                </>
              )}
              
              {/* Display result for GSPN functions if available */}
              {selectedFunction.worker_type === 'gspn' && inputData.result && (
                <Box sx={{ marginTop: 3, padding: 2, backgroundColor: '#252526', borderRadius: '4px' }}>
                  <Typography variant="subtitle1" sx={{ color: "#bcbcbc", marginBottom: 1 }}>
                    <strong>Result:</strong>
                  </Typography>
                  <pre style={{ whiteSpace: 'pre-wrap', color: '#4CAF50' }}>
                    {inputData.result}
                  </pre>
                </Box>
              )}
              
              <br />
              {errors.submit && (
                <Typography 
                  color="error" 
                  sx={{ 
                    marginTop: 2, 
                    padding: 1, 
                    backgroundColor: 'rgba(211, 47, 47, 0.1)', 
                    borderRadius: 1 
                  }}
                >
                  {errors.submit}
                </Typography>
              )}
              <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ marginTop: 2 }}>
                {isBatchMode ? 'Run Batch' : 'Run Function'}
              </Button>
            </>
          )}
        </Box>
      </Box>

    </Box>
  );
};

export default FunctionViewer;
