// src/components/Dashboard.js
import React, { useRef, useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { Card, Typography, Box, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import WidgetPanel from './_dashboard/WidgetPanel';
import DigitalWatch from './_dashboard/_widgets/DigitalWatch';
import AnalogWatch from './_dashboard/_widgets/AnalogWatch';
import ServiceQueueWidget from './_dashboard/_widgets/ServiceQueueWidget';
import DailyProgressWidget from "./_dashboard/_widgets/DailyProgressWidget";
import ProductCategoryWidget from "./_dashboard/_widgets/ProductCategoryWidget";
import UserInfoWidget from "./_dashboard/_widgets/UserInfoWidget";
import DataSourceWidget from './_dashboard/_widgets/DataSourceWidget';
import ScreenshotWidget from './_dashboard/_widgets/ScreenshotWidget';

const widgets = [
    { id: 1, name: 'Digital Watch', component: DigitalWatch },
    { id: 2, name: 'Analog Watch', component: AnalogWatch },
    { id: 3, name: 'Service Queue', component: ServiceQueueWidget },
    { id: 4, name: "Daily Progress", component: DailyProgressWidget },
    { id: 5, name: "Apple Product Categories", component: () => <ProductCategoryWidget brand="Apple" /> },
    { id: 6, name: "Samsung Product Categories", component: () => <ProductCategoryWidget brand="Samsung" /> },
    { id: 7, name: "Data Source", component: DataSourceWidget },
    { id: 8, name: "User Info", component: UserInfoWidget },
    { id: 9, name: "System Screenshot", component: ScreenshotWidget }
];

const nodeRefs = widgets.reduce((acc, widget) => {
    acc[widget.id] = React.createRef();
    return acc;
}, {});

const Dashboard = () => {
    const widgetPanelRef = useRef(null);
    const [zIndexes, setZIndexes] = useState({});
    const [topZIndex, setTopZIndex] = useState(1);
    const [positions, setPositions] = useState({});
    const [loading, setLoading] = useState(true);
    const [isWidgetPanelOpen, setIsWidgetPanelOpen] = useState(false);
    const [activeWidgets, setActiveWidgets] = useState([]);

    const bringToFront = (widgetId) => {
        setZIndexes((prevZIndexes) => ({
            ...prevZIndexes,
            [widgetId]: topZIndex + 1
        }));
        setTopZIndex(topZIndex + 1);
    };

    const getCookie = (name) => {
        return document.cookie
            .split('; ')
            .find(row => row.startsWith(`${name}=`))
            ?.split('=')[1] || null;
    };

	const saveDashboardConfig = async () => {
		const token = getCookie('token');
		if (!token) {
			console.error("Token not found in cookies.");
			return;
		}
	
		const apiUrl = `${process.env.REACT_APP_STATUS_API_URL}/config/save`;
	
		// Konverter positions objekt til en array av posisjonsobjekter sortert etter widget ID
		const positionsArray = Object.keys(positions).map(key => ({
			id: parseInt(key, 10),
			...positions[key]
		}));
	
		const payload = {
			dashboard_config: {
				positions: positionsArray,
				activeWidgets
			}
		};
		
		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${token}`
				},
				body: JSON.stringify(payload)
			});
	
			if (!response.ok) {
				throw new Error(`Failed to save dashboard config, status: ${response.status}`);
			}
			console.log("Dashboard configuration saved successfully.");
		} catch (error) {
			console.error("Failed to save dashboard config:", error);
		}
	};


    const loadDashboardConfig = async () => {
    const token = getCookie('token');
    if (!token) {
        console.error("Token not found in cookies.");
        return;
    }

    const apiUrl = `${process.env.REACT_APP_STATUS_API_URL}/config/load`;

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error(`Failed to load configuration, status: ${response.status}`);
        }

        const result = await response.json();

        if (result.dashboard_config) {
            // Konverter positions fra array til objekt
            const positionsObject = result.dashboard_config.positions.reduce((acc, pos) => {
                acc[pos.id] = { x: pos.x, y: pos.y };
                return acc;
            }, {});

            setPositions(positionsObject);
            setActiveWidgets(result.dashboard_config.activeWidgets || []);
        } else {
            console.error("No configuration data found.");
        }
    } catch (error) {
        console.error("Error loading dashboard configuration:", error);
    } finally {
        setLoading(false);
    }
};


    const handleToggleWidget = (widget, isChecked) => {
        setActiveWidgets((prevWidgets) => {
            const updatedWidgets = isChecked
                ? [...prevWidgets, widget]
                : prevWidgets.filter((w) => w.id !== widget.id);
            saveDashboardConfig();
            return updatedWidgets;
        });
    };

    useEffect(() => {
        loadDashboardConfig();
    }, []);

    useEffect(() => {
        if (!loading) {
            saveDashboardConfig();
        }
    }, [activeWidgets, positions]);

    const handleDragStop = (widgetId, e, data) => {
        setPositions((prevPositions) => ({
            ...prevPositions,
            [widgetId]: { x: data.x, y: data.y }
        }));
    };

    const toggleWidgetPanel = () => {
        setIsWidgetPanelOpen((prev) => !prev);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (widgetPanelRef.current && !widgetPanelRef.current.contains(event.target)) {
                setIsWidgetPanelOpen(false);
            }
        };

        if (isWidgetPanelOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isWidgetPanelOpen]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100vh',
                position: 'relative',
                backgroundImage:
                    'linear-gradient(0deg, transparent 19px, #2b2b2b 20px), linear-gradient(90deg, transparent 19px, #2b2b2b 20px)',
                backgroundSize: '20px 20px',
            }}
        >
            <Typography variant="h3" gutterBottom>
                Dashboard
            </Typography>

            <IconButton
                onClick={toggleWidgetPanel}
                sx={{
                    position: 'absolute',
                    top: '20px',
                    right: isWidgetPanelOpen ? '320px' : '0px',
                    transition: 'right 0.3s ease',
                    backgroundColor: '#333',
                    color: '#fff',
                    borderRadius: '0 5px 5px 0',
                    width: '40px',
                    height: '40px',
                    boxShadow: '0px 2px 10px rgba(0,0,0,0.2)',
                    '&:hover': {
                        backgroundColor: '#444',
                    },
                }}
            >
                {isWidgetPanelOpen ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>

            <WidgetPanel
                ref={widgetPanelRef}
                isOpen={isWidgetPanelOpen}
                onToggleWidget={handleToggleWidget}
                activeWidgets={activeWidgets}
            />

            <Box sx={{ position: 'relative', top: 50, left: 50 }}>
                {!loading && (
                    <>
                        {activeWidgets.map((widget) => {
                            const WidgetComponent = widgets.find(w => w.id === widget.id)?.component;
                            if (!WidgetComponent) return null;

                            const widgetId = widget.id;
                            const position = positions[widgetId] || { x: 0, y: 0 };

                            return (
                                <Draggable
                                    key={widgetId}
                                    nodeRef={nodeRefs[widgetId]}
                                    grid={[20, 20]}
                                    onStop={(e, data) => handleDragStop(widgetId, e, data)}
                                    onStart={() => bringToFront(widgetId)}
                                    position={position}
                                >
                                    <div ref={nodeRefs[widgetId]} style={{ position: 'absolute', zIndex: zIndexes[widgetId] || 1 }}>
                                        <Card sx={{ padding: 2, bgcolor: '#333', color: '#fff' }}>
                                            <WidgetComponent />
                                        </Card>
                                    </div>
                                </Draggable>
                            );
                        })}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default Dashboard;
