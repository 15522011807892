import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Paper, Box, Chip, IconButton, TextField } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

// Hjelpefunksjon for å returnere farge for rolle
const getRoleColor = (role) => {
  switch(String(role).toLowerCase()){
    case 'superadmin': return '#ff0000';
    case 'admin': return '#ffa500';
    case 'price-admin': return '#ffff00';
    case 'technician': return '#00cc00';
    default: return '#ccc';
  }
};

// Legg til en hjelpefunksjon for å tilordne rangering til roller
const getRoleRank = (role) => {
  const lower = String(role).toLowerCase();
  if (lower === 'superadmin') return 0;
  if (lower === 'admin') return 1;
  if (lower === 'price-admin') return 2;
  return 3;
};

const UserList = ({ users, onSelectUser, onRefresh, selectedUsername }) => {
  // Keep a reference to the previously received users to detect changes
  const [prevUsers, setPrevUsers] = useState([]);
  const [listUsers, setListUsers] = useState(users || []);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Update local state when props change
  useEffect(() => {
    if (users && JSON.stringify(users) !== JSON.stringify(prevUsers)) {
      setListUsers(users);
      setPrevUsers(users);
    }
  }, [users]);

  // Filtrer brukere basert på søketekst
  const filteredUsers = listUsers.filter(user => {
    const term = searchTerm.toLowerCase();
    return (
      user.username.toLowerCase().includes(term) ||
      (user.fornavn && user.fornavn.toLowerCase().includes(term)) ||
      (user.etternavn && user.etternavn.toLowerCase().includes(term)) ||
      (user.role && user.role.toLowerCase().includes(term))
    );
  });

  // Før rendering, sorter de filtrerte brukerne basert på rolle
  const sortedUsers = [...filteredUsers].sort(
    (a, b) => getRoleRank(a.role) - getRoleRank(b.role)
  );

  // Helper function to format freeze status
  const formatFreezeStatus = (freezeStatus) => {
    if (freezeStatus === true || freezeStatus === 'true' || freezeStatus === 1) {
      return true;
    }
    return false;
  };

  return (
    <Paper sx={{ maxHeight: '100%', overflow: 'auto', padding: 1, position: 'relative' }}>
      {/* Søkefelt */}
      <TextField
        placeholder="Search (e.g. by role or username)"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        fullWidth
        variant="outlined"
        size="small"
        sx={{ mb: 1, bgcolor: '#1e1e1e', input: { color: '#fff' } }}
      />
      <List>
        {sortedUsers.map((user) => {
          // Process the freeze status
          const isFrozen = formatFreezeStatus(user.freeze);
          
          return (
            <ListItem
              key={user.username}
              button
              onClick={() => onSelectUser(user)}
              sx={{
                backgroundColor: user.username === selectedUsername ? '#565656' : '#2e2e2e',
                marginBottom: '10px',
                borderRadius: '8px',
                padding: '16px',
                '&:hover': {
                  backgroundColor: user.username === selectedUsername ? '#505050' : '#414141',
                },
                borderLeft: `4px solid ${getRoleColor(user.role)}`,
              }}
            >
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <span>{user.username}</span>
                      <Chip 
                        label={user.role || 'No Role'} 
                        size="small" 
                        sx={{ 
                          ml: 1,
                          bgcolor: getRoleColor(user.role),
                          color: '#fff',
                          fontSize: '0.7rem',
                          height: '20px'
                        }}
                      />
                    </Box>
                    {isFrozen && (
                      // Updated: Using specific color #79c0ff for FROZEN chip
                      <Chip 
                        label="FROZEN" 
                        size="small" 
                        sx={{ 
                          bgcolor: '#79c0ff',  // Specific blue color for frozen indicator
                          color: '#000',       // Black text for better contrast on light blue
                          fontSize: '0.7rem',
                          height: '20px',
                          fontWeight: 'bold'
                        }}
                      />
                    )}
                  </Box>
                }
                secondary={
                  <Box sx={{ mt: 0.5 }}>
                    <span>{`${user.fornavn || ''} ${user.etternavn || ''}`}</span>
                    <Box sx={{ display: 'flex', mt: 0.5, fontSize: '0.75rem', color: '#bbb' }}>
                      <span>{`Lock Count: ${user.lock_count || '0'}`}</span>
                      <Box component="span" sx={{ mx: 1 }}>•</Box>
                      <span>{`Freeze: ${isFrozen ? 'Yes' : 'No'}`}</span>
                    </Box>
                  </Box>
                }
                primaryTypographyProps={{ fontWeight: 'bold' }}
              />
            </ListItem>
          );
        })}
        
        {/* Show message when list is empty */}
        {(!listUsers || listUsers.length === 0) && (
          <Box sx={{ 
            textAlign: 'center', 
            py: 4, 
            color: '#999',
            fontStyle: 'italic'
          }}>
            No users in this list
          </Box>
        )}
      </List>
    </Paper>
  );
};

export default UserList;
