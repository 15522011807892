import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from './AuthContext';
import TabView from '../components/shared/TabView';
import { Box, TextField, Button, CircularProgress } from '@mui/material';
import ActivityList from './_activities/ActivityList';
import ActivitySelectionDialog from './_activities/ActivitySelectionModal';
import ActivityEditor from './_activities/ActivityEditor';
import Cookies from 'js-cookie';
import BomListManager from './_samsung/BomListManager';
import Queues from './Queues';
import { useActivity } from "./_activities/ActivityContext";

function Activities() {
  const { userRole } = useContext(AuthContext);
  const { selectedActivity, setSelectedActivity } = useActivity();
  const [loading, setLoading] = useState(false);
  const [activityNumber, setActivityNumber] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [imeiNumber, setImeiNumber] = useState('');
  const [customerReference, setCustomerReference] = useState('');
  const [activityData, setActivityData] = useState([]);  // Lagre liste over aktiviteter
  const [multipleResults, setMultipleResults] = useState([]);
  const [showDialog, setShowDialog] = useState(false);

  // Ny state for brands
  const [brands, setBrands] = useState([]);

  // New ref to prevent duplicate searches
  const lastSearchedActivityRef = useRef(null);

  const [activeTab, setActiveTab] = useState(0); // New state for active tab

  useEffect(() => {
    if (selectedActivity) {
      const actNumber = selectedActivity.orderData?.activityNumber || selectedActivity;
      if (actNumber && actNumber !== lastSearchedActivityRef.current) {
        lastSearchedActivityRef.current = actNumber;
        console.log("Opening activity:", actNumber); // New log statement
        setActivityNumber(actNumber);
        searchForDevice(actNumber);
        setActiveTab(0); // Switch to the first (HANDLING) tab when an activity is selected
      }
    }
  }, [selectedActivity]);

  useEffect(() => {
    // Hent brands fra cookies og dekode dem
    const storedBrands = Cookies.get('brands');
    if (storedBrands) {
      try {
        const decodedBrands = JSON.parse(decodeURIComponent(storedBrands)); // Dekoder cookien
        setBrands(decodedBrands);
      } catch (error) {
        console.error("Failed to decode brands cookie:", error);
      }
    }
  }, []);

  useEffect(() => {
    console.log('Brands state:', brands);
  }, [brands]);



  const searchForDevice = async (actNumber) => {
    setLoading(true);
    const apiUrl = process.env.REACT_APP_STATUS_API_URL;
    let searchParam = actNumber ? `act_number=${actNumber}` : '';
    
    const url = `${apiUrl}/data?${searchParam}`;
    const token = Cookies.get('token');
    
    if (!token) {
      alert('Du må være innlogget for å søke etter en serviceordre.');
      setLoading(false);
      return;
    }
    
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
    
      if (response.status === 401) {
        alert('Token utløpt eller ugyldig. Vennligst logg inn på nytt.');
        setLoading(false);
        return;
      }
    
      if (response.ok) {
        const jsonResponse = await response.json();
        // Handle the new data format where activities are inside data array
        const activities = jsonResponse.data || [];
        
        if (activities.length > 0) {
          // If multiple cases are returned, select the one matching actNumber
          const matched = activities.find(item => 
            String(item.orderData?.activityNumber) === String(actNumber)
          );
          const finalData = matched || activities[0];
          
          if (String(finalData.orderData?.activityNumber) !== String(actNumber)) {
            console.warn("Returned activity number", finalData.orderData?.activityNumber, "does not match requested", actNumber);
            setLoading(false);
            return;
          }
          
          const exists = activityData.some(
            activity => activity.orderData?.activityNumber === finalData.orderData?.activityNumber
          );
    
          if (!exists) {
            setActivityData(prevData => [finalData, ...prevData]);
            setSelectedActivity(finalData);
          } else {
            setSelectedActivity(finalData);
          }
        } else {
          alert('Ingen saker funnet for det oppgitte søket.');
        }
      } else {
        alert(`Feil ved forespørsel: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Feil ved søk:', error);
    } finally {
      setLoading(false);
      // Clear search fields
      setActivityNumber('');
      setSerialNumber('');
      setImeiNumber('');
      setCustomerReference('');
    }
  };
  
  const handleSelectActivity = (activity) => {
    setSelectedActivity(activity);  // Nå bruker ActivityContext
    setShowDialog(false);
    setActivityNumber('');
    setSerialNumber('');
    setImeiNumber('');
    setCustomerReference('');
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;

    switch (field) {
      case 'activityNumber':
        setActivityNumber(value);
        setSerialNumber('');
        setImeiNumber('');
        setCustomerReference('');
        break;
      case 'serialNumber':
        setSerialNumber(value);
        setActivityNumber('');
        setImeiNumber('');
        setCustomerReference('');
        break;
      case 'imeiNumber':
        setImeiNumber(value);
        setActivityNumber('');
        setSerialNumber('');
        setCustomerReference('');
        break;
      case 'customerReference':
        setCustomerReference(value);
        setActivityNumber('');
        setSerialNumber('');
        setImeiNumber('');
        break;
      default:
        break;
    }
  };

  // Funksjon for å lagre endringer til backend
  const handleSave = async (newData) => {
    console.log("Lagrer ny verdi:", newData);
  
    const apiUrl = process.env.REACT_APP_STATUS_API_URL;
    const updateUrl = `${apiUrl}/update_case`;  // Oppdatering av saken
    const token = Cookies.get('token');
  
    if (!token) {
      alert('Du må være innlogget for å oppdatere en sak.');
      return;
    }
  
    // Bygg strukturen for dataene i samsvar med det riktige formatet
    const payload = {
      guid: newData.guid,  // Unik identifikator for saken
      orderData: {
        activityNumber: newData.activityNumber,
        consents: newData.consents || {},
        providerSpecific: newData.providerSpecific || {},
        refNo: newData.refNo,
        shipmentData: newData.shipmentData || {},
        bookingType: newData.bookingType,
        serviceProviderExportStatus: newData.serviceProviderExportStatus,
        clientPostalCode: newData.clientPostalCode,
      },
      productData: {
        imei: newData.productImei,
        model: newData.productModel,
        serial: newData.productSerial,
        problemText: newData.problemText,
        purchaseDate: newData.purchaseDate,
      },
      customer: {
        name: newData.customerName,
        address: newData.customerAddress,
        postalCode: newData.customerPostalCode,
        city: newData.customerCity,
        email: newData.customerEmail,
        phone: newData.customerPhone,
      },
      tags: newData.tags || [],  // Pass på at tags sendes som en liste
    };
  
    try {
      const response = await fetch(updateUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),  // Send formaterte data til backend
      });
  
      if (response.ok) {
        const result = await response.json();
        alert('Saken er oppdatert!');
        console.log('Update result:', result);
      } else {
        alert(`Feil ved oppdatering: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Feil ved oppdatering:', error);
    }
  };
  
  const spaceForEditor = (
    <Box sx={{ height: '300px', backgroundColor: '#636363', marginBottom: '5px' }} />
  );

  const activitySmallTabLabels = ['Activity', 'Comments', 'Log', 'AR', 'Order'];

  const activitySmallTabContents = [
    loading ? (
      <CircularProgress />
    ) : selectedActivity ? (
      <div>
        <ActivityEditor 
           key={selectedActivity.guid || 'new-activity'} // Remount editor when selectedActivity changes
           initialData={selectedActivity} 
           onSubmit={handleSave} 
        />
      </div>
    ) : (
      <div />
    ),
    <div>{spaceForEditor}</div>,
    <div>{spaceForEditor}</div>,
    <div>{spaceForEditor}</div>,
    <div>{spaceForEditor}</div>
  ];

  // Filtrer aktiviteter basert på status
  const filterActivities = (status) => {
    return activityData.filter(activity => activity.currentStatus === status);
  };

  const queuesSmallTabLabels = ['Not started', 'Open', 'Waiting', 'Completed'];
  const queuesSmallTabContents = [
    <Box key="queues" sx={{ 
      width: "100%",
      maxWidth: "100%",
      marginLeft: 0,
      padding: "0 0px",
      overflow: 'hidden', // Fjern scrollbar
      "& > div": {
        width: "100%!important",
        marginLeft: "0!important",
        justifyContent: "flex-start"
      }
    }}>
      <Queues endpoint='/activity/assigned' />
    </Box>,

    
    <Box key="open-queues" sx={{ 
      width: "100%",
      maxWidth: "100%",
      marginLeft: 0,
      padding: "0 0px",
      overflow: 'hidden', // Fjern scrollbar
      "& > div": {
        width: "100%!important",
        marginLeft: "0!important",
        justifyContent: "flex-start"
      }
    }}>
      <Queues endpoint='/activity/open-cases' />
    </Box>,

    <Box key="waiting-queues" sx={{
      width: "100%",
      maxWidth: "100%",
      marginLeft: 0,
      padding: "0 0px",
      overflow: 'hidden', // Fjern scrollbar
      "& > div": {
        width: "100%!important",
        marginLeft: "0!important",
        justifyContent: "flex-start"
      }
    }}>
      <Queues endpoint='/activity/waiting' />
    </Box>,

  ];

  const queuesSmallTabContentsOld = [
    <ActivityList activities={filterActivities('Not started')} />,
    <ActivityList activities={filterActivities('Open')} />,

    
    <ActivityList activities={filterActivities('Waiting')} />,
    <ActivityList activities={filterActivities('Completed')} />
  ];
  

  const samsungSmallTabLabels = ['Create SO', 'Repairs', 'BOM List'];
  const samsungSmallTabContents = [
    <div>Innhold for Sub-tab 1</div>,
    <div>Innhold for Sub-tab 2</div>,
    <div>
      <BomListManager />
    </div>,
  ];

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchForDevice(activityNumber);
    }
  };

  const openAct = (
    <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
      <TextField
        id="actNumber-search"
        label="Act Number"
        variant="outlined"
        fullWidth
        value={activityNumber}
        onChange={(e) => handleInputChange(e, 'activityNumber')}
        onKeyPress={handleKeyPress}
        sx={{ input: { color: '#fff' }, label: { color: '#c5c5c5' } }}
      />
      <TextField
        label="Serial Number"
        variant="outlined"
        fullWidth
        value={serialNumber}
        onChange={(e) => handleInputChange(e, 'serialNumber')}
        onKeyPress={handleKeyPress}
        sx={{ input: { color: '#fff' }, label: { color: '#c5c5c5' } }}
      />
      <TextField
        label="IMEI Number"
        variant="outlined"
        fullWidth
        value={imeiNumber}
        onChange={(e) => handleInputChange(e, 'imeiNumber')}
        onKeyPress={handleKeyPress}
        sx={{ input: { color: '#fff' }, label: { color: '#c5c5c5' } }}
      />
      <TextField
        label="Customer Reference"
        variant="outlined"
        fullWidth
        value={customerReference}
        onChange={(e) => handleInputChange(e, 'customerReference')}
        onKeyPress={handleKeyPress}
        sx={{ input: { color: '#fff' }, label: { color: '#c5c5c5' } }}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ height: '56px' }}
        onClick={() => searchForDevice(activityNumber)}
      >
        Search
      </Button>
    </Box>
  );

  const tabLabels = ['HANDLING', 'QUEUES'];
  const tabContents = [
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {openAct}
      <TabView
        tabLabels={activitySmallTabLabels}
        tabContents={activitySmallTabContents}
        variant="small"
        customSmallDimensions={{
          minHeight: '0',
          maxHeight: 'none',
          flexGrow: 1,
        }}
      />

      Recently opened activities:
      <ActivityList
        activities={activityData}
        onSelectActivity={handleSelectActivity}
        searchForDevice={searchForDevice}
        setActivityData={setActivityData}
      />
    </div>,
    <div style={{ height: '100%' }}>
      <TabView tabLabels={queuesSmallTabLabels} tabContents={queuesSmallTabContents} variant="small" />
    </div>
  ];

  if (brands.includes('apple')) {
    tabLabels.push('APPLE');
    tabContents.push(<div>Apple Cases</div>);
  }

  if (brands.includes('samsung')) {
    tabLabels.push('SAMSUNG');
    tabContents.push(<div>
      <TabView tabLabels={samsungSmallTabLabels} tabContents={samsungSmallTabContents} variant="small" />
    </div>);
  }

  return (
    <div>
      <h1>Activities</h1>
      <TabView 
        tabLabels={tabLabels} 
        tabContents={tabContents}
        activeTab={activeTab} // Pass activeTab prop to TabView
        
      />

      {showDialog && (
        <ActivitySelectionDialog
          activities={multipleResults}
          onSelectActivity={handleSelectActivity}
          onClose={() => setShowDialog(false)}
        />
      )}
    </div>
  );
}

export default Activities;
