import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';  // Importer js-cookie

// Funksjon for å sjekke om brukeren er frosset
const checkIfUserIsFrozen = async () => {
  const apiUrl = process.env.REACT_APP_STATUS_API_URL;
  const token = Cookies.get('token');  // Hent JWT-token fra cookies

  if (!token) {
    console.error('No token found.');
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    const username = decodedToken.sub;  // Hent 'sub' fra tokenet som brukernavn

    const response = await fetch(`${apiUrl}/check_freeze`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,  // Send JWT-token i headeren
      },
      body: JSON.stringify({ username }),  // Send brukernavn i body
    });

    if (response.ok) {
      const data = await response.json();
      return data.frozen === 'true';  // Returner true hvis brukeren er frosset
    } else {
      const errorText = await response.text();
      console.error('Somthing went wrong with the response from the server:', response.status, errorText);
      return false;
    }
  } catch (error) {
    console.error('Error while checking freeze status:', error);
    return false;
  }
};

// Opprett en AuthContext
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const [brands, setBrands] = useState([]);  // Legg til brands som en state
  const [functions, setFunctions] = useState([]); // Ny state for funksjoner

  // Funksjon for å håndtere innlogging
  const login = async (username, password) => {
    try {
      const apiUrl = process.env.REACT_APP_STATUS_API_URL;

      const response = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();

        // Hvis serveren svarer med en spesifikk feilmelding, sjekk for det
        if (data.error === 'Account is frozen') {
          console.warn('Login cancelled: Account is frozen by admin.');
          return { success: false, message: 'Account is frozen by admin' };
        }

        const decodedToken = jwtDecode(data.access_token); // Dekod token for å hente utløpstid
        const expirationDate = new Date(decodedToken.exp * 1000); // Konverter til millisekunder

        // Lagre token, rolle, og brands i cookies med utløpstid
        //Cookies.set('token', data.access_token, { expires: expirationDate });
        //Cookies.set('role', data.role, { expires: expirationDate });
        ////Cookies.set('brands', data.brands, { expires: expirationDate });  // Lagre brands
        //Cookies.set('brands', JSON.stringify(data.brands), { expires: expirationDate });  // Sørg for at det settes som en JSON-struktur

        // Bruk secure flag hvis vi er i produksjon
        const isProduction = process.env.REACT_APP_STATUS_API_URL === 'https://scripthub.serviceorderhub.com/dashboardapi';

        // Sett token, role og brands som JSON-struktur med sikkerhetsinnstillinger for produksjon
        Cookies.set('token', data.access_token, { 
          expires: expirationDate, 
          secure: isProduction,  // Kun sikre cookies i produksjon
          sameSite: 'Strict'     // Ekstra sikkerhet for å forhindre cross-site requests
        });

        Cookies.set('role', data.role, { 
          expires: expirationDate, 
          secure: isProduction,  // Kun sikre cookies i produksjon
          sameSite: 'Strict'
        });

        Cookies.set('brands', JSON.stringify(data.brands), { 
          expires: expirationDate, 
          secure: isProduction,  // Kun sikre cookies i produksjon
          sameSite: 'Strict'
        });

        // Force small sidebar for certain roles - improve to apply immediately
        if (data.role === 'repair-cost' || data.role === 'price-admin') {
          console.log(`Setting small sidebar for role: ${data.role}`);
          localStorage.setItem('useSmallSidebar', 'true');
          
          // The useRoleSpecificSettings hook will detect this role change and apply settings
        }

        // Hent brukerdata fra API-et
        const userResponse = await fetch(`${apiUrl}/user?username=${username}&token=${data.access_token}`);
        const userData = await userResponse.json();

        // Konverter functions-verdien til et array hvis det er en streng
        let userFunctions = [];
        if (userData.functions && typeof userData.functions === "string") {
          userFunctions = userData.functions.split(',').map(func => func.trim());
        }

        // Set states in correct order to trigger our useRoleSpecificSettings hook
        setBrands(data.brands || []);
        setFunctions(userFunctions);
        setRole(data.role);  // This will trigger the useRoleSpecificSettings hook
        setIsAuthenticated(true);
        
        console.log("User functions set:", userFunctions); // Debug logging
        
        return { success: true };
      } else {
        const errorData = await response.json();
        console.error('Login failed:', errorData.error);
        return { success: false, message: errorData.error || 'Wrong username or password.' };
      }
    } catch (error) {
      console.error('Login failed:', error);
      return { success: false, message: 'Could not login. Please try again later.' };
    }
  };

  // Funksjon for å håndtere utlogging
  const logout = () => {
    Cookies.remove('token');  // Fjern token fra cookies
    Cookies.remove('role');   // Fjern rollen fra cookies
    Cookies.remove('brands'); // Fjern brands fra cookies
    setIsAuthenticated(false);  // Nullstill autentiseringstilstanden
    setRole(null);  // Nullstill rollen
    setBrands([]);  // Nullstill brands
    setFunctions([]); // Nullstill functions ved utlogging
  };

  // Funksjon for å sjekke om token er utløpt
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;  // Nåværende tid i sekunder
      return decodedToken.exp < currentTime;  // Returner true hvis tokenet er utløpt
    } catch (error) {
      console.error('Error while decoding the token.:', error);
      return true;  // Hvis det er feil, anta at tokenet er utløpt
    }
  };

  // Sjekk token, role og brands ved første innlasting av appen
  useEffect(() => {
    const verifyTokenAndFreezeStatus = async () => {
      const token = Cookies.get('token');
      const role = Cookies.get('role');  // Hent role fra cookies
      const brandsFromCookie = Cookies.get('brands');  // Hent brands fra cookies

      if (token && role && brandsFromCookie) {
        // Force small sidebar for certain roles - add this here as well
        if (role === 'repair-cost' || role === 'price-admin') {
          console.log(`Setting small sidebar for role: ${role} during app startup`);
          localStorage.setItem('useSmallSidebar', 'true');
        }

        if (isTokenExpired(token)) {
          console.log('The token has expired');
          logout();  // Logg ut hvis tokenet er utløpt
        } else {
          const isFrozen = await checkIfUserIsFrozen();  // Sjekk om brukeren er frosset
          if (isFrozen) {
            console.log('Use account has been frozen');
            logout();
          } else {
            // Hent brukerdata for å få functions
            try {
              const apiUrl = process.env.REACT_APP_STATUS_API_URL;
              const decodedToken = jwtDecode(token);
              const username = decodedToken.sub;
              
              const userResponse = await fetch(`${apiUrl}/user?username=${username}&token=${token}`);
              const userData = await userResponse.json();
              
              let userFunctions = [];
              if (userData.functions && typeof userData.functions === "string") {
                userFunctions = userData.functions.split(',').map(func => func.trim());
              }
              
              setIsAuthenticated(true);
              setRole(role);
              setBrands(JSON.parse(brandsFromCookie));
              setFunctions(userFunctions); // Sett functions fra API-et
              
              console.log("User functions loaded:", userFunctions); // Debug logging
            } catch (error) {
              console.error('Error fetching user functions:', error);
              setIsAuthenticated(true);
              setRole(role);
              setBrands(JSON.parse(brandsFromCookie));
            }
          }
        }
      } else {
        logout();  // Hvis token, role eller brands mangler, logg ut brukeren
      }
    };

    verifyTokenAndFreezeStatus();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, role, brands, functions, login, logout, setIsAuthenticated, setRole, setBrands, setFunctions }}>
      {children}
    </AuthContext.Provider>
  );
};
