import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Box, Typography } from "@mui/material";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF", "#FF4560", "#26A69A", "#D7263D", "#A2D5F2", "#077B8A"];

const ProductCategoryWidget = ({ brand }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [detailedData, setDetailedData] = useState({}); // Ny state for detaljert data

    useEffect(() => {
        fetchOpenCases();
    }, []);

    const fetchOpenCases = async () => {
        try {
            const token = Cookies.get("token");
            if (!token) throw new Error("No token found");
    
            const decodedToken = jwtDecode(token);
            const username = decodedToken.sub;
    
            const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/activity/open-cases`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ username, token })
            });
    
            if (!response.ok) throw new Error("Failed to fetch open cases");
            const result = await response.json();
            processData(result);
        } catch (error) {
            console.error(`Error in ${brand} widget:`, error);
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    const categorizeProduct = (prodId) => {
        if (!prodId) return "Unknown";

        if (brand.toLowerCase() === "apple") {
            const prodIdLower = prodId.toLowerCase();
            if (prodIdLower.includes("iphone")) return "iPhone";
            if (prodIdLower.includes("ipad")) return "iPad";
            if (prodIdLower.includes("airpods")) return "AirPods";
            if (prodIdLower.includes("beats")) return "Beats";
            if (prodIdLower.includes("macbook")) return "MacBook";
            if (prodIdLower.includes("imac")) return "iMac";
            if (prodIdLower.includes("watch")) return "Apple Watch";
            if (prodIdLower.includes("tv")) return "Apple TV";
            return "Other Apple Products";
        }

        if (brand.toLowerCase() === "samsung") {
            const prodIdUpper = prodId.toUpperCase();
            if (prodIdUpper.startsWith('SM-')) {
                const series = prodIdUpper.substring(3, 4);
                return {
                    'A': 'Galaxy A-series',
                    'G': 'Galaxy S-series',
                    'S': 'Galaxy S-series', // Lagt til SM-S som Galaxy S-serie
                    'N': 'Galaxy Note',
                    'F': 'Galaxy Fold',
                    'Z': 'Galaxy Flip',
                    'T': 'Galaxy Tab',
                    'X': 'Galaxy Tab',  // Lagt til SM-X som Galaxy Tab
                    'R': 'Galaxy Watch',
                    'M': 'Galaxy M-series',
                }[series] || 'Other Samsung Models';  // For SM- som vi ikke kjenner serien til
            }
            return "Unknown";  // For Samsung-produkter som ikke starter med SM-
        }

        return "Unknown Brand";
    };

    const processData = (cases) => {
        if (!Array.isArray(cases)) {
            console.error("Invalid cases data:", cases);
            setData([]);
            return;
        }

        const categoryCount = {};
        const details = {}; // For å lagre detaljert informasjon

        cases.forEach((item) => {
            if (item.Brand?.toLowerCase() === brand.toLowerCase()) {
                const category = categorizeProduct(item.ProdId);
                categoryCount[category] = (categoryCount[category] || 0) + 1;
                
                // Lagre detaljert informasjon
                if (!details[category]) {
                    details[category] = new Set();
                }
                details[category].add(item.ProdId);
            }
        });

        setDetailedData(details);
        
        const sortedData = Object.entries(categoryCount)
            .sort((a, b) => b[1] - a[1]) // Sorter etter antall (mest populære først)
            .map(([key, value]) => ({ name: key, value }));

        setData(sortedData);
    };

    const CustomTooltip = ({ active, payload }) => {
        if (!active || !payload || !payload[0]) return null;
        
        const category = payload[0].name;
        const models = Array.from(detailedData[category] || []).sort();
        
        return (
            <Box sx={{
                backgroundColor: 'rgba(30, 30, 30, 0.95)', // Updated to match #1e1e1e with opacity
                border: '1px solid #666',
                borderRadius: '4px',
                padding: '10px',
                maxHeight: '300px',
                overflowY: 'auto'
            }}>
                <Typography sx={{ fontWeight: 'bold', color: '#fff', mb: 1 }}>
                    {category}: {payload[0].value} units
                </Typography>
                {models.map((model, idx) => (
                    <Typography key={idx} sx={{ color: '#fff', fontSize: '0.9rem' }}>
                        • {model}
                    </Typography>
                ))}
            </Box>
        );
    };

    if (loading) {
        return (
            <div style={{ width: "600px", height: "330px", backgroundColor: "#292929", padding: "20px", borderRadius: "8px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80%" }}>
                    <Typography variant="h5" sx={{ color: "#fff" }}>Loading...</Typography>
                </div>
            </div>
        );
    }

    if (data.length === 0) {
        return (
            <div style={{ width: "600px", height: "330px", backgroundColor: "#292929", padding: "20px", borderRadius: "8px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <h3>No {brand} Products</h3>
            </div>
        );
    }

    return (
        <div style={{ width: "600px", height: "330px", backgroundColor: "#292929", padding: "20px", borderRadius: "8px" }}>
            <h3>{brand} Products in Category</h3>
            <Box sx={{ width: "600px", height: "270px" }}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            outerRadius={130}
                            innerRadius={50}
                            fill="#8884d8"
                            dataKey="value"
                            label={false} // Fjerner labels rundt kaken
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                        <Legend 
                            layout="vertical" 
                            align="right"
                            verticalAlign="middle"
                            formatter={(value, entry) => {
                                const item = data.find(d => d.name === value);
                                const total = data.reduce((sum, d) => sum + d.value, 0);
                                const percent = ((item.value / total) * 100).toFixed(0);
                                return `${value} (${percent}%)`;
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </Box>
        </div>
    );
};

export default ProductCategoryWidget;