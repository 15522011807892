import React, { useState, useEffect, useContext, useCallback } from 'react';
import { TextField, Button, Grid, Typography, Paper, Divider, Modal, Box, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Checkbox, FormGroup, Chip, MenuItem, Select, FormControl, InputLabel, CircularProgress, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import UserList from './UserList'; // Importer den nye komponenten for å vise brukere
import TabView from '../shared/TabView'; // Importer TabView-komponenten
import { message } from 'antd'; 
import { Height } from '@mui/icons-material';
import { AuthContext } from '../AuthContext'; // Import AuthContext to access logout function
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import FunctionGrid from './FunctionGrid'; // Add this import
import Cookies from 'js-cookie'; // Add this import to fix the 'Cookies is not defined' error

// Dette er URL-en til API-et
const API_URL = process.env.REACT_APP_STATUS_API_URL;

// Bruk miljøvariabelen for API-nøkkelen - med fallback til tidligere hardkodet verdi
const API_KEY = process.env.REACT_APP_API_KEY || 'UXvTRtMeXRZTtZgMNZhht_kYcLB.KcqxkVjX2.qmTQ';

// Hjelpefunksjon for å sende forespørsel til API-et
const sendAdminRequest = async (action, data = {}, method = 'POST') => {
  const urlMap = {
    'create_table': `${API_URL}/admin/create_table`,
    'delete_table': `${API_URL}/admin/delete_table`,
    'add_user': `${API_URL}/admin/add_user`,
    'delete_user': `${API_URL}/admin/delete_user`,
    'list_users': `${API_URL}/admin/list_users`,
    'locked_users': `${API_URL}/admin/locked_users`, 
    'frozen_users': `${API_URL}/admin/frozen_users`,
    'freeze_user': `${API_URL}/admin/freeze_user`,
    'unfreeze_user': `${API_URL}/admin/unfreeze_user`,
    'check_freeze': `${API_URL}/check_freeze`,
    'reset_lock_count': `${API_URL}/admin/reset_lock_count`,
    'update_user': `${API_URL}/admin/update_user`,
    'add_function': `${API_URL}/admin/add_function`,
    'remove_function': `${API_URL}/admin/remove_function`,
    'list_functions': `${API_URL}/admin/list_functions`,
    'list_all_functions': `${API_URL}/functions/`,
    'list_roles': `${API_URL}/admin/list_roles`,
  };

  const url = urlMap[action];
  if (!url) {
    console.error(`Ukjent handling: ${action}`);
    return;
  }
  
  // Fix: Always include API-key in all requests, and ensure it's properly formatted
  const headers = { 
    'Content-Type': 'application/json',
  };
  
  // Make sure API_KEY is properly added when availablensure it's properly formatted
  if (API_KEY && API_KEY.trim() !== '') {
    headers['X-API-Key'] = API_KEY.trim();
    //console.log('Using API key:', API_KEY.substring(0, 5) + '...');
  } else {
    console.warn('API key is missing or empty');
  }
  
  // Get JWT token from cookies
  const cookies = document.cookie.split(';');
  const tokenCookie = cookies.find(cookie => cookie.trim().startsWith('token='));
  if (tokenCookie) {
    const token = tokenCookie.split('=')[1];
    headers['Authorization'] = `Bearer ${token}`;
    console.log('Including JWT token in request');
  } else {
    console.warn('JWT token not found in cookies');
  }

  try {
    console.log(`Sending ${method} request to ${url}`);
    let response;
    if (method === 'GET') {
      response = await fetch(url, { method, headers });
    } else {
      response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(data)
      });
    }

    // Debug response for troubleshooting
    console.log(`Response status: ${response.status}`);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // Forbedret feilhåndtering
      const errorText = await response.text().catch(() => null);
      let errorMessage = `${response.status} - ${response.statusText}`;
      
      try {
        if (errorText) {
          const errorJson = JSON.parse(errorText);
          if (errorJson.error) {
            errorMessage = errorJson.error;
          }
        }
      } catch (e) {
        // Ignorer parse-feil
      }
      
      console.error(`Error: ${errorMessage}`);
      message.error(errorMessage);
      return null;
    }
  } catch (error) {
    console.error(`Error: ${error.message}`);
    message.error(`Nettverksfeil: ${error.message}`);
    return null;
  }
};

// Clean capitalizeNames helper
const capitalizeNames = (nameString) => {
  if (!nameString) return '';
  return nameString
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

// Helper to detect admin roles
const isAdminRole = (role) => role === 'admin' || role === 'superadmin';

// Add a helper
function isSuperAdminRole(role) {
  return role === 'superadmin';
}

// Helper to check if freezing is allowed
function canFreeze(userName, userRole, currentUserName, currentUserRole) {
  if (userName === currentUserName) return false;
  if (isSuperAdminRole(userRole) && !isSuperAdminRole(currentUserRole)) return false;
  // Allow superadmin to freeze/unfreeze admin
  if (isAdminRole(userRole) && !isSuperAdminRole(currentUserRole)) return false;
  return true;
}

// Add a parseFreeze helper
function parseFreeze(value) {
  return value === true || value === 'true' || value === 1;
}

function AdminPanel() {
  const [isEditable, setIsEditable] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [fornavn, setFornavn] = useState('');
  const [etternavn, setEtternavn] = useState('');
  const [role, setRole] = useState('');
  const [brands, setBrands] = useState('');
  const [sesamDb, setSesamDb] = useState('');
  const [persGroup, setPersGroup] = useState('');
  const [persDistr, setPersDistr] = useState('');
  const [users, setUsers] = useState([]);
  const [lockedUsers, setLockedUsers] = useState([]); // Riktig definert her
  const [frozenUsers, setFrozenUsers] = useState([]); // Riktig definert her
  const [currentUser, setCurrentUser] = useState('');
  const [currentUserRole, setCurrentUserRole] = useState('');
  
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newFornavn, setNewFornavn] = useState('');
  const [newEtternavn, setNewEtternavn] = useState('');
  const [newRole, setNewRole] = useState('');
  const [newBrands, setNewBrands] = useState('');
  const [newSesamDb, setNewSesamDb] = useState('');
  const [newPersGroup, setNewPersGroup] = useState('');
  const [newPersDistr, setNewPersDistr] = useState('');
  const [newEmail, setNewEmail] = useState('');        // NEW state for email
  const [newPhone, setNewPhone] = useState('');          // NEW state for phone
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false); // Add state for update modal
    
  // Updated field values for the update form
  const [updatedUsername, setUpdatedUsername] = useState('');
  const [updatedFornavn, setUpdatedFornavn] = useState('');
  const [updatedEtternavn, setUpdatedEtternavn] = useState('');
  const [updatedRole, setUpdatedRole] = useState('');
  const [updatedBrands, setUpdatedBrands] = useState('');
  const [updatedSesamDb, setUpdatedSesamDb] = useState('');
  const [updatedPersGroup, setUpdatedPersGroup] = useState('');
  const [updatedPersDistr, setUpdatedPersDistr] = useState('');
  const [updatedEmail, setUpdatedEmail] = useState('');  // NEW state for update email
  const [updatedPhone, setUpdatedPhone] = useState('');    // NEW state for update phone
  const [email, setEmail] = useState('');   // NEW state for email
  const [phone, setPhone] = useState('');   // NEW state for phone

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false); // State for delete confirmation dialog
  const { logout } = useContext(AuthContext); // Access the logout function from AuthContext
  const navigate = useNavigate(); // For navigation after self-deletion

  const [availableFunctions, setAvailableFunctions] = useState([]);
  const [userFunctions, setUserFunctions] = useState([]);
  const [newUserFunctions, setNewUserFunctions] = useState([]);
  const [updatedUserFunctions, setUpdatedUserFunctions] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [roleAvailableFunctions, setRoleAvailableFunctions] = useState([]); // New state to store role-specific available functions
  const [rolePresetFunctions, setRolePresetFunctions] = useState([]); // New state to store role-specific preset functions
  const [pageLoading, setPageLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  // Move these function definitions up, before they're used in JSX
  const handleNewUserFunctionChange = (funcName) => {
    console.log(`handleNewUserFunctionChange(${funcName})`);
    setNewUserFunctions(prev => 
      prev.includes(funcName)
        ? prev.filter(f => f !== funcName)
        : [...prev, funcName]
    );
  };

  const handleUpdatedUserFunctionChange = (funcName) => {
    console.log(`handleUpdatedUserFunctionChange(${funcName})`);
    setUpdatedUserFunctions(prev => 
      prev.includes(funcName)
        ? prev.filter(f => f !== funcName)
        : [...prev, funcName]
    );
  };

  // New function to fetch available functions and presets for a specific role
  const fetchRoleFunctions = useCallback(async (roleName) => {
    if (!roleName) return { availableFunctions: [], presetFunctions: [] };
    
    try {
      const token = Cookies.get("token");
      if (!token) throw new Error("No token found");
      
      // Execute these requests in parallel to save time
      const [availableResponse, presetResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/list_role_functions?role=${roleName}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-API-Key': API_KEY.trim()
          }
        }),
        fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/list_role_presets?role=${roleName}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-API-Key': API_KEY.trim()
          }
        })
      ]);
      
      // Process responses
      if (availableResponse.ok && presetResponse.ok) {
        const [availableData, presetData] = await Promise.all([
          availableResponse.json(),
          presetResponse.json()
        ]);
        
        const availableFunctions = availableData.functions || [];
        const presetFunctions = presetData.presets || [];
        
        // Update state only once with both values
        setRoleAvailableFunctions(availableFunctions);
        setRolePresetFunctions(presetFunctions);
        
        return { availableFunctions, presetFunctions };
      } else {
        console.error("Failed to fetch role functions");
        return { availableFunctions: [], presetFunctions: [] };
      }
    } catch (error) {
      console.error("Error fetching role functions:", error);
      return { availableFunctions: [], presetFunctions: [] };
    }
  }, []);

  // Update these functions to use role-specific functions
  const handleNewRoleChange = async (e) => {
    const selectedRole = e.target.value;
    setNewRole(selectedRole);
    
    if (selectedRole) {
      const { availableFunctions, presetFunctions } = await fetchRoleFunctions(selectedRole);
      setNewUserFunctions(presetFunctions); // Automatically pre-select preset functions
    } else {
      setNewUserFunctions([]);
    }
  };
  
  const handleUpdatedRoleChange = async (e) => {
    const selectedRole = e.target.value;
    setUpdatedRole(selectedRole);
    
    if (selectedRole) {
      const { availableFunctions, presetFunctions } = await fetchRoleFunctions(selectedRole);
      setUpdatedUserFunctions(presetFunctions); // Automatically pre-select preset functions
    }
  };
  
  // Modify the modal opening functions to fetch role-specific data
  const handleOpenModal = useCallback(() => {
    console.log("Opening Add New User modal");
    setNewUserFunctions([]);  // Reset functions
    setOpenModal(true);
  }, []);

  const handleOpenUpdateModal = useCallback(async () => {
    console.log("Opening Update User modal");
    setUpdatedUsername(username);
    setUpdatedFornavn(capitalizeNames(fornavn));
    setUpdatedEtternavn(capitalizeNames(etternavn));
    setUpdatedRole(role);
    setUpdatedBrands(brands);
    setUpdatedSesamDb(sesamDb);
    setUpdatedPersGroup(persGroup);
    setUpdatedPersDistr(persDistr);
    setUpdatedEmail(email || '');
    setUpdatedPhone(phone || '');
    
    // Fetch available functions and presets for the current role
    if (role) {
      await fetchRoleFunctions(role);
    }
    
    setUpdatedUserFunctions([...userFunctions]);
    setOpenUpdateModal(true);
  }, [username, fornavn, etternavn, role, brands, sesamDb, persGroup, persDistr, email, phone, userFunctions, fetchRoleFunctions]);

  const handleCloseModal = () => {
    // Clear all input fields when the modal closes
    setNewUsername('');
    setNewPassword('');
    setNewFornavn('');
    setNewEtternavn('');
    setNewRole('');
    setNewBrands('');
    setNewSesamDb('');
    setNewPersGroup('');
    setNewPersDistr('');
    setNewEmail('');        // Clear newEmail
    setNewPhone('');          // Clear newPhone
    setOpenModal(false);
  };
  const handleCloseUpdateModal = () => setOpenUpdateModal(false);  // Get the currently logged-in user on component mount
  
  // Funksjon for å hente låste brukere
  const listLockedOutUsers = async () => {
    try {
      const response = await sendAdminRequest('locked_users', {}, 'POST');
      if (response) {
        setLockedUsers(response.locked_users || []);
      } else {
        console.error('Failed to fetch locked users');
      }
    } catch (error) {
      console.error('Error in listLockedOutUsers:', error);
    }
  };          
  // Funksjon for å hente fryste brukere
  const listFrozenUsers = async () => {
    try {
      const response = await sendAdminRequest('frozen_users', {}, 'GET');
      if (response) {
        setFrozenUsers(response.frozen_users || []);
      } else {
        console.error('Failed to fetch frozen users');
      }
    } catch (error) {
      console.error('Error in listFrozenUsers:', error);
    }
  };          

  // Funksjon for å hente alle brukere
  const listUsers = async () => {
    try {
      const response = await sendAdminRequest('list_users', {}, 'GET');
      if (response) {
        setUsers(response.users || []);
      } else {
        console.error('Failed to fetch users');
      }
    } catch (error) {
      console.error('Error in listUsers:', error);
    }
  };    

  // Legg til ny bruker - renamed to addUser (already correct)
  const addUser = async () => {
    console.log("addUser() trigget med:", newUsername, newRole); // Debug log
    // Prevent admin from creating superadmin users
    if (newRole.toLowerCase() === 'superadmin' && currentUserRole !== 'superadmin') {
      message.error('Only superadmin users can create new superadmin accounts');
      return;
    }

    const data = {
      username: newUsername,
      password: newPassword,
      fornavn: newFornavn,
      etternavn: newEtternavn,
      role: newRole,
      brands: newBrands.split(',').map((brand) => brand.trim()),
      sesamDb: newSesamDb,
      persGroup: newPersGroup,
      persDistr: newPersDistr,
      email: newEmail,       // NEW field
      phone: newPhone        // NEW field
    };
    const response = await sendAdminRequest('add_user', data);
    if (response) {
      message.success(`User ${newUsername} added successfully`);
      
      // Add a delay to ensure the user is fully committed to the database
      if (newUserFunctions.length > 0) {
        // Wait for 1 second before adding functions
        message.info('Adding user functions...');
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        let successCount = 0;
        let failCount = 0;
        
        for (const func of newUserFunctions) {
          // Only try to add functions that are available for this role
          if (roleAvailableFunctions.includes(func)) {
            // Try adding the function with retry logic
            let attempts = 0;
            const maxAttempts = 3;
            let success = false;
            
            while (!success && attempts < maxAttempts) {
              attempts++;
              const result = await addFunctionToUser(newUsername, func);
              if (result) {
                success = true;
                successCount++;
                break;
              }
              // Wait a bit longer between retries
              await new Promise(resolve => setTimeout(resolve, 500));
            }
            
            if (!success) {
              failCount++;
            }
          }
        }
        
        if (failCount > 0) {
          message.warning(`Added user but failed to add ${failCount} function(s). You may need to update the user to add missing functions.`);
        } else {
          message.success(`Successfully added ${successCount} functions to user ${newUsername}`);
        }
      }
      
      listUsers();
      // Clear input fields
      setNewUsername('');
      setNewPassword('');
      setNewFornavn('');
      setNewEtternavn('');
      setNewRole('');
      setNewBrands('');
      setNewSesamDb('');
      setNewPersGroup('');
      setNewPersDistr('');
      setNewEmail('');        // Clear newEmail
      setNewPhone('');          // Clear newPhone
      setNewUserFunctions([]);
    } else {
      message.error('Error creating user');
    }
  };

  // Kjør funksjonene for å hente brukere ved lasting
  const loadInitialData = useCallback(async () => {
    setPageLoading(true);
    setFetchError(null);
    
    try {
      // Run these fetches in parallel
      await Promise.all([
        listUsers(),
        listLockedOutUsers(),
        listFrozenUsers(),
        fetchAvailableFunctions(),
        fetchAvailableRoles()
      ]);
    } catch (error) {
      console.error("Error loading initial data:", error);
      setFetchError("Failed to load data. Please try refreshing the page.");
    } finally {
      setPageLoading(false);
    }
  }, []);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  // Get the current logged-in user's username from JWT cookie on mount
  useEffect(() => {
    try {
      const tokenCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='));
      if (tokenCookie) {
        const token = tokenCookie.split('=')[1];
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const payload = JSON.parse(jsonPayload);
        setCurrentUser(payload.sub || payload.username);
      }
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  }, []);

  // 2) After users are loaded, set currentUserRole
  useEffect(() => {
    const me = users.find(u => u.username === currentUser);
    if (me) setCurrentUserRole(me.role);
  }, [users, currentUser]);

  // Funksjon for å fryse bruker
  const freezeUser = async () => {
    if (!canFreeze(username, role, currentUser, currentUserRole)) {
      if (username === currentUser) {
        message.error('Du kan ikke fryse din egen bruker');
      } else if (isAdminRole(role)) {
        message.error(`Kan ikke fryse bruker med rolle '${role}'`);
      }
      return;
    }
    const data = { username };
    const response = await sendAdminRequest('freeze_user', data);
    if (response) {
      message.success(`User ${username} is now frozen`);
      await Promise.all([ listFrozenUsers(), listUsers() ]);
      const currentFound = users.find(u => u.username === username);
      if (currentFound) {
        currentFound.freeze = true;
        setUsers([...users]);
      }
    } else {
      message.error('Error freezing user');
    }
  };

  // Funksjon for å oppheve fryse-status
  const unfreezeUser = async () => {
    if (!canFreeze(username, role, currentUser, currentUserRole)) {
      if (username === currentUser) {
        message.error('Du kan ikke oppheve frysing av din egen bruker');
      } else if (isAdminRole(role)) {
        message.error(`Kan ikke oppheve frysing for bruker med rolle '${role}'`);
      }
      return;
    }
    const data = { username };
    const response = await sendAdminRequest('unfreeze_user', data);
    if (response) {
      message.success(`User ${username} has been unfrozen`);
      await Promise.all([ listFrozenUsers(), listUsers() ]);
      const currentFound = users.find(u => u.username === username);
      if (currentFound) {
        currentFound.freeze = false;
        setUsers([...users]);
      }
    } else {
      message.error('Error unfreezing user');
    }
  };

  // Funksjon for å tilbakestille lock_count
  const resetLockCount = async () => {
    const data = { username };
    const response = await sendAdminRequest('reset_lock_count', data);
    if (response) {
      message.success(`Lock count reset for ${username}`);
      
      // Update all affected lists
      await Promise.all([
        listLockedOutUsers(), // Refresh locked users list
        listUsers()           // Refresh main user list
      ]);
      
      // Update current selected user lock count in the UI
      const currentUser = users.find(u => u.username === username);
      if (currentUser) {
        currentUser.lock_count = 0;
        setUsers([...users]); // Force UI update with a new array reference
      }
    } else {
      message.error('Error resetting lock count');
    }
  };

  // Open delete confirmation dialog
  const handleDeleteClick = () => {
    if (!username) {
      message.error('Please select a user to delete');
      return;
    }
    
    // Prevent admin from deleting superadmin accounts
    if (role === 'superadmin' && currentUserRole === 'admin') {
      message.error('Admin users cannot delete superadmin users');
      return;
    }
    
    setOpenDeleteConfirm(true);
  };
  
  // Close delete confirmation dialog
  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  // Funksjon for å slette bruker - updated with confirmation handling
  const deleteUser = async () => {
    // Close the confirmation dialog
    setOpenDeleteConfirm(false);
    
    // Prevent deleting your own superadmin account
    if (username === currentUser && role === 'superadmin') {
      message.error('You cannot delete your own superadmin user');
      return;
    }
    
    // Store whether this is the current user being deleted for later use
    const isDeletingSelf = username === currentUser;
    
    const response = await sendAdminRequest('delete_user', { username });
    if (response) {
      message.success(`User ${username} has been deleted`);
      
      // If the user deleted their own account, log them out and redirect to login
      if (isDeletingSelf) {
        message.info('You have deleted your own account. You will be logged out.', 2, () => {
          logout();
          navigate('/login');
        });
      } else {
        // Otherwise just update the user list
        listUsers();
      }
    } else {
      message.error('Error deleting user');
    }
  };

  // Funksjon for å endre en valgt bruker
  const updateUser = async () => {
    console.log("updateUser() trigget med:", updatedUsername); // Debug log
    if (!username) {
      message.error('Please select a user to update');
      return;
    }
    const data = {
      user_id: users.find((user) => user.username === username)?.id,
      updates: {
        username: updatedUsername,
        fornavn: updatedFornavn,
        etternavn: updatedEtternavn,
        role: updatedRole,
        brands: updatedBrands.split(',').map((brand) => brand.trim()),
        sesamDb: updatedSesamDb,
        persGroup: updatedPersGroup,
        persDistr: updatedPersDistr,
        email: updatedEmail,
        phone: updatedPhone
      },
    };
    const response = await sendAdminRequest('update_user', data);
    if (response) {
      message.success(`User ${username} updated`);
      
      // Update user functions (add/remove)
      const functionsToAdd = updatedUserFunctions.filter(f => !userFunctions.includes(f));
      const functionsToRemove = userFunctions.filter(f => !updatedUserFunctions.includes(f));
      for (const func of functionsToAdd) {
        await addFunctionToUser(updatedUsername, func);
      }
      for (const func of functionsToRemove) {
        await removeFunctionFromUser(updatedUsername, func);
      }
      
      listUsers();
      fetchUserFunctions(updatedUsername);
      
      // Oppdater lokalt med de nye verdiene og kallet til handleUserSelect
      const updatedUser = {
        username: updatedUsername,
        fornavn: updatedFornavn,
        etternavn: updatedEtternavn,
        role: updatedRole,
        brands: updatedBrands,
        sesamDb: updatedSesamDb,
        persGroup: updatedPersGroup,
        persDistr: updatedPersDistr,
        email: updatedEmail,
        phone: updatedPhone
      };
      handleUserSelect(updatedUser);
      
      // Clear update form fields if desired
      setUpdatedEmail('');
      setUpdatedPhone('');
    } else {
      message.error('Error updating user');
    }
  };

  // Funksjon for å håndtere når en bruker blir valgt fra listen
  const handleUserSelect = useCallback((user) => {
    console.log("Selected user:", user);  // NEW: log the full user object for debugging
    setUsername(user.username || '');
    // Store names in original form but display them capitalized
    setFornavn(user.fornavn || '');
    setEtternavn(user.etternavn || '');
    setRole(user.role || '');
    setBrands(Array.isArray(user.brands) ? user.brands.join(', ') : ''); // Hvis brands er en liste, konverter til kommaseparert streng
    setSesamDb(user.sesamDb || ''); // Standard til tom streng hvis verdien ikke finnes
    setPersGroup(user.persGroup || '');
    setPersDistr(user.persDistr || '');
    setEmail(user.email || '');         // NEW: set email
    setPhone(user.phone || '');         // NEW: set phone
    
    // Fetch the user's functions
    fetchUserFunctions(user.username);
    
    // Force a re-render to update button styles
    // This is a hack that ensures the UI updates properly
    setTimeout(() => {
      const dummy = document.createElement('div');
      document.body.appendChild(dummy);
      document.body.removeChild(dummy);
    }, 10);
  }, []);

  // Funksjon for å aktivere redigeringsmodus
  const handleUpdateClick = () => {
    if (!username) {
      message.error('Please select a user to update');
      return;
    }
    if (isSuperAdminRole(role) && !isSuperAdminRole(currentUserRole)) {
      message.error('You cannot modify a superadmin user');
      return;
    }
    handleOpenUpdateModal();
  };

  // Innhold for TabView: en tab for brukerliste, en for låste brukere, og en for fryste brukere
  const tabLabels = ['USER LIST', 'LOCKED OUT', 'FROZEN'];
  const tabContents = [
    <UserList 
      users={users} 
      onSelectUser={handleUserSelect} 
      selectedUsername={username}
      sx={{ flexGrow: 1, overflowY: 'auto', height: '100%' }} 
    />,
    <UserList 
      users={lockedUsers} 
      onSelectUser={handleUserSelect} 
      selectedUsername={username}
      sx={{ flexGrow: 1, overflowY: 'auto', height: '100%' }} 
    />,
    <UserList 
      users={frozenUsers} 
      onSelectUser={handleUserSelect} 
      selectedUsername={username}
      sx={{ flexGrow: 1, overflowY: 'auto', height: '100%' }} 
    />,
  ];

  // Modal style - updated width and layout
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Redusert fra 90%
    maxWidth: '1200px', // Redusert fra 1400px
    maxHeight: '85vh', // Redusert fra 90vh
    overflow: 'auto',
    bgcolor: '#2e2e2e',
    border: '1px solid #444',
    boxShadow: 24,
    p: 3, // Redusert fra 4
    color: '#fff',
  };

  // Create a function that returns appropriate button styles based on user selection status
  const getButtonStyles = (isUserSelected) => {
    return {
      standard: {
        bgcolor: '#505050',
        '&:hover': { bgcolor: '#606060' },
        color: '#fff',
        textTransform: 'none',
        '&.Mui-disabled': { 
          bgcolor: '#3a3a3a', 
          color: '#777',
        }
      },
      update: {
        bgcolor: isUserSelected ? '#3c6ea8' : '#3a3a3a',
        '&:hover': { bgcolor: isUserSelected ? '#4c7eb8' : '#3a3a3a' },
        color: isUserSelected ? '#fff' : '#777',
        textTransform: 'none',
        boxShadow: isUserSelected ? '0 0 8px rgba(100, 180, 255, 0.3)' : 'none',
      },
      freeze: {
        bgcolor: isUserSelected ? '#5755a3' : '#3a3a3a', 
        '&:hover': { bgcolor: isUserSelected ? '#6865b3' : '#3a3a3a' },
        color: isUserSelected ? '#fff' : '#777',
        textTransform: 'none',
        border: 'none',
        boxShadow: isUserSelected ? '0 0 8px rgba(150, 150, 255, 0.3)' : 'none',
      },
      unfreeze: {
        bgcolor: isUserSelected ? '#3a8c6e' : '#3a3a3a', 
        '&:hover': { bgcolor: isUserSelected ? '#4a9c7e' : '#3a3a3a' },
        color: isUserSelected ? '#fff' : '#777',
        textTransform: 'none',
        border: 'none',
        boxShadow: isUserSelected ? '0 0 8px rgba(100, 255, 180, 0.3)' : 'none',
      },
      reset: {
        bgcolor: isUserSelected ? '#a1854c' : '#3a3a3a',
        '&:hover': { bgcolor: isUserSelected ? '#b1955c' : '#3a3a3a' },
        color: isUserSelected ? '#fff' : '#777',
        textTransform: 'none',
        boxShadow: isUserSelected ? '0 0 8px rgba(255, 210, 100, 0.3)' : 'none',
      },
      delete: {
        bgcolor: isUserSelected ? '#a85252' : '#3a3a3a',
        '&:hover': { bgcolor: isUserSelected ? '#b86262' : '#3a3a3a' },
        color: isUserSelected ? '#fff' : '#777',
        textTransform: 'none',
        boxShadow: isUserSelected ? '0 0 8px rgba(255, 150, 150, 0.3)' : 'none',
      },
      add: {
        bgcolor: '#4e8ca1', // This button is always active
        '&:hover': { bgcolor: '#5e9cb1' },
        color: '#fff',
        textTransform: 'none',
        boxShadow: '0 0 8px rgba(100, 200, 255, 0.3)',
      },
    };
  };

  // Get button styles based on current selection state
  const buttonStyles = getButtonStyles(!!username);

  // Add shared styles for text fields
  const textFieldStyle = {
    mb: 1.5, // Redusert fra 2
    '& .MuiInputBase-root': {
      height: '40px', // Mindre høyde på input feltene
    },
    bgcolor: '#3e3e3e',
    input: { 
      color: '#fff',
      fontSize: '0.9rem', // Mindre tekst
    },
    label: { 
      color: '#aaa',
      fontSize: '0.9rem', // Mindre label tekst
      transform: 'translate(14px, 11px) scale(1)', // Juster label posisjon
      '&.Mui-focused, &.MuiFormLabel-filled': {
        transform: 'translate(14px, -9px) scale(0.75)',
      },
    },
  };

  // Add User Modal - updated layout
  const addUserModal = (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="add-user-modal-title"
      aria-describedby="add-user-modal-description"
    >
      <Box sx={modalStyle}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <Typography id="add-user-modal-title" variant="h6" component="h2">
            Add New User
          </Typography>
          <IconButton onClick={handleCloseModal} sx={{ color: '#fff' }}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container spacing={2}> {/* Redusert spacing fra 3 til 2 */}
          {/* Left side - User details in single column */}
          <Grid item xs={12} md={5}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <TextField
                fullWidth
                label="Username"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                type="password"
                label="Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="First Name"
                value={newFornavn}
                onChange={(e) => setNewFornavn(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="Last Name"
                value={newEtternavn}
                onChange={(e) => setNewEtternavn(e.target.value)}
                sx={textFieldStyle}
              />
              <FormControl fullWidth sx={{
                mb: 1.5,
                bgcolor: '#3e3e3e',
                '& .MuiInputBase-root': { height: '40px', color: '#fff' },
                '& .MuiInputLabel-root': { color: '#aaa', fontSize: '0.9rem' },
                '& .MuiSelect-icon': { color: '#aaa' },
              }}>
                <InputLabel id="new-role-label">Role</InputLabel>
                <Select
                  labelId="new-role-label"
                  id="new-role-select"
                  value={newRole}
                  label="Role"
                  onChange={handleNewRoleChange} // Use the updated handler
                  error={newRole.toLowerCase() === 'superadmin' && currentUserRole !== 'superadmin'}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: '#2e2e2e',
                        color: '#fff',
                        '& .MuiMenuItem-root': {
                          fontSize: '0.9rem',
                          '&:hover': { bgcolor: '#3e3e3e' },
                          '&.Mui-selected': { bgcolor: '#4a4a4a' }
                        }
                      }
                    }
                  }}
                >
                  <MenuItem value=""><em>Select a role</em></MenuItem>
                  {availableRoles.map((roleItem) => (
                    <MenuItem 
                      key={roleItem.id} 
                      value={roleItem.role}
                      disabled={roleItem.role === 'superadmin' && currentUserRole !== 'superadmin'}
                    >
                      {roleItem.role}
                    </MenuItem>
                  ))}
                </Select>
                {newRole.toLowerCase() === 'superadmin' && currentUserRole !== 'superadmin' && (
                  <Typography variant="caption" sx={{ color: '#f44336', mt: 0.5 }}>
                    Only superadmin users can create new superadmin accounts
                  </Typography>
                )}
              </FormControl>
              <TextField
                fullWidth
                label="Brands (comma-separated)"
                value={newBrands}
                onChange={(e) => setNewBrands(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="SesamDb"
                value={newSesamDb}
                onChange={(e) => setNewSesamDb(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="PersGroup"
                value={newPersGroup}
                onChange={(e) => setNewPersGroup(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="PersDistr"
                value={newPersDistr}
                onChange={(e) => setNewPersDistr(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="Email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="Phone"
                value={newPhone}
                onChange={(e) => setNewPhone(e.target.value)}
                sx={textFieldStyle}
              />
            </Box>
          </Grid>
          
          {/* Right side - Functions */}
          <Grid item xs={12} md={7}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ mb: 1.5, fontSize: '1.1rem' }}> {/* Mindre overskrift */}
                User Functions
                {newRole && (
                  <Typography variant="caption" sx={{ ml: 1, color: '#aaa' }}>
                    (Based on {newRole} role)
                  </Typography>
                )}
              </Typography>
              <Box sx={{ 
                flexGrow: 1,
                minHeight: '500px', // Redusert fra 600px
                maxHeight: '600px', // Redusert fra 700px
              }}>
                <FunctionGrid 
                  functions={newRole ? roleAvailableFunctions : availableFunctions}
                  selectedFunctions={newUserFunctions}
                  onChange={handleNewUserFunctionChange}
                  disabledMessage={!newRole ? "Please select a role first" : ""}
                />
              </Box>
            </Box>
          </Grid>
          
          {/* Bottom button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                console.log('Add User knapp trykket');
                addUser();
                handleCloseModal();
              }}
              fullWidth
              sx={{ marginTop: 1.5, ...buttonStyles.add }} // Redusert margin
            >
              Add User
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );

  // Update User Modal - similar layout as Add User Modal
  const updateUserModal = (
    <Modal
      open={openUpdateModal}
      onClose={handleCloseUpdateModal}
      aria-labelledby="update-user-modal-title"
      aria-describedby="update-user-modal-description"
    >
      <Box sx={modalStyle}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <Typography id="update-user-modal-title" variant="h6" component="h2">
            Update User: {username}
          </Typography>
          <IconButton onClick={handleCloseUpdateModal} sx={{ color: '#fff' }}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container spacing={2}> {/* Redusert spacing fra 3 til 2 */}
          {/* Left side - User details in single column */}
          <Grid item xs={12} md={5}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <TextField
                fullWidth
                label="Username"
                value={updatedUsername}
                onChange={(e) => setUpdatedUsername(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="First Name"
                value={updatedFornavn}
                onChange={(e) => setUpdatedFornavn(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="Last Name"
                value={updatedEtternavn}
                onChange={(e) => setUpdatedEtternavn(e.target.value)}
                sx={textFieldStyle}
              />
              <FormControl fullWidth sx={{
                mb: 1.5,
                bgcolor: '#3e3e3e',
                '& .MuiInputBase-root': { height: '40px', color: '#fff' },
                '& .MuiInputLabel-root': { color: '#aaa', fontSize: '0.9rem' },
                '& .MuiSelect-icon': { color: '#aaa' },
              }}>
                <InputLabel id="updated-role-label">Role</InputLabel>
                <Select
                  labelId="updated-role-label"
                  id="updated-role-select"
                  value={updatedRole}
                  label="Role"
                  onChange={handleUpdatedRoleChange} // Use the updated handler
                  disabled={role === 'superadmin' && currentUserRole !== 'superadmin'}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: '#2e2e2e',
                        color: '#fff',
                        '& .MuiMenuItem-root': {
                          fontSize: '0.9rem',
                          '&:hover': { bgcolor: '#3e3e3e' },
                          '&.Mui-selected': { bgcolor: '#4a4a4a' }
                        }
                      }
                    }
                  }}
                >
                  <MenuItem value=""><em>Select a role</em></MenuItem>
                  {availableRoles.map((roleItem) => (
                    <MenuItem 
                      key={roleItem.id} 
                      value={roleItem.role}
                      disabled={
                        roleItem.role === 'superadmin' && currentUserRole !== 'superadmin' ||
                        (role === 'superadmin' && currentUserRole !== 'superadmin')
                      }
                    >
                      {roleItem.role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Brands (comma-separated)"
                value={updatedBrands}
                onChange={(e) => setUpdatedBrands(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="SesamDb"
                value={updatedSesamDb}
                onChange={(e) => setUpdatedSesamDb(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="PersGroup"
                value={updatedPersGroup}
                onChange={(e) => setUpdatedPersGroup(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="PersDistr"
                value={updatedPersDistr}
                onChange={(e) => setUpdatedPersDistr(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="Email"
                value={updatedEmail}
                onChange={(e) => setUpdatedEmail(e.target.value)}
                sx={textFieldStyle}
              />
              <TextField
                fullWidth
                label="Phone"
                value={updatedPhone}
                onChange={(e) => setUpdatedPhone(e.target.value)}
                sx={textFieldStyle}
              />
            </Box>
          </Grid>
          
          {/* Right side - Functions */}
          <Grid item xs={12} md={7}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ mb: 1.5, fontSize: '1.1rem' }}> {/* Mindre overskrift */}
                User Functions
                {updatedRole && (
                  <Typography variant="caption" sx={{ ml: 1, color: '#aaa' }}>
                    (Based on {updatedRole} role)
                  </Typography>
                )}
              </Typography>
              <Box sx={{ 
                flexGrow: 1,
                minHeight: '500px', // Redusert fra 600px
                maxHeight: '600px', // Redusert fra 700px
              }}>
                <FunctionGrid 
                  functions={roleAvailableFunctions.length > 0 ? roleAvailableFunctions : availableFunctions}
                  selectedFunctions={updatedUserFunctions}
                  onChange={handleUpdatedUserFunctionChange}
                />
              </Box>
            </Box>
          </Grid>
          
          {/* Bottom button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                updateUser();
                handleCloseUpdateModal();
              }}
              fullWidth
              sx={{ marginTop: 1.5, ...buttonStyles.update }} // Redusert margin
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );

  // Delete confirmation dialog
  const deleteConfirmationDialog = (
    <Dialog
      open={openDeleteConfirm}
      onClose={handleCloseDeleteConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: '#2e2e2e',
          color: '#fff',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {username === currentUser ? "Delete Your Own Account?" : "Delete User?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ color: '#ddd' }}>
          {username === currentUser 
            ? "You are about to delete your own account. This will immediately log you out and you will no longer have access to the system. This action cannot be undone."
            : `Are you sure you want to delete the user "${username}"? This action cannot be undone.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handleCloseDeleteConfirm} 
          sx={{ color: '#aaa' }}
        >
          Cancel
        </Button>
        <Button 
          onClick={deleteUser} 
          sx={{ color: '#ff6b6b' }} 
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  // After fetching or setting selected user, define a boolean for freeze status:
  const selectedUser = users.find(u => u.username === username);
  const isFrozen = parseFreeze(selectedUser?.freeze);

  // Add a function to fetch a user's functions function to fetch all available functions
  const fetchUserFunctions = async (uname) => {
    if (!uname) return;
    const url = `${API_URL}/admin/list_functions?username=${encodeURIComponent(uname)}`;
    const headers = { 'Content-Type': 'application/json' };
    if (API_KEY && API_KEY.trim() !== '') {
      headers['X-API-Key'] = API_KEY.trim();
    }
    const cookies = document.cookie.split(';');
    const tokenCookie = cookies.find(cookie => cookie.trim().startsWith('token='));
    if (tokenCookie) {
      const token = tokenCookie.split('=')[1];
      headers['Authorization'] = `Bearer ${token}`;
    }
    try {
      const response = await fetch(url, { method: 'GET', headers });
      if (response.ok) {
        const result = await response.json();
        if (result && result.functions) {
          setUserFunctions(result.functions);
        } else {
          console.error("No functions returned");
          setUserFunctions([]);
        }
      } else {
        console.error(`Error: ${response.status}`);
        setUserFunctions([]);
      }
    } catch (error) {
      console.error("Error in fetchUserFunctions:", error);
      setUserFunctions([]);
    }
  };

  const fetchAvailableFunctions = () => {
    sendAdminRequest('list_all_functions', {}, 'GET')
      .then(response => {
        if (response && response.functions) {
          setAvailableFunctions(response.functions);
        } else {
          setAvailableFunctions([]);
          console.error("Error fetching available functions");
        }
      })
      .catch(error => {
        setAvailableFunctions([]);
        console.error("Error in fetchAvailableFunctions:", error);
      });
  };

  const fetchAvailableRoles = async () => {
    console.log("Fetching available roles");
    try {
      const response = await sendAdminRequest('list_roles', {}, 'GET');
      if (response && response.roles && Array.isArray(response.roles)) {
        console.log("Available roles:", response.roles);
        setAvailableRoles(response.roles);
      } else {
        console.error("Error: Unexpected response format for roles", response);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const addFunctionToUser = async (username, func) => {
    const data = { username: username, function: func };
    const response = await sendAdminRequest('add_function', data);
    if (response) {
      console.log(`Function ${func} added for user ${username}`);
      return true;
    } else {
      console.error(`Failed to add function ${func} for user ${username}`);
      return false;
    }
  };

  const removeFunctionFromUser = async (username, func) => {
    const data = { username: username, function: func };
    const response = await sendAdminRequest('remove_function', data);
    if (response) {
      console.log(`Function ${func} removed for user ${username}`);
    } else {
      console.error(`Failed to remove function ${func} for user ${username}`);
    }
  };

  // Style for function-tags for mer kompakt visning
  const functionTagStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '120px',
    maxWidth: '180px',
    height: '32px',
    m: 0.5,
    p: '4px 8px',
    bgcolor: 'rgba(255,255,255,0.05)',
    borderRadius: 2,
    cursor: 'pointer',
    transition: 'all 0.15s',
    '&:hover': {
      bgcolor: 'rgba(255,255,255,0.1)',
    },
    fontSize: '0.8rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  // Function for å gruppere functions etter prefiks eller kategori
  const groupFunctions = (functions) => {
    const groups = {};
    const sortedFuncs = [...functions].sort((a, b) => a.name.localeCompare(b.name));
    
    sortedFuncs.forEach(func => {
      // Prøv å finne et naturlig gruppeprefiks (f.eks. "admin:", "user:", etc.)
      let group = 'Other';
      const colonIndex = func.name.indexOf(':');
      if (colonIndex > 0) {
        group = func.name.substring(0, colonIndex);
      } else if (func.name.includes('_')) {
        // Eller bruk første del av underscore-delt navn
        group = func.name.split('_')[0];
      } else {
        // Ellers bruk første bokstav i navnet
        group = func.name.charAt(0).toUpperCase();
      }
      
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(func);
    });
    
    // Sorter grupper alfabetisk
    return Object.keys(groups).sort().map(key => ({
      name: key,
      functions: groups[key]
    }));
  };

  // Add overlay for page loading state
  const loadingOverlay = pageLoading && (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 1000,
        borderRadius: 1
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress size={40} sx={{ color: '#0088cc', mb: 2 }} />
        <Typography variant="body2" sx={{ color: '#fff' }}>
          Loading user data...
        </Typography>
      </Box>
    </Box>
  );

  // Improve the return with error handling and loading states
  return (
    <Paper 
      elevation={3} 
      sx={{ 
        padding: 3, 
        backgroundColor: '#1e1e1e', 
        color: '#fff',
        position: 'relative',
        minHeight: '400px'
      }}
    >
      {loadingOverlay}
      
      {!pageLoading && fetchError && (
        <Alert 
          severity="error" 
          sx={{ mb: 2, bgcolor: '#3a1414', color: '#ffa1a1' }}
        >
          {fetchError}
        </Alert>
      )}
      
      {!pageLoading && !fetchError && (
        <>
          <Typography variant="h4" gutterBottom sx={{ color: '#fff' }}>
            User Management
          </Typography>
          <Grid container spacing={2}>
            {/* Vis valgte bruker øverst og brukerliste ved siden av */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                Selected User:
              </Typography>
              <Paper sx={{ p: 3, mb: 2, backgroundColor: '#1e1e1e', border: '1px solid #444', boxShadow: '0 0 10px rgba(0,0,0,0.5)', borderRadius: '8px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>Username</Typography>
                    <Typography variant="body1">{username || '-'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>Role</Typography>
                    <Typography variant="body1">{role || '-'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>First Name</Typography>
                    <Typography variant="body1">
                      {capitalizeNames(fornavn) || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>Last Name</Typography>
                    <Typography variant="body1">
                      {capitalizeNames(etternavn) || '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>Email</Typography>
                    <Typography variant="body1">{email || '-'}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>Phone</Typography>
                    <Typography variant="body1">{phone || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>Brands</Typography>
                    <Typography variant="body1">
                      {brands ? brands.split(',').map(brand => 
                        brand.trim().charAt(0).toUpperCase() + brand.trim().slice(1).toLowerCase()
                      ).join(', ') : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>SesamDb</Typography>
                    <Typography variant="body1">{sesamDb || '-'}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>PersGroup</Typography>
                    <Typography variant="body1">{persGroup || '-'}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>PersDistr</Typography>
                    <Typography variant="body1">{persDistr || '-'}</Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>Functions</Typography>
                    <Typography variant="body1">
                      {userFunctions.length > 0 
                        ? userFunctions.map(func => 
                            <span key={func} style={{ 
                              display: 'inline-block', 
                              background: '#333', 
                              padding: '4px 8px', 
                              margin: '2px', 
                              borderRadius: '4px',
                              fontSize: '0.85rem'
                            }}>
                              {func}
                            </span>
                          )
                        : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                {/* Group 1: User Management */}
                <Grid item xs={6} sm={6}>
                  <Button
                    variant="contained"
                    onClick={handleUpdateClick}
                    fullWidth
                    sx={{
                      ...(username ? buttonStyles.update : { 
                        bgcolor: '#3a3a3a', 
                        color: '#777',
                        '&:hover': { bgcolor: '#3a3a3a' } 
                      })
                    }}
                    disabled={!username}
                  >
                    Update User
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button
                    variant="contained"
                    onClick={() => { console.log("Add New User button clicked"); handleOpenModal(); }}
                    fullWidth
                    sx={buttonStyles.add}
                  >
                    Add New User
                  </Button>
                </Grid>

                {/* Group 2: Freeze Management - with small top margin for visual grouping */}
                <Grid item xs={6} sm={6} sx={{ mt: 1 }}>
                  {!isFrozen ? (
                    // Freeze button
                    <Tooltip
                      title={
                        username === currentUser
                          ? "Du kan ikke fryse din egen bruker"
                          : isAdminRole(role)
                            ? `Brukere med rolle '${role}' kan ikke fryses`
                            : ""
                      }
                      arrow
                      disableHoverListener={!(username === currentUser || isAdminRole(role))}
                      placement="top"
                    >
                      <span style={{ width: '100%' }}>
                        <Button
                          variant="contained"
                          onClick={freezeUser}
                          fullWidth
                          sx={
                            username && canFreeze(username, role, currentUser, currentUserRole)
                              ? buttonStyles.freeze
                              : { bgcolor: '#3a3a3a', color: '#777', '&:hover': { bgcolor: '#3a3a3a' } }
                          }
                          disabled={!username || !canFreeze(username, role, currentUser, currentUserRole)}
                        >
                          Freeze User
                        </Button>
                      </span>
                    </Tooltip>
                  ) : (
                    // Unfreeze button
                    <Tooltip
                      title={
                        username === currentUser
                          ? "Du kan ikke oppheve frysing av din egen bruker"
                          : isAdminRole(role)
                            ? `Kan ikke oppheve frysing for bruker med rolle '${role}'`
                            : ""
                      }
                      arrow
                      disableHoverListener={!(username === currentUser || isAdminRole(role))}
                      placement="top"
                    >
                      <span style={{ width: '100%' }}>
                        <Button
                          variant="contained"
                          onClick={unfreezeUser}
                          fullWidth
                          sx={
                            username && canFreeze(username, role, currentUser, currentUserRole)
                              ? buttonStyles.unfreeze
                              : { bgcolor: '#3a3a3a', color: '#777', '&:hover': { bgcolor: '#3a3a3a' } }
                          }
                          disabled={!username || !canFreeze(username, role, currentUser, currentUserRole)}
                        >
                          Unfreeze User
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={6} sm={6} sx={{ mt: 1 }}>
                  <Button
                    variant="contained"
                    onClick={resetLockCount}
                    fullWidth
                    sx={
                      username ? buttonStyles.reset : { 
                        bgcolor: '#3a3a3a', 
                        color: '#777',
                        '&:hover': { bgcolor: '#3a3a3a' } 
                      }
                    }
                    disabled={!username}
                  >
                    Reset Lock Count
                  </Button>
                </Grid>

                {/* Group 3: Status Management */}
                <Grid item xs={6} sm={6}>
                  <Button
                    variant="contained"
                    onClick={handleDeleteClick} // Changed from deleteUser to handleDeleteClick
                    fullWidth
                    sx={
                      username ? buttonStyles.delete : { 
                        bgcolor: '#3a3a3a', 
                        color: '#777',
                        '&:hover': { bgcolor: '#3a3a3a' } 
                      }
                    }
                    disabled={!username || (role === 'superadmin' && currentUserRole === 'admin')}
                  >
                    Delete User
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                </Grid>
              </Grid>
            </Grid>
            
            {/* TabView for brukerliste, fryste brukere og låste brukere */}
            <Grid item xs={12} sm={6}>
              <TabView 
                tabLabels={tabLabels} 
                tabContents={tabContents} 
                variant="small" 
                smallTabColors={{
                  active: '#4a4a4a',        // Dark gray for active tab
                  inactive: '#303030',      // Darker gray for inactive tab
                  activeHover: '#5a5a5a',   // Slightly lighter gray for active hover
                  inactiveHover: '#404040'  // Slightly lighter gray for inactive hover
                }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ marginY: 3 }} />
          
          {addUserModal}
          {updateUserModal}
          {deleteConfirmationDialog}
        </>
      )}
    </Paper>
  );
}

export default React.memo(AdminPanel); // Use memo to prevent unnecessary re-renders
