import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import SuperAdminPanel from './SuperAdminPanel';
import { jwtDecode } from "jwt-decode";

const SuperAdmin = () => {
  const [apiStats, setApiStats] = useState(null);
  const [serverInfo, setServerInfo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("token");
        if (!token) throw new Error("No token found");

        const decodedToken = jwtDecode(token);
        const username = decodedToken.sub;

        // Fetch API stats
        const statsResponse = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/superadmin/stats`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username, token })
        });

        if (!statsResponse.ok) throw new Error("Failed to fetch API stats");
        const statsData = await statsResponse.json();
        setApiStats(statsData);

        // Fetch server info
        const serverResponse = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/superadmin/server-info`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username, token })
        });

        if (!serverResponse.ok) throw new Error("Failed to fetch server info");
        const serverData = await serverResponse.json();
        setServerInfo(serverData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // Oppdater hvert minutt
    return () => clearInterval(interval);
  }, []);

  return <SuperAdminPanel apiStats={apiStats} serverInfo={serverInfo} />;
};

export default SuperAdmin;
