import React, { useState } from 'react';
import { Box, Typography, TextField, Chip, Paper, Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

// Helper function for grouping functions
const groupFunctions = (functions) => {
  const groups = {
    'General': [] // Start with General group
  };

  // Ensure functions is an array and handle string values
  const functionsArray = Array.isArray(functions) ? functions : [];
  
  // Convert string-only array to object array with name property if needed
  const normalizedFunctions = functionsArray.map(func => 
    typeof func === 'string' ? { name: func } : func
  );
  
  // Sort the functions array safely
  const sortedFuncs = [...normalizedFunctions].sort((a, b) => {
    // Handle possible undefined values
    const nameA = a?.name || '';
    const nameB = b?.name || '';
    return nameA.localeCompare(nameB);
  });
  
  sortedFuncs.forEach(func => {
    // Skip if function object is invalid
    if (!func || !func.name) return;
    
    const funcName = func.name;
    const colonIndex = funcName.indexOf(':');
    
    if (colonIndex > 0) {
      // Has prefix with colon - group by that
      const group = funcName.substring(0, colonIndex);
      if (!groups[group]) {
        groups[group] = [];
      }
      // Add the function without prefix to this group
      groups[group].push({
        ...func,
        displayName: funcName.substring(colonIndex + 1).trim()
      });
    } else {
      // No prefix - add to General group with original name
      groups['General'].push({
        ...func,
        displayName: funcName
      });
    }
  });
  
  // Convert to array and sort so that General comes first
  return Object.entries(groups)
    .map(([name, functions]) => ({
      name,
      functions: functions.length > 0 ? functions : undefined // Remove empty groups
    }))
    .filter(group => group.functions) // Filter out groups without functions
    .sort((a, b) => {
      if (a.name === 'General') return -1;
      if (b.name === 'General') return 1;
      return a.name.localeCompare(b.name);
    });
};

const FunctionGrid = ({ functions = [], selectedFunctions = [], onChange, disabledMessage = "" }) => {
  const [filter, setFilter] = useState('');
  
  // Make sure functions is an array and normalize it for grouping
  const normalizedFunctions = Array.isArray(functions) ? functions.map(func => 
    typeof func === 'string' ? { name: func } : func
  ) : [];
  
  const groupedFunctions = groupFunctions(normalizedFunctions);
  const isDisabled = !!disabledMessage;

  // Helper to check if all functions in a group are selected
  const isGroupFullySelected = (groupFunctions) => {
    return groupFunctions.every(func => 
      func && func.name && selectedFunctions.includes(func.name)
    );
  };

  // Handler for selecting/deselecting entire group
  const handleGroupSelect = (groupFunctions) => {
    const isFullySelected = isGroupFullySelected(groupFunctions);
    
    groupFunctions.forEach(func => {
      if (!func || !func.name) return; // Skip invalid functions
      
      const name = func.name;
      const isSelected = selectedFunctions.includes(name);
      
      if (isFullySelected && isSelected) {
        // Deselect this function
        onChange(name);
      } else if (!isFullySelected && !isSelected) {
        // Select this function
        onChange(name);
      }
    });
  };

  // Apply filter to groups
  const filteredGroups = filter
    ? groupedFunctions.map(group => ({
        name: group.name,
        functions: group.functions.filter(f => 
          f && f.name && f.name.toLowerCase().includes(filter.toLowerCase())
        )
      })).filter(group => group.functions && group.functions.length > 0)
    : groupedFunctions;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
      {isDisabled && (
        <Paper sx={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgba(0,0,0,0.7)',
          borderRadius: 1
        }}>
          <Alert severity="info" sx={{ bgcolor: '#1e3a5c', color: '#fff' }}>
            {disabledMessage}
          </Alert>
        </Paper>
      )}
      <TextField
        size="small"
        placeholder="Search functions..."
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        variant="outlined"
        sx={{
          mb: 2,
          '.MuiOutlinedInput-root': {
            bgcolor: 'rgba(255,255,255,0.05)',
            color: '#fff',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255,255,255,0.3)',
            },
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255,255,255,0.1)',
          },
          input: { color: '#fff' }
        }}
      />

      <Box sx={{
        flexGrow: 1,
        overflowY: 'auto',
        p: 1,
        bgcolor: '#363636',
        borderRadius: 1,
        border: '1px solid #444',
      }}>
        {filteredGroups && filteredGroups.length > 0 ? (
          filteredGroups.map(group => (
            <Box key={group.name} sx={{ mb: 3 }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                mb: 1.5,
              }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    flex: 1,
                    pl: 1, 
                    py: 0.5, 
                    bgcolor: 'rgba(0,0,0,0.2)', 
                    borderRadius: 1,
                    fontWeight: 'bold',
                    color: '#aaa'
                  }}
                >
                  {group.name} ({group.functions ? group.functions.length : 0})
                </Typography>
                {group.functions && group.functions.length > 0 && (
                  <Chip
                    size="small"
                    icon={isGroupFullySelected(group.functions) ? 
                      <CheckCircleIcon fontSize="small" /> : 
                      <LibraryAddCheckIcon fontSize="small" />}
                    label={isGroupFullySelected(group.functions) ? "Deselect All" : "Select All"}
                    onClick={() => handleGroupSelect(group.functions)}
                    sx={{
                      ml: 1,
                      bgcolor: isGroupFullySelected(group.functions) ? 
                        'rgba(124,171,241,0.2)' : 'transparent',
                      color: '#fff',
                      border: isGroupFullySelected(group.functions) ? 
                        '1px solid #7cabf1' : '1px solid rgba(255,255,255,0.2)',
                      '&:hover': {
                        bgcolor: isGroupFullySelected(group.functions) ? 
                          'rgba(124,171,241,0.3)' : 'rgba(255,255,255,0.05)',
                      },
                    }}
                  />
                )}
              </Box>
              
              <Box sx={{ 
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                pl: 1,
              }}>
                {group.functions && group.functions.map((func, idx) => {
                  if (!func || !func.name) return null;
                  
                  const isSelected = selectedFunctions.includes(func.name);
                  return (
                    <Chip
                      key={func.id || func.name || idx}
                      label={func.displayName || func.name} // Use displayName if available
                      onClick={() => !isDisabled && onChange(func.name)}
                      icon={isSelected ? 
                        <CheckCircleIcon fontSize="small" /> : 
                        <RadioButtonUncheckedIcon fontSize="small" />}
                      variant={isSelected ? "filled" : "outlined"}
                      sx={{
                        m: 0.5,
                        bgcolor: isSelected ? 'rgba(124,171,241,0.2)' : 'transparent',
                        color: '#fff',
                        border: isSelected ? '1px solid #7cabf1' : '1px solid rgba(255,255,255,0.2)',
                        '&:hover': {
                          bgcolor: isSelected ? 'rgba(124,171,241,0.3)' : 'rgba(255,255,255,0.05)',
                        },
                        '& .MuiChip-icon': {
                          color: isSelected ? '#7cabf1' : '#aaa',
                          marginLeft: '6px',
                        },
                        transition: 'all 0.2s ease',
                        ...(isDisabled && {
                          opacity: 0.5,
                          pointerEvents: 'none'
                        })
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
          ))
        ) : (
          <Typography sx={{ p: 2, textAlign: 'center', color: '#aaa' }}>
            {filter ? 'No functions match your search' : 'No functions available'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FunctionGrid;
