import React, { useEffect, useRef } from 'react';
import { Box, List, ListItem, ListItemText, Typography, Paper, ListItemIcon } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { green, red, grey } from '@mui/material/colors';
import { message } from 'antd';

const JobViewer = ({ jobs, setJobs, jobStatuses, selectedJob, setSelectedJob, serverIp }) => {
  const listRef = useRef(null);
  const prevJobCount = useRef(jobs.length);
  const userSelectedJob = useRef(false);

  useEffect(() => {
    if (jobs.length > 0) {
      if (!selectedJob || !jobs.some(job => job.job_id === selectedJob.job_id)) {
        if (!userSelectedJob.current) {
          setSelectedJob(jobs[0]);
        }
      }
    }
  }, [jobs, selectedJob]);

  const handleSubmitJob = async (functionName, args, kwargs) => {
    if (!serverIp) {
      message.error('You are not connected to the server. Please connect first.');
      return;
    }

    try {
      const response = await fetch(`http://${serverIp}:5001/submit_job`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          function_name: functionName,
          args: args || [],
          kwargs: kwargs || {},
        }),
      });

      const result = await response.json();
      if (response.ok) {
        message.success(`Job sent! Job ID: ${result.job_id}`);

        const newJob = {
          job_id: result.job_id,
          function_name: functionName,
          args: args || [],
          kwargs: kwargs || {},
        };

        setJobs(prevQueue => [newJob, ...prevQueue]);

        if (!userSelectedJob.current) {
          setSelectedJob(newJob);
        }
      } else {
        message.error(`Error: ${result.error}`);
      }
    } catch (error) {
      message.error(`Connection error: ${error.message}`);
    }
  };

  const handleJobClick = (job) => {
    userSelectedJob.current = true;
    setSelectedJob(job);
  };

  const getStatusDetails = (jobId) => {
    const status = jobStatuses[jobId]?.status;

    if (status === 'queued') {
      return { icon: <CircularProgress size={20} />, text: "Queued", color: grey[400] };
    }
    if (status === 'done') {
      return { icon: <CheckCircleIcon sx={{ color: green[500] }} />, text: "Done", color: green[500] };
    }
    if (status === 'failed') {
      return { icon: <ErrorIcon sx={{ color: red[500] }} />, text: "Failed", color: red[500] };
    }
    return { icon: <CircularProgress size={20} sx={{ color: '#aaa' }} />, text: "Unknown", color: grey[500] };
  };

  return (
    <Box sx={{ display: 'flex', height: '400px', border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden' }}>
      <Box sx={{ width: '30%', borderRight: '1px solid #ddd', backgroundColor: '#252526' }} ref={listRef}>
        <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold', borderBottom: '2px solid #444', backgroundColor: '#1e1e1e', color: '#fff' }}>
          Job Queue
        </Typography>
        <List sx={{ overflowY: 'auto', height: 'calc(100% - 50px)' }}>
          {jobs.slice().reverse().map((job) => (
            <ListItem 
              button 
              key={job.job_id} 
              onClick={() => handleJobClick(job)}
              selected={selectedJob?.job_id === job.job_id}
              sx={{
                backgroundColor: selectedJob?.job_id === job.job_id ? '#4a4a4a' : 'transparent',
                color: selectedJob?.job_id === job.job_id ? '#fff' : 'inherit',
                '&:hover': { backgroundColor: '#5a5a5a', color: '#fff' },
              }}
            >
              <ListItemIcon>
                {getStatusDetails(job.job_id).icon}
              </ListItemIcon>
              <ListItemText 
                primary={`Job-ID: ${job.job_id}`} 
                secondary={`Function: ${job.function_name}`} 
              />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box sx={{ flex: 1, backgroundColor: '#1e1e1e', color: '#fff' , maxWidth: '1080px'}}>
        <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold', borderBottom: '2px solid #444', backgroundColor: '#1e1e1e', color: '#fff' }}>
          Details
        </Typography>
        <Box sx={{ padding: 2, height: 'calc(100% - 50px)', overflowY: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {selectedJob ? (
            <Paper sx={{ padding: 2, height: '100%', overflowY: 'auto', backgroundColor: '#333', color: '#fff', width: '100%' }}>
              {Object.keys(selectedJob.kwargs || {}).length > 0 && (
                <>
                  <Typography variant="subtitle1">Input Data:</Typography>
                  <Paper sx={{ padding: 1, backgroundColor: '#444', marginBottom: 1 }}>
                    <pre>{JSON.stringify(selectedJob.kwargs, null, 2)}</pre>
                  </Paper>
                </>
              )}
              {jobStatuses[selectedJob.job_id]?.status !== "failed" && (
                <>
                  <Typography variant="subtitle1" sx={{ marginTop: 2 }}>Return Data:</Typography>
                  <Paper sx={{ padding: 1, backgroundColor: '#444', marginBottom: 2, maxWidth: '100%' }}>
                    <pre>{JSON.stringify(jobStatuses[selectedJob.job_id]?.data || 'No data', null, 2)}</pre>
                  </Paper>
                </>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, paddingTop: 2, borderTop: '1px solid #555' }}>
                {getStatusDetails(selectedJob.job_id).icon}
                <Typography sx={{ color: getStatusDetails(selectedJob.job_id).color }}>
                  {getStatusDetails(selectedJob.job_id).text}
                </Typography>
              </Box>
            </Paper>
          ) : (
            <Typography sx={{ color: grey[500], fontSize: '1.2rem', textAlign: 'center' }}>
              Select a job to view the result
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default JobViewer;
