// src/components/ProtectedRoute.js
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';

function ProtectedRoute({ children, allowedRoles = [] }) {
  const { isAuthenticated, role, functions } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1);
  }, []);

  // Debug logging for monitoring the component's behavior
  useEffect(() => {
    console.log("ProtectedRoute - Current state:", {
      isAuthenticated,
      role,
      functions,
      location: location.pathname,
      allowedRoles
    });
  }, [isAuthenticated, role, functions, location.pathname, allowedRoles]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Only redirect to login if not authenticated
  if (!isAuthenticated) {
    console.log("Not authenticated, redirecting to login");
    return <Navigate to="/login" />;
  }

  // Function to map function names to route paths - MUST MATCH the mapping in Sidebar.js
  const functionToRoutePath = (func) => {
    // Map function names to their corresponding routes - EXACT SAME MAPPING AS IN SIDEBAR
    const functionRouteMap = {
      'dashboard': '/dashboard',
      'activities': '/activities',
      'stock': '/stock',
      'shipping': '/shipping',
      'social': '/social',
      'workers': '/workers',
      'repaircost': '/repair-cost',     // Note: function name is 'repaircost' not 'repair-cost'
      'priceadmin': '/price-admin',     // Note: function name is 'priceadmin' not 'price-admin'
      'settings': '/settings'           // Adding settings explicitly
    };
    
    console.log(`Checking function mapping for: "${func}" → ${functionRouteMap[func] || 'not found'}`);
    return functionRouteMap[func] || null;
  };

  // Find the first page the user has access to based on functions
  const getFirstAccessiblePage = () => {
    if (!functions || functions.length === 0) {
      console.log("No functions found, falling back to role-based routing");
      return getHomePageForRole(role);
    }
    
    console.log("Checking user functions:", functions);
    // Look through functions and find the first valid route
    for (const func of functions) {
      const path = functionToRoutePath(func);
      if (path) {
        console.log(`Found accessible path from function "${func}": ${path}`);
        return path;
      }
    }
    
    console.log("No matching function routes found, falling back to role-based routing");
    return getHomePageForRole(role);
  };

  // Get the home page for the user based on their role (fallback)
  const getHomePageForRole = (userRole) => {
    let homePage;
    switch(userRole) {
      case 'repair-cost':
        homePage = '/repair-cost';
        break;
      case 'price-admin':
        homePage = '/repair-cost'; // This is intentional based on existing logic
        break;
      case 'workers':
        homePage = '/workers';
        break;
      case 'admin':
        homePage = '/dashboard';
        break;
      case 'superadmin':
        homePage = '/dashboard';
        break;
      default:
        homePage = '/dashboard';
    }
    console.log(`Role-based home page for ${userRole}: ${homePage}`);
    return homePage;
  };

  // Check if the current path is accessible based on the user's role-specific restrictions
  const isPathAllowedForRole = () => {
    if (role === 'repair-cost') {
      return location.pathname === '/repair-cost';
    }
    
    if (role === 'price-admin') {
      return location.pathname === '/repair-cost' || location.pathname === '/price-admin';
    }
    
    // For other roles, check against allowed roles
    if (allowedRoles.length > 0) {
      const isAllowed = allowedRoles.includes(role);
      console.log(`Role check for ${role} against ${allowedRoles.join(', ')}: ${isAllowed ? 'Allowed' : 'Denied'}`);
      return isAllowed;
    }
    
    console.log(`No specific role restrictions, allowing access for ${role}`);
    return true; // Default to allowing access if no specific restrictions
  };

  // If the path is not allowed for this role or the role is not in allowedRoles
  if (!isPathAllowedForRole()) {
    const accessiblePage = getFirstAccessiblePage();
    console.log(`Access denied to ${location.pathname}, redirecting to: ${accessiblePage}`);
    return <Navigate to={accessiblePage} />;
  }

  return children;
}

export default ProtectedRoute;
