import React from 'react';
import styled from 'styled-components';
import { useChat } from './_social/ChatContext';
import ChatSelector from './_social/ChatSelector';
import ChatPanel from './_social/ChatPanel';  // Add this import

const Container = styled.div`
  padding: 20px;
  height: calc(100vh - 100px);
  display: flex;
  gap: 20px;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #2d2f33;
  border-radius: 8px;
  overflow: hidden;
`;

const Header = styled.div`
  color: #fff;
  padding: 16px;
  background: #363636;
  border-bottom: 1px solid #404040;
  h1 {
    margin: 0;
    font-size: 1.5rem;
  }
`;

const ChatArea = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const Social = () => {
  const { setIsChatOpen } = useChat();

  React.useEffect(() => {
    setIsChatOpen(true);
    return () => {
      if (window.location.pathname !== '/social') {
        setIsChatOpen(false);
      }
    };
  }, [setIsChatOpen]);

  return (
    <Container>
      <ChatSelector />
      <MainContent>
        <Header>
          <h1>Social Hub</h1>
        </Header>
        <ChatArea>
          <ChatPanel />
        </ChatArea>
      </MainContent>
    </Container>
  );
};

export default Social;
