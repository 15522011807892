import React, { useState, useRef, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  Divider,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import { useActivity } from "../_activities/ActivityContext";

const columnHeaders = [
    { key: "activityNumber", label: "Activity", width: 90 },
    { key: "daysSinceStatus", label: "Ch", width: 70 },
    { key: "status", label: "Status", width: 100 },
    { key: "refNo", label: "CustRef", width: 90 },
    { key: "GuarRef", label: "GRef", width: 130 },
    { key: "technician", label: "Technician", width: 100 },
    { key: "serviceCode", label: "Service Code", width: 110 },
    { key: "brand", label: "Brand", width: 100 },
    { key: "model", label: "Model", width: 150 },
    { key: "ProblemInMore", label: "Problem", width: "auto", minWidth: 200 }, // Tar resten av plassen
];

// Convert widths to numbers (using minWidth for "auto")
const initialWidths = columnHeaders.map(col => 
  col.width === "auto" ? (col.minWidth ? col.minWidth : 100) : col.width
);

const QueuesList = ({ services, onRowClick }) => {
  const { selectedActivity } = useActivity();
  const [colWidths, setColWidths] = useState(initialWidths);
  const resizingCol = useRef(null);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const [editingCell, setEditingCell] = useState({ rowId: null, colKey: null });
  
  // Change the default sort configuration to sort the "daysSinceStatus" column descending:
  const [sortConfig, setSortConfig] = useState({ column: "daysSinceStatus", direction: "desc" });

  const handleMouseDown = (e, index) => {
    startX.current = e.clientX;
    startWidth.current = colWidths[index];
    resizingCol.current = index;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (resizingCol.current === null) return;
    const diff = e.clientX - startX.current;
    let newWidth = startWidth.current + diff;
    if (newWidth < 50) newWidth = 50; // Minimum width
    setColWidths(prev => {
      const newWidths = [...prev];
      newWidths[resizingCol.current] = newWidth;
      return newWidths;
    });
  };

  const handleMouseUp = () => {
    resizingCol.current = null;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // New handleSort function for sortable columns
  const handleSort = (key) => {
    setSortConfig((prev) => {
      if (prev.column === key) {
        return { column: key, direction: prev.direction === "asc" ? "desc" : "asc" };
      }
      return { column: key, direction: "asc" };
    });
  };

  // Memoized sorted services based on sortConfig
  const sortedServices = useMemo(() => {
    if (!sortConfig.column) return services;
    return [...services].sort((a, b) => {
      let aVal = a[sortConfig.column] != null ? a[sortConfig.column] : "";
      let bVal = b[sortConfig.column] != null ? b[sortConfig.column] : "";
      let compA, compB;
      if (typeof aVal === "number" && typeof bVal === "number") {
        compA = aVal;
        compB = bVal;
      } else if (!Number.isNaN(Number(aVal)) && !Number.isNaN(Number(bVal))) {
        compA = Number(aVal);
        compB = Number(bVal);
      } else {
        compA = aVal.toString().toUpperCase();
        compB = bVal.toString().toUpperCase();
      }
      if (typeof compA !== typeof compB) {
        compA = compA.toString().toUpperCase();
        compB = compB.toString().toUpperCase();
      }
      if (compA < compB) return sortConfig.direction === "asc" ? -1 : 1;
      if (compA > compB) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [services, sortConfig]);

  if (!services || services.length === 0) {
    return (
      <Typography sx={{ textAlign: "center", padding: 2 }}>
        No services found.
      </Typography>
    );
  }

  // Define the set of sortable column keys
  const sortableColumns = new Set(["daysSinceStatus", "status", "GuarRef", "technician", "serviceCode", "brand"]);

  // Prevent opening another cell if one is already open, and ignore double-click
  const handleCellClick = (e, rowId, colKey) => {
    if (e.detail === 2) {
      // It's a double-click, do nothing for editing
      return;
    }
    // Only allow editing for the "ProblemInMore" column
    if (colKey !== "ProblemInMore") {
      return;
    }
    // If there's already an open editor and it's not the same cell, ignore
    if (editingCell.rowId && (editingCell.rowId !== rowId || editingCell.colKey !== colKey)) {
      return;
    }
    // If no editor is open, open this cell
    if (!editingCell.rowId) {
      setEditingCell({ rowId, colKey });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "180%", // Justerer for navbar og søkefelt
        overflow: "auto",
        bgcolor: "#1e1e1e",
        borderRadius: 1,
        border: "1px solid #444",
        color: "#fff",
        '& ::-webkit-scrollbar': {
          width: '0px',  // Endret fra 8px til 0px
          display: 'none' // Legger til dette for å skjule scrollbar helt
        },
        msOverflowStyle: 'none',  // For IE og Edge
        scrollbarWidth: 'none',   // For Firefox
      }}
    >
      <Table sx={{ tableLayout: "fixed", borderCollapse: "collapse" }}>
        <TableHead sx={{ position: "sticky", top: 0, bgcolor: "#333" }}>
          <TableRow>
            {columnHeaders.map((col, index) => (
              <TableCell
                key={col.key}
                sx={{
                  width: col.key === "ProblemInMore" ? 'auto' : colWidths[index],
                  position: "relative",
                  paddingLeft: "8px",
                  borderBottom: "2px solid #666",
                  fontWeight: "bold",
                  cursor: sortableColumns.has(col.key) ? "pointer" : "default",
                }}
                onClick={sortableColumns.has(col.key) ? () => handleSort(col.key) : undefined}
              >
                <Typography variant="body2" component="span">
                  {col.label}
                </Typography>
                {sortableColumns.has(col.key) && sortConfig.column === col.key && (
                  <Typography variant="body2" component="span" sx={{ ml: 0.5 }}>
                    {sortConfig.direction === "asc" ? "↑" : "↓"}
                  </Typography>
                )}
                <span
                  onMouseDown={(e) => handleMouseDown(e, index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    height: "100%",
                    width: "4px",             // reduced width for a subtle marker
                    cursor: "ew-resize",
                    userSelect: "none",
                    backgroundColor: "transparent", // no solid background
                    boxShadow: "inset -1px 0 0 0 #ccc", // subtle vertical line
                  }}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedServices.map((service) => (
            <TableRow
              key={service.id}
              onDoubleClick={(e) => {
                e.stopPropagation(); // Prevent cell single-click from triggering
                onRowClick(service.activityNumber);
              }}
              sx={{
                "&:hover": { backgroundColor: "#2e2e2e", cursor: "pointer" },
                backgroundColor: service.activityNumber === selectedActivity ? "#444" : "inherit", // Highlight selected row
              }}
            >
              {columnHeaders.map((col, index) => (
                <TableCell
                  key={col.key}
                  onClick={(e) => handleCellClick(e, service.id, col.key)}
                  sx={{
                    width: col.key === "ProblemInMore" ? 'auto' : colWidths[index],
                    paddingLeft: "8px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    borderBottom: "1px solid #555",
                    position: "relative",
                  }}
                >
                  {editingCell.rowId === service.id && editingCell.colKey === col.key ? (
                    <TextField
                      autoFocus
                      fullWidth
                      defaultValue={service[col.key] || "-"}
                      onBlur={() => setEditingCell({ rowId: null, colKey: null })}
                      variant="outlined"
                      size="small"
                      sx={{
                        bgcolor: "#222",
                        input: {
                          color: "#fff",
                        },
                      }}
                    />
                  ) : (
                    <Typography variant="body2" noWrap>
                      {service[col.key] || "-"}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

QueuesList.propTypes = {
  services: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default QueuesList;
