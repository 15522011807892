import React, { useEffect, useState } from 'react';
import { 
  Box, Typography, TextField, Button, List, ListItem,
  ListItemText, IconButton, Divider, Paper, Alert, CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

const FunctionManager = () => {
  const [functions, setFunctions] = useState([]);
  const [newFunction, setNewFunction] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // Fetch all available functions
  const fetchFunctions = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const token = Cookies.get("token");
      if (!token) throw new Error("No token found");

      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/admin/list_all_functions`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) throw new Error("Failed to fetch functions");
      
      const data = await response.json();
      setFunctions(data.functions || []);
    } catch (err) {
      console.error("Error fetching functions:", err);
      setError("Failed to load functions: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFunctions();
  }, []);

  const handleAddFunction = async () => {
    if (!newFunction.trim()) return;
    
    try {
      setLoading(true);
      const token = Cookies.get("token");
      
      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/api/admin/add_system_function`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ name: newFunction.trim() })
      });

      if (!response.ok) throw new Error("Failed to add function");
      
      setSuccessMessage(`Function '${newFunction}' added successfully`);
      setNewFunction('');
      fetchFunctions();
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Error adding function:", err);
      setError("Failed to add function: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFunction = async (functionName) => {
    try {
      setLoading(true);
      const token = Cookies.get("token");
      
      const response = await fetch(`${process.env.REACT_APP_STATUS_API_URL}/api/admin/delete_system_function`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ name: functionName })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to delete function");
      }
      
      setSuccessMessage(`Function '${functionName}' deleted successfully`);
      fetchFunctions();
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (err) {
      console.error("Error deleting function:", err);
      setError("Failed to delete function: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="subtitle2" sx={{ mb: 1, color: '#fff', fontSize: '0.9rem' }}>
        System Function Management
      </Typography>
      
      {/* Add Function Section */}
      <Paper elevation={0} sx={{ p: 1, mb: 1, bgcolor: '#333', borderRadius: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <TextField
            size="small"
            value={newFunction}
            onChange={(e) => setNewFunction(e.target.value)}
            placeholder="Enter function name"
            variant="outlined"
            sx={{
              flexGrow: 1,
              mr: 1,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                '& fieldset': { borderColor: '#555' },
                '&:hover fieldset': { borderColor: '#777' },
                '&.Mui-focused fieldset': { borderColor: '#0088cc' }
              },
              '& .MuiInputBase-input': { py: 0.75 }
            }}
          />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            disabled={!newFunction.trim() || loading}
            onClick={handleAddFunction}
            size="small"
            sx={{
              bgcolor: '#0066aa',
              '&:hover': { bgcolor: '#0088cc' },
              textTransform: 'none',
              py: 0.75
            }}
          >
            Add Function
          </Button>
        </Box>
      </Paper>

      {/* Success/Error Messages */}
      {successMessage && (
        <Alert severity="success" sx={{ mb: 1, py: 0, bgcolor: '#143a1d', color: '#a1ffa1' }}>
          {successMessage}
        </Alert>
      )}
      
      {error && (
        <Alert severity="error" sx={{ mb: 1, py: 0, bgcolor: '#3a1414', color: '#ffa1a1' }}>
          {error}
        </Alert>
      )}

      {/* Functions List */}
      <Paper 
        elevation={0} 
        sx={{ 
          flexGrow: 1, 
          bgcolor: '#222', 
          borderRadius: 1, 
          overflow: 'auto'
        }}
      >
        {loading && functions.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress size={24} sx={{ color: '#0088cc' }} />
          </Box>
        ) : (
          <List dense disablePadding sx={{ width: '100%' }}>
            {functions.length > 0 ? (
              functions.map((functionName, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    secondaryAction={
                      <IconButton 
                        edge="end" 
                        size="small" 
                        onClick={() => handleDeleteFunction(functionName)}
                        sx={{ color: '#ff6666' }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    }
                    sx={{ px: 1 }}
                  >
                    <ListItemText 
                      primary={functionName} 
                      primaryTypographyProps={{ 
                        sx: { color: '#fff', fontSize: '0.85rem' } 
                      }} 
                    />
                  </ListItem>
                  {index < functions.length - 1 && (
                    <Divider variant="middle" sx={{ borderColor: '#444' }} />
                  )}
                </React.Fragment>
              ))
            ) : (
              <ListItem>
                <ListItemText 
                  primary="No functions available" 
                  primaryTypographyProps={{ 
                    sx: { color: '#999', fontSize: '0.85rem', textAlign: 'center' } 
                  }} 
                />
              </ListItem>
            )}
          </List>
        )}
      </Paper>
    </Box>
  );
};

export default FunctionManager;
