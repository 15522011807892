import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode'; 
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';  // Importer js-cookie for å håndtere cookies
import { AuthContext } from './AuthContext';  // Importer AuthContext
import { message } from 'antd';


function ServerStatus({ showOnlyBackend }) {
  const [serverStatus, setServerStatus] = useState('Checking...');
  const [isServerUp, setIsServerUp] = useState(null);
  const [backendStatus, setBackendStatus] = useState('Checking...');
  const [localStatus, setLocalStatus] = useState('Checking...');
  const [serverWorker, setServerWorker] = useState(null);
  const navigate = useNavigate();
  
  const { setIsAuthenticated, setRole } = useContext(AuthContext);  // Hent metoder fra AuthContext

  // Funksjon for å logge ut brukeren og fjerne token og rolle
  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('role');
    setIsAuthenticated(false);  // Oppdater state i AuthContext
    setRole(null);  // Fjern rolle fra AuthContext
    navigate('/login');  // Naviger til login-siden
  };

  // Funksjon for å hente arbeidere og finne local_backend
  const fetchWorkers = async () => {
    try {
      const response = await fetch('https://scripthub.serviceorderhub.com/dashboardapi/workers/listsecure');
      if (response.ok) {
        const data = await response.json();
        // Finn worker med type "local_backend"
        const serverWorkerData = data.workers.find(worker => worker.worker_type === "local_backend");
        
        if (serverWorkerData) {
          console.log('Found local_backend worker:', serverWorkerData);
          setServerWorker(serverWorkerData);
        } else {
          console.error('No local_backend worker found in the workers list');
        }
      } else {
        console.error('Failed to fetch workers list');
      }
    } catch (error) {
      console.error('Error fetching workers:', error);
    }
  };

  // Funksjon for å sjekke serverstatus via en ping til backend
  const checkServerStatus = async (manualSource = null) => {
    // Kun sett "Checking..." for den klikkede kilden
    if (manualSource === "backend") {
      setBackendStatus('Checking...');
    } else if (manualSource === "local") {
      setLocalStatus('Checking...');
    }
    
    try {
      const dashboardapi = process.env.REACT_APP_STATUS_API_URL;
      
      // Prioriter serverWorker IP hvis tilgjengelig, ellers fallback til localStorage
      const localIp = serverWorker?.worker_local_ip || localStorage.getItem('serverIp');
      
      const [backendResult, localResult] = await Promise.allSettled([
        fetch(`${dashboardapi}/ping`),
        localIp ? fetch(`http://${localIp}:5001/status`) : Promise.reject('No server IP available')
      ]);
      
      const backendOk = backendResult.status === 'fulfilled' && backendResult.value.ok;
      const localOk   = localResult.status === 'fulfilled' && localResult.value.ok;
      
      // Removed setTimeout delay or reduced to minimal value
      setBackendStatus(backendOk ? 'Yes' : 'No');
      setLocalStatus(localOk ? 'Yes' : 'No');
      setIsServerUp(backendOk && localOk);
    } catch (error) {
      console.error('Error checking server status:', error);
      setBackendStatus('No');
      setLocalStatus('No');
      setIsServerUp(false);
    }
  };

  // Funksjon for å sjekke om token er utløpt
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;  // Nåværende tid i sekunder
      return decodedToken.exp < currentTime;  // Returner true hvis tokenet er utløpt
    } catch (error) {
      console.error('Feil ved dekoding av token:', error);
      return true;  // Hvis det er feil, anta at tokenet er utløpt
    }
  };

  // Funksjon for å sjekke om brukeren er frosset
  const checkIfUserIsFrozen = async () => {
    const apiUrl = process.env.REACT_APP_STATUS_API_URL;
    const token = Cookies.get('token'); // Hent token fra cookies

    if (!token) {
        console.error('Ingen token funnet');
        logout();
        return;
    }

    // Sjekk om token er utløpt
    if (isTokenExpired(token)) {
        message.error({
          content: 'Session expired, please log in again.',
          duration: 0, // 0 means the message will stay until manually closed
        });
        //alert('Session expired, please log in again.');
        logout(); // Logg ut hvis token er utløpt
        return;
    }

    try {
        const decodedToken = jwtDecode(token);
        const username = decodedToken.sub; // Hent brukernavn (sub) fra tokenet

        const response = await fetch(`${apiUrl}/check_freeze`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ username }),
        });

        if (response.ok) {
            const data = await response.json();
            if (data.status === "success" && data.user && data.user.freeze === true) {
                // Vis alert først, så logg ut
                alert('Account has been frozen by admin');

                // Etter alert, utfør logout
                setTimeout(() => {
                    logout(); // Logg ut brukeren etter alert
                }, 100); // Liten forsinkelse før utlogging
            } else {
                console.log(`User ${username} is not frozen.`);
            }
        } else {
            console.error('Noe gikk galt ved sjekk av fryse-status:', response.status);
        }
    } catch (error) {
        console.error('Feil ved sjekk av fryse-status:', error);
    }
};


  // Bruk useEffect for å sjekke serverstatus, fryse-status og token utløp ved komponentens opplasting
  useEffect(() => {
    // Fetch workers list first
    fetchWorkers();
    
    // Immediate first check for faster initialization
    checkServerStatus();
    
    // Perform another check soon after initial load to update any "Checking..." status quickly
    const initialCheck = setTimeout(() => {
      checkServerStatus();
    }, 500);
    
    const interval = setInterval(() => {
      checkServerStatus(false);
      checkIfUserIsFrozen();
    }, 10000);  // Sjekk hvert 10. sekund

    // Rydd opp intervallet når komponenten demonteres
    return () => {
      clearInterval(interval);
      clearTimeout(initialCheck);
    };
  }, []);

  // Nye hendelsesfunksjoner for hver status
  const handleBackendClick = () => {
    checkServerStatus("backend");
  };

  const handleLocalClick = () => {
    // Refresh worker list when manually checking local status
    fetchWorkers().then(() => checkServerStatus("local"));
  };

  // Returner UI med separate hover-effekter for Backend og Local status på samme linje
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '0.8rem' }}>
      {!showOnlyBackend ? (
        <>
          <Box
            component="span"
            onClick={handleBackendClick}
            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          >
            <span style={{ color: 'rgba(135,135,135,0.6)', fontWeight: 'normal' }}>Backend: </span>
            <span style={{ color: 'rgba(135,135,135,0.6)', fontWeight: 'normal' }}>
              {backendStatus}
            </span>
          </Box>
          <Box component="span" sx={{ mx: 1 }}>
            |
          </Box>
          <Box
            component="span"
            onClick={handleLocalClick}
            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          >
            <span style={{ color: 'rgba(135,135,135,0.6)', fontWeight: 'normal' }}>Workers: </span>
            <span style={{ color: 'rgba(135,135,135,0.6)', fontWeight: 'normal' }}>
              {localStatus}
            </span>
          </Box>
        </>
      ) : (
        <Box
          component="span"
          onClick={handleBackendClick}
          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
        >
          <span style={{ color: 'rgba(135,135,135,0.6)', fontWeight: 'normal' }}>Backend: </span>
          <span style={{ color: 'rgba(135,135,135,0.6)', fontWeight: 'normal' }}>
            {backendStatus}
          </span>
        </Box>
      )}
    </Box>
  );
}

ServerStatus.defaultProps = {
  showOnlyBackend: false
};

export default ServerStatus;
