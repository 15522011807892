import React, { useContext, useEffect, useRef } from 'react';
import { Drawer, Box, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import PaidIcon from '@mui/icons-material/Paid';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // Add this import for price admin icon
import SidebarElement from './SidebarElement'
import { AuthContext } from './AuthContext';
import { SettingsContext } from './SettingsContext';
import Logo from '../assets/Elcare_Nordic_white.png';

function Sidebar() {
  const { isAuthenticated, logout, role, functions: userFunctions } = useContext(AuthContext);
  const { useFloatingPanel, setIsSettingsOpen, isSidebarOpen, setIsSidebarOpen, autoHideSidebar, useSmallSidebar } = useContext(SettingsContext);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Sjekker om skjermen er liten
  const mouseOutTimer = useRef(null);

  const handleNavigate = (path) => navigate(path);

  const handleSettingsClick = () => {
    if (useFloatingPanel) {
      setIsSettingsOpen(true); // Bare åpne modalen uten navigering
    } else {
      navigate('/settings');
    }
  };

  const handleLogin = () => navigate('/login');
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleMouseEnter = () => {
    if (mouseOutTimer.current) {
      clearTimeout(mouseOutTimer.current);
    }
  };

  const handleMouseLeave = () => {
    if (autoHideSidebar && isSidebarOpen) {
      mouseOutTimer.current = setTimeout(() => {
        setIsSidebarOpen(false);
      }, 1000);
    }
  };

  // Simplify dynamic icon size - remove role-specific check
  const iconSize = useSmallSidebar ? 20 : (isSmallScreen ? 30 : 40);

  // Legg til clickOutside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Hvis auto-hide er på og sidebar er åpen
      if (autoHideSidebar && isSidebarOpen) {
        // Sjekk om klikket var utenfor sidebar og ikke på toggle-knappen
        const drawer = document.querySelector('.MuiDrawer-paper');
        const toggleButton = document.querySelector('.sidebar-toggle-button');
        if (drawer && !drawer.contains(event.target) && 
            toggleButton && !toggleButton.contains(event.target)) {
          setIsSidebarOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [autoHideSidebar, isSidebarOpen, setIsSidebarOpen]);

  // Logging for debugging
  useEffect(() => {
    console.log("Role in Sidebar:", role);
    console.log("User functions in Sidebar:", userFunctions);
  }, [role, userFunctions]);

  // Definer mappingen mellom funksjonsnøkler og meny-konfigurasjoner
  // Fjerner "settings" herfra siden vi har en egen dedikert knapp for settings-funksjonalitet
  const menuMapping = [
    { key: 'dashboard', path: '/dashboard', label: 'Dashboard', icon: <HomeIcon sx={{ fontSize: iconSize, color: '#4caf50' }} /> },
    { key: 'activities', path: '/activities', label: 'Activities', icon: <AssignmentIcon sx={{ fontSize: iconSize, color: '#4caf50' }} /> },
    { key: 'stock', path: '/stock', label: 'Stock', icon: <InventoryIcon sx={{ fontSize: iconSize, color: '#4caf50' }} /> },
    { key: 'shipping', path: '/shipping', label: 'Shipping', icon: <LocalShippingIcon sx={{ fontSize: iconSize, color: '#4caf50' }} /> },
    { key: 'social', path: '/social', label: 'Social', icon: <GroupIcon sx={{ fontSize: iconSize, color: '#4caf50' }} /> },
    { key: 'workers', path: '/workers', label: 'Workers', icon: <BuildCircleIcon sx={{ fontSize: iconSize, color: '#2196f3' }} /> },
    { key: 'repaircost', path: '/repair-cost', label: 'Repair Cost', icon: <PaidIcon sx={{ fontSize: iconSize, color: '#ff9800' }} /> },
    { key: 'priceadmin', path: '/price-admin', label: 'Price Admin', icon: <MonetizationOnIcon sx={{ fontSize: iconSize, color: '#e91e63' }} /> },
  ];

  return (
    <Drawer
      variant="permanent"
      open={autoHideSidebar ? isSidebarOpen : true}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        width: useSmallSidebar ? 120 : 180,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: useSmallSidebar ? 120 : 180,
          boxSizing: 'border-box',
          backgroundColor: '#252526',
          color: '#ffffff',
          overflowY: 'auto',
          transform: autoHideSidebar && !isSidebarOpen ? 'translateX(-100%)' : 'none',
          transition: 'transform 0.3s ease-in-out',
          // Custom scrollbar styling
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#252526', // Same as sidebar background
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.15)', // Very subtle light color
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(255, 255, 255, 0.25)', // Slightly more visible on hover
          },
          // For Firefox
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgba(255, 255, 255, 0.15) #252526',
        },
      }}
    >
      {/* Logo */}
      <Box sx={{ padding: '8px 16px 0 16px', textAlign: 'center' }}>
        <img
          src={Logo}
          alt="Verksted Logo"
          style={{ width: isSmallScreen ? '80%' : '100%', height: 'auto' }}
        />
      </Box>

      {/* App navn */}
      {!isSmallScreen && (
        <Box sx={{ padding: '4px 16px', textAlign: 'center', color: '#ffffff', marginTop: '-10px' }}>
          InStore
        </Box>
      )}

      {isAuthenticated && (
        <Box sx={{ paddingTop: '16px' }}>
          {menuMapping.map(item => {
            // Sjekk om brukeren er superadmin ELLER om userFunctions inkluderer denne funksjonen
            const hasAccess = role === 'superadmin' || (Array.isArray(userFunctions) && userFunctions.includes(item.key));
            
            // Logging for debugging
            console.log(`Menu item ${item.key}:`, hasAccess ? "Visible" : "Hidden");
            
            if (hasAccess) {
              return (
                <div key={item.key} onClick={() => handleNavigate(item.path)}>
                  <SidebarElement icon={item.icon} label={item.label} />
                </div>
              );
            }
            return null;
          })}
        </Box>
      )}

      <Box sx={{ flexGrow: 1 }} />

      {/* Superadmin-panel */}
      {role === 'superadmin' && (
        <Box sx={{ padding: 2, textAlign: 'center' }}>
          <div onClick={() => handleNavigate('/superadmin')}>
            <SidebarElement icon={<AdminPanelSettingsIcon sx={{ fontSize: iconSize, color: '#ff5722' }} />} label="Super Admin" />
          </div>
        </Box>
      )}

      {/* Settings-knappen - vises kun for superadmin eller brukere med settings-funksjon */}
      {(isAuthenticated && (role === 'superadmin' || (Array.isArray(userFunctions) && userFunctions.includes('settings')))) && (
        <Box sx={{ padding: 2, textAlign: 'center' }}>
          <div onClick={handleSettingsClick}>
            <SidebarElement icon={<SettingsIcon sx={{ fontSize: iconSize, color: '#4caf50' }} />} label="Settings" />
          </div>
        </Box>
      )}

      {/* Login / Logout-knapp */}
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        {isAuthenticated ? (
          <Button 
            variant="contained" 
            onClick={handleLogout} 
            sx={{ 
              width: '100%', 
              backgroundColor: 'rgba(135,135,135,0.6)',
              '&:hover': { backgroundColor: 'rgba(135,135,135,0.8)' }
            }}
          >
            Logout
          </Button>
        ) : (
          location.pathname !== '/login' && (
            <Button variant="contained" color="primary" onClick={handleLogin} sx={{ width: '100%' }}>
              Login
            </Button>
          )
        )}
      </Box>
    </Drawer>
  );
}

export default Sidebar;
